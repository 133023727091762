<template>
    <div v-if="data" class="about-us-card" :class="{ 'mobile-view': isMobile}">
        <img v-if="isMobile" v-lazy="data.url" alt="" class="card-image" :style="`width: ${cardWidth}; height: ${cardHeight};`">
        <p :class="cardTextClass">{{ data.text }}</p>
        <img v-if="!isMobile" v-lazy="data.url" alt="" class="card-image">
    </div>
  
</template>

<script>
export default {
    name: 'AboutUsCard',
    props: [ 'data', 'isMobile' ],
    setup(props) {
        var cardWidth = props.isMobile ? `${window.innerWidth * 0.7}px` : '384px';
        var cardHeight = props.isMobile ? 'auto' : '250px';
        var cardTextClass = props.isMobile ? 'card-text-mobile' : 'card-text';

        return {
            cardWidth,
            cardHeight,
            cardTextClass,
        }
    },
}
</script>

<style scoped>
.about-us-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}
.card-text {
    display: flex;
    font-size: 18px;
    line-height: 32px;
    color: black;
    text-align: justify;
    max-width: 600px;
    margin-right: 60px;
    letter-spacing: 0px;    
}
.card-text-mobile {
    display: flex;
    font-size: 14px;
    line-height: 22px;
    color: black;
    text-align: justify;
    /* max-width: 600px; */
    /* margin-right: 60px; */
    letter-spacing: 0px;
}
.card-image {
    width: 384px;
    height: 249px;
    border-radius: 15px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* mobile view */
.mobile-view .about-us-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.mobile-view .card-image {
    width: 282px;
    height: 167px;
    border-radius: 10px;
    /* margin-bottom: 40px; */
}
.mobile-view .card-text {
    font-size: 12px;
    text-align: center;
    width: 100%;
    margin-right: 0;
}
</style>