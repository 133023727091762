<template>
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.5 18C0.5 8.33502 8.33502 0.5 18 0.5C27.665 0.5 35.5 8.33502 35.5 18C35.5 27.665 27.665 35.5 18 35.5C8.33502 35.5 0.5 27.665 0.5 18Z"
            stroke="#2E2E1F" stroke-opacity="0.12" />
        <g clip-path="url(#clip0_1718_269)">
            <path
                d="M18 24.75C21.7279 24.75 24.75 21.7279 24.75 18C24.75 14.2721 21.7279 11.25 18 11.25C14.2721 11.25 11.25 14.2721 11.25 18C11.25 21.7279 14.2721 24.75 18 24.75Z"
                stroke="#2E2E27" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M15.1875 18C15.1875 20.6339 16.1248 22.9865 17.5978 24.5735C17.649 24.6291 17.7112 24.6736 17.7804 24.704C17.8496 24.7344 17.9244 24.7501 18 24.7501C18.0756 24.7501 18.1504 24.7344 18.2196 24.704C18.2888 24.6736 18.351 24.6291 18.4022 24.5735C19.8752 22.9865 20.8125 20.6339 20.8125 18C20.8125 15.3661 19.8752 13.0134 18.4022 11.4264C18.351 11.3708 18.2888 11.3264 18.2196 11.296C18.1504 11.2656 18.0756 11.2499 18 11.2499C17.9244 11.2499 17.8496 11.2656 17.7804 11.296C17.7112 11.3264 17.649 11.3708 17.5978 11.4264C16.1248 13.0134 15.1875 15.3661 15.1875 18Z"
                stroke="#2E2E27" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.6338 15.75H24.366" stroke="#2E2E27" stroke-width="16" stroke-linecap="round"
                stroke-linejoin="round" />
            <path d="M11.6338 20.25H24.366" stroke="#2E2E27" stroke-width="16" stroke-linecap="round"
                stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_1718_269">
                <rect width="18" height="18" fill="white" transform="translate(9 9)" />
            </clipPath>
        </defs>
    </svg>
</template>