<template>
    <div v-if="!isMobile">
        <v-row no-gutters style="margin-bottom: 38px; width: 100%;">
            <v-col cols="12" md="4" style="display: flex; justify-content: center; padding: 0; margin: 0; width: 100%;">
                <div style="width: 75%;" class="card"
                    @click="navOnClick($event, 'ProductsMetaAMP5')">
                    <round-stop-icon></round-stop-icon>
                    <p style="font-size: 18.9px; font-weight: 700;">
                        Anti-inflammatory peptide MetaAMP5
                    </p>
                    <p style="font-size: 15.1px; color: #2E2E27;">
                        Reduces the level of pro-inflammatory cytokines and attenuates the inflammatory response
                    </p>
                </div>
            </v-col>
            <v-col cols="12" md="4" style="display: flex; justify-content: center; padding: 0; margin: 0; width: 100%;">
                <div style="width: 75%;" class="card"
                    @click="navOnClick($event, 'ProductsMetaAMP33')">
                    <round-stop-icon></round-stop-icon>
                    <p style="font-size: 18.9px; font-weight: 700;">
                        Anti-acne Peptide MetaAMP33
                    </p>
                    <p style="font-size: 15.1px; color: #2E2E27;">
                        Reduces the level of pro-inflammatory cytokines and attenuates the inflammatory response
                    </p>
                </div>
            </v-col>
            <v-col cols="12" md="4" style="display: flex; justify-content: center; padding: 0; margin: 0; width: 100%;">
                <div style="width:80%;" class="card"
                    @click="navOnClick($event, 'ProductsMetaCono')">
                    <round-stop-icon></round-stop-icon>
                    <p style="font-size: 18.9px; font-weight: 700;">
                        Anti-wrinkle peptide MetaCono
                    </p>
                    <p style="font-size: 15.1px; color: #2E2E27;">
                        Instant wrinkle removal.
                    </p>
                    <p style="font-size: 15.1px; color: #2E2E27;">
                        Relax facial muscles and instantly erase facial wrinkles.
                    </p>
                </div>
            </v-col>
        </v-row>
        <v-row no-gutters style="width: 100%;">
            <v-col cols="12" md="6" style="display: flex; justify-content: center; padding: 0; margin: 0; width: 100%;">
                <div style="width: 87%;" class="card"
                    @click="navOnClick($event, 'ProductsMetaPepOcean1')">
                    <round-stop-icon></round-stop-icon>
                    <p style="font-size: 18.9px; font-weight: 700;">
                        Gingival Protection Peptide MetaPep-Ocean-1
                    </p>
                    <p style="font-size: 15.1px; color: #2E2E27;">
                        Oral microbiome balancer
                    </p>
                    <p style="font-size: 15.1px; color: #2E2E27;">
                        Antibacterial, anti-inflammatory and enhancing oral protection
                    </p>
                </div>
            </v-col>
            <v-col cols="12" md="6" style="display: flex; justify-content: center; padding: 0; margin: 0; width: 100%;">
                <div style="width: 87%;" class="card"
                    @click="navOnClick($event, 'ProductsMetaAMP12')">
                    <round-stop-icon></round-stop-icon>
                    <p style="font-size: 18.9px; font-weight: 700;">
                        Vaginal Care Antimicrobial Peptide MetaAMP12
                    </p>
                    <p style="font-size: 15.1px; color: #2E2E27;">
                        Vaginal health promoter.
                    </p>
                    <p style="font-size: 15.1px; color: #2E2E27;">
                        Effectively inhibits various pathogenic bacteria and reduces inflammation
                    </p>
                </div>
            </v-col>
        </v-row>
    </div>

    <div v-else>
        <v-row no-gutters style="margin-bottom: 5px; padding: 2%;">
            <v-col style="display: flex; justify-content: center; max-width: 60%;">
                <div class="card-mobile" @click="navOnClick($event, 'ProductsMetaAMP5')">
                    <div class="card-item-wrapper-mobile">
                        <round-stop-icon></round-stop-icon>
                        <p class="card-item-title-mobile">
                            Anti-inflammatory peptide MetaAMP5
                        </p>
                    </div>
                    <p class="card-item-text-mobile">
                        Reduces the level of pro-inflammatory cytokines and attenuates the inflammatory response
                    </p>
                </div>
            </v-col>
            <v-col style="display: flex; justify-content: center; max-width: 40%;">
                <div class="card-mobile" @click="navOnClick($event, 'ProductsMetaAMP33')">
                    <div class="card-item-wrapper-mobile">
                        <round-stop-icon></round-stop-icon>
                        <p class="card-item-title-mobile">
                            Anti-acne Peptide MetaAMP33
                        </p>
                    </div>
                    <p class="card-item-text-mobile">
                        Reduces the level of pro-inflammatory cytokines and attenuates the inflammatory response
                    </p>
                </div>
            </v-col>
            
        </v-row>
        <v-row no-gutters style="margin-bottom: 5px; padding: 2%;">
            <v-col style="display: flex; justify-content: center; max-width: 40%;">
                <div class="card-mobile" @click="navOnClick($event, 'ProductsMetaCono')">
                    <div class="card-item-wrapper-mobile">
                        <round-stop-icon></round-stop-icon>
                        <p class="card-item-title-mobile">
                            Anti-wrinkle peptide MetaCono
                        </p>
                    </div>
                    <p class="card-item-text-mobile">
                        Instant wrinkle removal.
                    </p>
                    <p class="card-item-text-mobile">
                        Relax facial muscles and instantly erase facial wrinkles.
                    </p>
                </div>
            </v-col>
            <v-col style="display: flex; justify-content: center; max-width: 60%;">
                <div class="card-mobile" @click="navOnClick($event, 'ProductsMetaPepOcean1')">
                    <div class="card-item-wrapper-mobile">
                        <round-stop-icon></round-stop-icon>
                        <p class="card-item-title-mobile">
                            Gingival Protection Peptide MetaPep-Ocean-1
                        </p>
                    </div>
                    <p class="card-item-text-mobile">
                        Oral microbiome balancer
                    </p>
                    <p class="card-item-text-mobile">
                        Antibacterial, anti-inflammatory and enhancing oral protection
                    </p>
                </div>
            </v-col>
        </v-row>
        <v-row no-gutters style="padding: 2%;">
            <v-col style="display: flex; justify-content: center; max-width: 100%;">
                <div class="card-mobile" @click="navOnClick($event, 'ProductsMetaAMP12')">
                    <div class="card-item-wrapper-mobile">
                        <round-stop-icon></round-stop-icon>
                        <p class="card-item-title-mobile">
                            Vaginal Care Antimicrobial Peptide MetaAMP12
                        </p>
                    </div>
                    <p class="card-item-text-mobile">
                        Vaginal health promoter.
                    </p>
                    <p class="card-item-text-mobile">
                        Effectively inhibits various pathogenic bacteria and reduces inflammation
                    </p>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import RoundStopIcon from '@/components/Products/RoundStopIcon.vue'
import router from '@/router';
export default {
    components: {
        RoundStopIcon,
    },
    props: ["isMobile"],
    methods: {
        navOnClick(event, index) {
            var elements = document.getElementsByClassName("navbar-link-active");
            for (let item of elements) {
                item.classList.remove(["navbar-link-active"]);
            }
            var element = document.getElementById("navbar-products");
            console.log(element)
            if (element) element.classList.add(["navbar-link-active"]);

            router.push({ name: index });
        }
    }
}
</script>

<style scoped>
.card {
    text-align: left; 
    width: 75%; 
    border-radius: 12px; 
    box-shadow: 3px 3px 3px lightgrey; 
    padding: 5%; 
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.6);
}

.card-mobile {
    text-align: left; 
    border-radius: 12px; 
    box-shadow: 3px 3px 3px lightgrey; 
    padding: 5%;
    width: 100%;
}
.card-item-wrapper-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.card-item-title-mobile {
    font-size: 9px;
    font-weight: bold;
    margin-left: 2%;
}
.card-item-text-mobile {
    font-size: 7px;
    color: #2E2E27;
}
</style>