<template>
    <div class="background">
        <div style="padding-top: 250px; display: flex; flex-direction: column; align-items: center; height: 750px;">
            <h1 :class="platFormTitleTextClass">
                Technical Platform
            </h1>
            <div v-if="isMobile" class="divider"></div>
            <p :class="platFormSubTitleTextClass">
                Our platform boasts four key advantages in transforming complex text into digestible data, shifting from
                experiment-driven to computation-driven approaches, transitioning simple components into system biology,
                and evolving from generic targeting to precision targeting.
            </p>
        </div>
    </div>

    <div v-if="!isMobile" style="padding-top: 60px; display: flex; flex-direction: column; align-items: center; height: 332px; background-color: #F5F5F5; width: 100%;">
        <v-row style="width: 927px; background-color: #F5F5F5; margin: 0;">
            <v-col cols="12" md="3" style="display: flex; justify-content: center;">
                <div style="display: flex; flex-direction: column; align-items: center;">
                    <DataIcon width="153" height="153"></DataIcon>
                    <div style="font-size: 20px; line-height: 23.2px; letter-spacing: 0.12px; color: #284070;">
                        Digestible data</div>
                </div>
            </v-col>
            <v-col cols="12" md="3" style="display: flex; justify-content: center;">
                <div style="display: flex; flex-direction: column; align-items: center;">
                    <ComputationIcon></ComputationIcon>
                    <div style="font-size: 20px; line-height: 23.2px; letter-spacing: 0.12px; color: #284070;">
                        Computation-driven</div>
                </div>
            </v-col>
            <v-col cols="12" md="3" style="display: flex; justify-content: center;">
                <div style="display: flex; flex-direction: column; align-items: center;">
                    <BiologyIcon></BiologyIcon>
                    <div style="font-size: 20px; line-height: 23.2px; letter-spacing: 0.12px; color: #284070;">System
                        biology</div>
                </div>
            </v-col>
            <v-col cols="12" md="3" style="display: flex; justify-content: center;">
                <div style="display: flex; flex-direction: column; align-items: center;">
                    <TargetIcon></TargetIcon>
                    <div style="font-size: 20px; line-height: 23.2px; letter-spacing: 0.12px; color: #284070;">Precision
                        targeting</div>
                </div>
            </v-col>
        </v-row>
    </div>

    <div v-else style="display: flex; flex-direction: column; align-items: center; background-color: #F5F5F5; justify-content: center; padding: 10% 0;">
        <div style="width: 100%; background-color: #F5F5F5; display: flex; flex-direction: row; justify-content: center;">
            <div style="display: flex; flex-direction: column; align-items: center;">
                <DataIcon width="153" height="153"></DataIcon>
                <div style="font-size: 12px; line-height: 23.2px; letter-spacing: 0.12px; color: #284070;">Digestible data</div>
            </div>
            <div style="display: flex; flex-direction: column; align-items: center;">
                <ComputationIcon></ComputationIcon>
                <div style="font-size: 12px; line-height: 23.2px; letter-spacing: 0.12px; color: #284070;">Computation-driven</div>
            </div>
        </div>
        <div style="width: 100%; background-color: #F5F5F5; display: flex; flex-direction: row; justify-content: center;">
            <div style="display: flex; flex-direction: column; align-items: center;">
                <BiologyIcon></BiologyIcon>
                <div style="font-size: 12px; line-height: 23.2px; letter-spacing: 0.12px; color: #284070;">System biology</div>
            </div>
            <div style="display: flex; flex-direction: column; align-items: center;">
                <TargetIcon></TargetIcon>
                <div style="font-size: 12px; line-height: 23.2px; letter-spacing: 0.12px; color: #284070;">Precision targeting</div>
            </div>
        </div>
    </div>

    <div :class="platformDetailWrapperClass">
        <div :class="platformDetailTitleClass">Technical Platform</div>
        <div style="width: 40px; height: 3px; background-color: black; margin-top: 20px; margin-bottom: 100px;"></div>

        <div v-if="!isMobile" style="width: 1200px; height: 240px; padding-left: 90px; background-color: #F5F5F5; margin-bottom: 70px;"
            class="box" id="metanlp">
            <v-row>
                <v-col cols="12" md="4">
                    <v-row style="align-items: center">
                        <v-col cols="12" md="5">
                            <ComplexTextIcon></ComplexTextIcon>
                            <div class="icon-text">Complex Text</div>
                        </v-col>
                        <v-col cols="12" md="1">
                            <ArrowIcon></ArrowIcon>
                        </v-col>
                        <v-col cols="12" md="5">
                            <DataIconSmall></DataIconSmall>
                            <div class="icon-text">Digestible data</div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="8" style="text-align: left">
                    <div
                        style="font-size: 24px; font-weight: bold; line-height: 26px; letter-spacing: 0.15px; color: #284070; margin-bottom: 20px;">
                        MetaNLP®</div>
                    <div style="font-size: 15px; line-height: 20px; letter-spacing: 0.12px; color: #2E2E27;">Proprietary
                        fine-grained NLP text mining for scientific literature and patents</div>
                    <div style="font-size: 15px; line-height: 20px; letter-spacing: 0.12px; color: #2E2E27;">— Distill
                        human knowledge to structured data</div>
                </v-col>
            </v-row>
        </div>

        <div v-else style="width: 90%; height: 310px; background-color: #F5F5F5; margin-bottom: 20px; padding: 0 10%;" class="box" id="metanlp">
            <div style="display: flex; flex-direction: column;">
                <div style="display: flex; flex-direction: row; justify-content: space-around; margin-bottom: 0%;">
                    <p style="font-size: 18px; font-weight: bold; line-height: 26px; letter-spacing: 0.14px; color: #284070;">MetaNLP®</p>
                    <div class="divider" style="background-color: #284070; width: 50%;"></div>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; margin-bottom: 5%; margin-top: 2%;">
                    <div style="display: flex; flex-direction: column; align-items: center;">
                        <ComplexTextIcon style="margin-bottom: 4px;"></ComplexTextIcon>
                        <div class="icon-text">Complex Text</div>
                    </div>
                    <div>
                        <ArrowIcon></ArrowIcon>
                    </div>
                    <div style="display: flex; flex-direction: column; align-items: center;">
                        <DataIconSmall style="margin-bottom: 8px;"></DataIconSmall>
                        <div class="icon-text">Digestible data</div>
                    </div>
                </div>
                <div style="text-align: left;">
                    <div style="font-size: 12px; line-height: 20px; letter-spacing: 0.12px; color: #2E2E27;">Proprietary
                        fine-grained NLP text mining for scientific literature and patents</div>
                    <div style="font-size: 12px; line-height: 20px; letter-spacing: 0.12px; color: #2E2E27;">Distill
                        human knowledge to structured data</div>
                    </div>
            </div>
        </div>

        <div v-if="!isMobile" style="width: 1200px; height: 240px; padding-left: 90px; background-color: #F5F5F5; margin-bottom: 70px;"
            class="box" id="metakg">
            <v-row>
                <v-col cols="12" md="4">
                    <v-row style="align-items: center">
                        <v-col cols="12" md="5">
                            <ElementDrivenIcon></ElementDrivenIcon>
                            <div class="icon-text">Experiment-driven</div>
                        </v-col>
                        <v-col cols="12" md="1">
                            <ArrowIcon></ArrowIcon>
                        </v-col>
                        <v-col cols="12" md="5">
                            <ComputationIconSmall></ComputationIconSmall>
                            <div class="icon-text">Computation-driven</div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="8" style="text-align: left">
                    <div
                        style="font-size: 24px; font-weight: bold; line-height: 26px; letter-spacing: 0.15px; color: #284070; margin-bottom: 20px;">
                        MetaKG®</div>
                    <div style="font-size: 15px; line-height: 20px; letter-spacing: 0.12px; color: #2E2E27;">Ultra-large
                        biomedical knowledge graph with graph models to uncover hidden relations</div>
                    <div style="font-size: 15px; line-height: 20px; letter-spacing: 0.12px; color: #2E2E27;">—
                        Accelerate knowledge discovery and product development</div>
                </v-col>
            </v-row>
        </div>

        <div v-else style="width: 90%; height: 310px; background-color: #F5F5F5; margin-bottom: 20px; padding: 0 10%;" class="box" id="metakg">
            <div style="display: flex; flex-direction: column;">
                <div style="display: flex; flex-direction: row; justify-content: space-around; margin-bottom: 0;">
                    <p style="font-size: 18px; font-weight: bold; line-height: 26px; letter-spacing: 0.14px; color: #284070;">MetaKG®</p>
                    <div class="divider" style="background-color: #284070; width: 50%;"></div>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; margin-bottom: 5%; margin-top: 2%;">
                    <div style="display: flex; flex-direction: column; align-items: center;">
                        <ElementDrivenIcon style="margin-bottom: 10px;"></ElementDrivenIcon>
                        <div class="icon-text">Experiment-driven</div>
                    </div>
                    <div>
                        <ArrowIcon></ArrowIcon>
                    </div>
                    <div style="display: flex; flex-direction: column; align-items: center;">
                        <ComputationIconSmall style="margin-bottom: 13px;"></ComputationIconSmall>
                        <div class="icon-text">Computation-driven</div>
                    </div>
                </div>
                <div style="text-align: left;">
                    <div style="font-size: 12px; line-height: 20px; letter-spacing: 0.12px; color: #2E2E27;">Ultra-large
                        biomedical knowledge graph with graph models to uncover hidden relations</div>
                    <div style="font-size: 12px; line-height: 20px; letter-spacing: 0.12px; color: #2E2E27;">
                        Accelerate knowledge discovery and product development</div>
                    </div>
            </div>
        </div>

        <div v-if="!isMobile" style="width: 1200px; height: 240px; padding-left: 90px; background-color: #F5F5F5; margin-bottom: 70px;"
            class="box" id="metapep">
            <v-row>
                <v-col cols="12" md="4">
                    <v-row style="align-items: center">
                        <v-col cols="12" md="5">
                            <SimpleIcon></SimpleIcon>
                            <div class="icon-text">Simple component</div>
                        </v-col>
                        <v-col cols="12" md="1">
                            <ArrowIcon></ArrowIcon>
                        </v-col>
                        <v-col cols="12" md="5">
                            <BiologyIconSmall></BiologyIconSmall>
                            <div class="icon-text">System biology</div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="8" style="text-align: left">
                    <div
                        style="font-size: 24px; font-weight: bold; line-height: 26px; letter-spacing: 0.15px; color: #284070; margin-bottom: 20px;">
                        MetaPep®</div>
                    <div style="font-size: 15px; line-height: 20px; letter-spacing: 0.12px; color: #2E2E27;">
                        Function-based and target-based peptide design with deep learning and </div>
                    <div style="font-size: 15px; line-height: 20px; letter-spacing: 0.12px; color: #2E2E27;">molecular
                        dynamics</div>
                    <div style="font-size: 15px; line-height: 20px; letter-spacing: 0.12px; color: #2E2E27;">—
                        Accelerate ingredient development with high success rate</div>
                </v-col>
            </v-row>
        </div>

        <div v-else style="width: 90%; height: 310px; background-color: #F5F5F5; margin-bottom: 20px; padding: 0 10%;" class="box" id="metapep">
            <div style="display: flex; flex-direction: column;">
                <div style="display: flex; flex-direction: row; justify-content: space-around; margin-bottom: 0%;">
                    <p style="font-size: 18px; font-weight: bold; line-height: 26px; letter-spacing: 0.14px; color: #284070;">MetaPep®</p>
                    <div class="divider" style="background-color: #284070; width: 50%;"></div>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; margin-bottom: 5%; margin-top: 2%;">
                    <div style="display: flex; flex-direction: column; align-items: center;">
                        <SimpleIcon style="margin-bottom: 8px;"></SimpleIcon>
                        <div class="icon-text">Simple component</div>
                    </div>
                    <div>
                        <ArrowIcon></ArrowIcon>
                    </div>
                    <div style="display: flex; flex-direction: column; align-items: center;">
                        <BiologyIconSmall style="margin-bottom: 8px;"></BiologyIconSmall>
                        <div class="icon-text">System biology</div>
                    </div>
                </div>
                <div style="text-align: left;">
                    <div style="font-size: 12px; line-height: 20px; letter-spacing: 0.12px; color: #2E2E27;">
                        Function-based and target-based peptide design with deep learning and molecular dynamics
                    </div>
                    <div style="font-size: 12px; line-height: 20px; letter-spacing: 0.12px; color: #2E2E27;">
                        Accelerate ingredient development with high success rate
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!isMobile" style="width: 1200px; height: 240px; padding-left: 90px; background-color: #F5F5F5; margin-bottom: 70px;"
            class="box" id="metaomics">
            <v-row>
                <v-col cols="12" md="4">
                    <v-row style="align-items: center">
                        <v-col cols="12" md="5">
                            <GeneticIcon style="margin-bottom: -4px;"></GeneticIcon>
                            <div class="icon-text">Generic targeting</div>
                        </v-col>
                        <v-col cols="12" md="1">
                            <ArrowIcon></ArrowIcon>
                        </v-col>
                        <v-col cols="12" md="5">
                            <PrecisionIcon style="margin-bottom: -4px;"></PrecisionIcon>
                            <div class="icon-text">Precision targeting</div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="8" style="text-align: left">
                    <div
                        style="font-size: 24px; font-weight: bold; line-height: 26px; letter-spacing: 0.15px; color: #284070; margin-bottom: 20px;">
                        MetaOmics®</div>
                    <div style="font-size: 15px; line-height: 20px; letter-spacing: 0.12px; color: #2E2E27;">Huge
                        multi-omics data with various advanced analytical methods</div>
                    <div style="font-size: 15px; line-height: 20px; letter-spacing: 0.12px; color: #2E2E27;">— Enable
                        precision product development through population segmentation</div>
                </v-col>
            </v-row>
        </div>

        <div v-else style="width: 90%; height: 310px; background-color: #F5F5F5; margin-bottom: 20px; padding: 0 10%;" class="box" id="metaomics">
            <div style="display: flex; flex-direction: column;">
                <div style="display: flex; flex-direction: row; justify-content: space-around; margin-bottom: 0%;">
                    <p style="font-size: 18px; font-weight: bold; line-height: 26px; letter-spacing: 0.14px; color: #284070;">MetaOmics®</p>
                    <div class="divider" style="background-color: #284070; width: 50%;"></div>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; margin-bottom: 5%; margin-top: 2%;">
                    <div style="display: flex; flex-direction: column; align-items: center;">
                        <GeneticIcon></GeneticIcon>
                        <div class="icon-text" style="line-height: 18px;">Generic targeting</div>
                    </div>
                    <div>
                        <ArrowIcon></ArrowIcon>
                    </div>
                    <div style="display: flex; flex-direction: column; align-items: center;">
                        <PrecisionIcon></PrecisionIcon>
                        <div class="icon-text" style="line-height: 18px;">Precision targeting</div>
                    </div>
                </div>
                <div style="text-align: left;">
                    <div style="font-size: 12px; line-height: 20px; letter-spacing: 0.12px; color: #2E2E27;">
                        Huge multi-omics data with various advanced analytical methods
                    </div>
                    <div style="font-size: 12px; line-height: 20px; letter-spacing: 0.12px; color: #2E2E27;">
                        Enable precision product development through population segmentation
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataIcon from '@/components/Technology/DataIcon.vue'
import ComputationIcon from '@/components/Technology/ComputationIcon.vue'
import BiologyIcon from '@/components/Technology/BiologyIcon.vue'
import TargetIcon from '@/components/Technology/TargetIcon.vue'
import ComplexTextIcon from '@/components/Technology/ComplexTextIcon.vue'
import ArrowIcon from '@/components/Technology/ArrowIcon.vue'
import DataIconSmall from '@/components/Technology/DataIconSmall.vue'
import ElementDrivenIcon from '@/components/Technology/ElementDrivenIcon.vue'
import ComputationIconSmall from '@/components/Technology/ComputationIconSmall.vue'
import SimpleIcon from '@/components/Technology/SimpleIcon.vue'
import BiologyIconSmall from '@/components/Technology/BiologyIconSmall.vue'
import GeneticIcon from '@/components/Technology/GeneticIcon.vue'
import PrecisionIcon from '@/components/Technology/PrecisionIcon.vue'
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";
import { ref } from 'vue';
export default {
    components: {
        DataIcon,
        ComputationIcon,
        BiologyIcon,
        TargetIcon,
        ComplexTextIcon,
        ArrowIcon,
        DataIconSmall,
        ElementDrivenIcon,
        ComputationIconSmall,
        SimpleIcon,
        BiologyIconSmall,
        GeneticIcon,
        PrecisionIcon,
    },
    setup() {
        var screenSize = useScreenSize()
        var isMobile = screenSize.isMobile.value;
        var backgroundClass = isMobile ? 'background-extra-mobile' : 'background-extra';
        var platFormTitleTextClass = isMobile ? 'platform-title-text-mobile' : 'platform-title-text';
        var platFormSubTitleTextClass = isMobile ? 'platform-sub-title-text-mobile' : 'platform-sub-title-text';
        var platformDetailTitleClass = isMobile ? 'platform-detail-title-mobile' : 'platform-detail-title';
        var platformDetailWrapperClass = isMobile ? 'platform-detail-wrapper-mobile' : 'platform-detail-wrapper';

        return {
            screenSize,
            isMobile,
            backgroundClass,
            platFormTitleTextClass,
            platFormSubTitleTextClass,
            platformDetailTitleClass,
            platformDetailWrapperClass,
        }
    }
}
</script>

<style scoped>
.background {
    background-image: url("../../public/images/technology/technology1.png");
    background-repeat: no-repeat;
    /* background-size: 400%; */
    background-position-x: right;
    width: 100%;
    /* background-position-y: top; */
}

.background-extra {
    padding-bottom: 75px;
    background-size: 100%;
    background-position-y: center;
}

.background-extra-mobile {
    padding-bottom: 5%;
    background-size: 100%;
    background-position-y: top;
}

.platform-detail-wrapper {
    padding-top: 120px; 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
}
.platform-detail-wrapper-mobile {
    padding-top: 55px; 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
}

.platform-title-text {
    font-weight: normal;
    line-height: 73.6px;
    letter-spacing: -1.28px;
    color: #ABE499;
    font-size: 96px;
    margin-bottom: 95px;
}

.platform-title-text-mobile {
    font-weight: normal;
    line-height: 73.6px;
    letter-spacing: 0px;
    color: #ABE499;
    font-size: 32px;
    margin-bottom: 4%;
}

.platform-sub-title-text {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.18px;
    color: white;
    width: 959px;
}

.platform-sub-title-text-mobile {
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.18px;
    color: white;
    width: 80%;
}

.platform-detail-title {
    font-size: 52px; 
    line-height: 46px; 
    letter-spacing: -0.8px; 
    text-align: center;
}
.platform-detail-title-mobile {
    font-size: 20px; 
    line-height: 46px; 
    letter-spacing: 0px; 
    text-align: center;
}

.box {
    background: #F5F5F5;
    box-shadow: -4px 29px 44.7px -21px rgba(40, 64, 112, 0.2);
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-text {
    font-size: 14px;
    line-height:20px;
    letter-spacing: 0.12px;
    margin-bottom: 3px;
}

.divider {
    width: 90%;
    height: 1px;
    background-color: white;
    margin-bottom: 8%;
}

.image-margin {
    margin-top: 96px;
    margin-bottom: 111px;
}

.image-margin-mobile {
    margin-top: 10%;
    margin-bottom: 10%;
}
</style>