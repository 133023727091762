<template>
    <div :style="`position: relative; height: ${height}px; width: ${width}px;`">
        <div ref="draggableImageLeft" class="draggable-image-left-container" :style="`width: ${width / 2}px; height: ${height}px;`">
            <img :height="height" :width="width" class="draggable-image-left" :style="`object-position: ${positionX} ${positionY}`" :src="image1"></img>
        </div>
        <div ref="draggableImageRight" class="draggable-image-right-container" :style="`width: ${width / 2}px; height: ${height}px;`">
            <img :height="height" :width="width" class="draggable-image-right" :style="`object-position: ${positionX} ${positionY}`" :src="image2"></img>
        </div>
        <div ref="draggableContainer" :style="`position: absolute; left: ${width / 2}px; height: ${height}px; z-index: 3;`">
            <div class="draggable-line" :style="`height: ${height}px;`" @mousedown="dragMouseDown" :id="`draggableLine${index}`">
                <draggable-icon class="draggable-icon"></draggable-icon>
            </div>
        </div>
    </div>
</template>

<script>
import DraggableIcon from '@/components/Products/DraggableIcon.vue'
import { ref } from 'vue';
export default {
    components: {
        DraggableIcon,
    },
    props: ['image1', 'image2', 'positionX', 'positionY', 'height', 'width', 'index'],
    setup(props) {
        var positions = ref({
            clientX: undefined,
            clientY: undefined,
            movementX: 0,
            movementY: 0
        })

        return {
            positions,
        }
    },
    mounted() {
        const el = document.getElementById(`draggableLine${this.index}`);
        el.addEventListener("touchstart", this.touchStartEvent);
    },
    methods: {
        getImgUrl(image) {
            var image = require(image);
            return image
        },
        touchStartEvent(event) {
            event.preventDefault()
            // get the mouse cursor position at startup:
            this.positions.clientX = event.touches[0].clientX
            this.positions.clientY = event.touches[0].clientY
            document.ontouchmove = this.mobileElementDrag
            document.ontouchend = this.mobileCloseDragElement
        },
        dragMouseDown: function (event) {
            event.preventDefault()
            // get the mouse cursor position at startup:
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            document.onmousemove = this.elementDrag
            document.onmouseup = this.closeDragElement
        },
        mobileElementDrag(event) {
            this.positions.movementX = this.positions.clientX - event.changedTouches[0].clientX
            this.positions.movementY = this.positions.clientY - event.changedTouches[0].clientY
            this.positions.clientX = event.changedTouches[0].clientX
            this.positions.clientY = event.changedTouches[0].clientY
            // set the element's new position:
            var xPosition = this.$refs.draggableContainer.offsetLeft - this.positions.movementX;
            if (xPosition > 0 && xPosition < this.width) {
                this.$refs.draggableContainer.style.left = xPosition + 'px';
                this.$refs.draggableImageLeft.style.width = xPosition + 'px';
                this.$refs.draggableImageRight.style.width = (this.width - xPosition) + 'px';
            }
        },
        mobileCloseDragElement(event) {
            document.ontouchmove = null
            document.ontouchend = null
        },
        elementDrag: function (event) {
            event.preventDefault()
            this.positions.movementX = this.positions.clientX - event.clientX
            this.positions.movementY = this.positions.clientY - event.clientY
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            // set the element's new position:
            var xPosition = this.$refs.draggableContainer.offsetLeft - this.positions.movementX;
            if (xPosition > 0 && xPosition < this.width) {
                this.$refs.draggableContainer.style.left = xPosition + 'px';
                this.$refs.draggableImageLeft.style.width = xPosition + 'px';
                this.$refs.draggableImageRight.style.width = (this.width - xPosition) + 'px';
            }
        },
        closeDragElement () {
            document.onmouseup = null
            document.onmousemove = null
        }
    }

}
</script>

<style scoped>
.draggable-image-left-container {
    z-index: 3;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;
}
.draggable-image-left {
    object-fit: cover;
    object-position: center -400px;
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
}
.draggable-image-right-container {
    z-index: 1;
    position: absolute;
    bottom: 0;
    right: 0;
    overflow: hidden;
}
.draggable-image-right {
    object-fit: cover;
    object-position: center -400px;
    z-index: 0;
    position: absolute;
    bottom: 0;
    right: 0;
}
.draggable-line {
    position: absolute;
    z-index: 3;
    left: 0;
    width: 8px;
    background-color: #2C406C;
}
.draggable-icon {
    z-index: 4;
    position: absolute;
    left: -180%;
    top: 40%;
}
</style>