<template>
  <div
    class="about-awards"
    :class="{ 'mobile-view': sizes.isMobile.value }"
    :style="{
      '--browserWidth': browserWidth + 'px',
      '--browserHeight': browserHeight + 'px',
    }"
  >
    <div class="awards-title">
      <p class="title-text">Awards and Honors</p>
      <div class="title-underline"></div>
    </div>
    <p class="awards-text" :isMobile="sizes.isMobile.value">
      MetaNovas has not only received recognition in numerous international
      top-tier events for its professional knowledge and leading R&D
      capabilities, but also earned acclaim from various mainstream
      international media.
    </p>
    <div class="awards-content">
      <AwardsCard
        v-for="data in cardData"
        :key="data.title"
        :data="data"
        class="awards-card"
      />
    </div>
  </div>
</template>

<script>
import AwardsCard from "../../components/About/AwardsCard.vue";
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";

export default {
  name: "Awards",
  components: { AwardsCard },

  setup() {
    const cardData = [
      {
        url: "/images/about/awards-card-01.png",
        title: "Champion of the L'Oréal 2023 Big Bang Beauty Tech Challenge",
        link: "https://finance.yahoo.com/news/metanovas-wins-loreal-big-bang-171800060.html",
      },
      {
        url: "/images/about/awards-card-02.png",
        title: "Top Industrial Presentation at the 2023 IFSCC International Cosmetic Conference",
      },
      {
        url: "/images/about/awards-card-03.png",
        title: "2023 Best Artificial Intelligence Company in Functional Food Development",
        link: "https://www.lux-review.com/winners/metanovas-biotech/",
      },
      {
        url: "/images/about/awards-card-04.png",
        title: "Published Top-tier Papers",
      },
      {
        url: "/images/about/awards-card-05.png",
        title: "Received the 'Top 250 Most Promising Global Health Tech Companies' honors in both 2022 and 2023",
        link: "https://www.galengrowth.com/healthtech-250/?utm_source=linkedin&utm_medium=organic&utm_campaign=ht_250"
      },
      {
        url: "/images/about/awards-card-06.png",
        title: "Featured in Leading Mainstream Media",
        link: "https://www.digitaljournal.com/pr/news/newsfile/metanovas-wins-l-oreal-big-bang-future-new-product-research-x-artificial-intelligence-cross-domain-championship",
      },
    ];
    const sizes = useScreenSize();
    const scale = 2.25;
    const { browserWidth, browserHeight } = calBrowserSize(sizes, scale);

    return { cardData, sizes, browserWidth, browserHeight };
  },
};
</script>

<style scoped>
.about-awards {
  display: flex;
  flex-direction: column;
  min-height: 1480px;
  align-items: center;
  justify-content: flex-start;
}
.awards-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8%;
  margin-bottom: 5%;
}
.title-text {
  font-size: 52px;
  font-weight: 500;
}
.title-underline {
  width: 40px;
  height: 2px;
  background-color: black;
}
.awards-text {
  font-size: 24px;
  font-weight: normal;
  width: 85%;
  margin-bottom: 8%;
}
.awards-content {
  width: 85%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4%;
}
.awards-card {
  margin-bottom: 4%;
}

/* mobile view */
@media (max-width: 768px) {
  .about-awards {
    width: var(--browserWidth);
    /* height: var(--browserHeight); */
    min-height: 0;
    padding-bottom: 15%;
  }
  .awards-title {
    margin-top: 20%;
    margin-bottom: 8%;
  }
  .title-text {
    font-size: 20px;
    font-weight: 500;
  }
  .title-underline {
    width: 20px;
  }
  .awards-text {
    width: 90%;
    font-size: 12px;
    margin-bottom: 60px;
  }
  .awards-content {
    width: 100%;
    gap: 4%;
  }
  .awards-card {
    max-width: 45%;
    margin-bottom: 10px;
  }
}
</style>