<template>
  <link href='https://fonts.googleapis.com/css?family=Inter' rel='stylesheet'>
  <div class="main-outer-container">
    <nav-bar
      :style="`position: fixed; top: 0; z-index: 100; background-color: #182945; width: ${headerWidth}px; `" :isMobile="isMobile"></nav-bar>
    <div :style="`height: ${isMobile ? '94px' : '76px'}; width: ${headerWidth}px;`"></div>
    <router-view :style="`width: ${headerWidth}px;`" />
    <SearchBar :style="`width: ${headerWidth}px;`"></SearchBar>
    <ContactUs :style="`width: ${headerWidth}px;`" id="support"></ContactUs>
  </div>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
import ContactUs from '@/views/About/ContactUs.vue';
import { ref, } from 'vue';

export default {
  components: {
    ContactUs,
    SearchBar,
  },
  setup() {
    var headerWidth = ref(window.innerWidth);
    var isMobile = ref(window.innerWidth <= 768);
    return {
      headerWidth,
      isMobile,
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  },
  methods: {
    handleWindowSizeChange() {
      // console.log(window.innerWidth)
      this.headerWidth = window.innerWidth;
      if (window.innerWidth <= 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  }
}
</script>

<style>
#app {
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

:root {
  --primary-color: #27406F;
  --secondary-color: #182945;
  --accent-color: #6AC89B;
  --contrast-color: #EFFAFF;
}

.v-btn {
  text-transform: none !important;
}

.main-outer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
