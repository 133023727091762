// import { ref, onMounted, onUnmounted } from 'vue';

// export const useScreenSize = () => {
//     const browserWidth = ref(window.innerWidth);
//     const deviceWidth = ref(screen.width);
//     const isMobile = ref(window.innerWidth <= 768);

//     const browserResized = () => {
//         browserWidth.value = window.innerWidth;
//         deviceWidth.value = screen.width;
//         isMobile.value = browserWidth.value <= 768;
//         console.log("browser width: ", browserWidth.value, "screen width", deviceWidth.value, "isMobile: ", isMobile.value);
//     };

//     onMounted(() => {
//         window.addEventListener('resize', browserResized);
//     });

//     onUnmounted(() => {
//         window.removeEventListener('resize', browserResized);
//     });

//     return {
//         browserWidth,
//         deviceWidth,
//         isMobile
//     };
// }

import { ref, onMounted, onUnmounted } from 'vue';

export const useScreenSize = () => {
    const browserWidth = ref(window.innerWidth);
    const deviceWidth = ref(screen.width);
    const isMobile = ref(window.innerWidth <= 768);
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    const handleMediaQueryChange = (e) => {
        isMobile.value = e.matches;
        browserWidth.value = window.innerWidth;
        // console.log("Media query changed: ", isMobile.value);
    };

    onMounted(() => {
        mediaQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);
    });

    onUnmounted(() => {
        mediaQuery.removeListener(handleMediaQueryChange);
    });

    return {
        browserWidth,
        deviceWidth,
        isMobile
    };
}
