<template>
    <product-top-page :title="title" :subTitle="subTitle" :cards="cards" :text="text" class="background" :class="backgroundClass" :style="`width: ${screenSize.browserWidth} px;`"
        v-lazy:background-image="'/images/product/8609504.png'"
    ></product-top-page>
    <product-bar :diagramBar="diagramBar" :diagramBarText="diagramBarText"></product-bar>

    <img v-lazy="'/images/product/MetaCono.png'" :width="image1.width" :height="image1.height" style="align-self: center;" :class="imageMargin"></img>
    <div v-if="isMobile" style="margin-bottom: 10%;">
        <div style="cursor: pointer; font-size: 10px; line-height: 19px; letter-spacing: 0; text-decoration: underline; color: #717173;" @click="zoomImage($event, 'image1')">click to zoom in</div>
        <div v-if="zoomInImage1">
            <div :style="`width: ${windowWidth}px; height: ${windowHeight * 2}px;`" class="page-mask" @click="closeDrawer($event, 'image1')"></div>
            <img v-lazy="'/images/product/MetaCono.png'" :width="windowWidth" style="z-index: 101; left: 0; top: 20%; position: fixed; background-color: white;"></img>
        </div>
    </div>

    <div style="width: 100%; height: 40px; background-color: #D7DEF1;"></div>

    <div style="display: flex; flex-direction: column; margin-top: 70px;">
        <h1 :class="moreAdvantagesTitleClass">More advantages</h1>
        <div style="width: 40px; align-self: center; border-bottom: 2px solid black; margin-top: 22px;"></div>

        <div :class="moreAdvantagesClass">
            <div>
                <h1 :class="moreAdvantagesTitleTitleClass">MetaCono has only two disulfide bonds, reducing toxicity while maintaining high bioactivity</h1>
                <div v-if="isMobile" class="divider"></div>
                <p :class="moreAdvantagesTitleBodyClass">Conotoxin is a natural biopeptide to reduce wrinkles. It can specifically block the electrical conduction of neurons in muscles and inhibit muscle contraction. MetaCono is a biomimetic peptide of conotoxin with two disulfide bonds, which achieves lower toxicity and higher biological activity compared to other conotoxins. Additionally, MetaCono exhibits excellent in vivo stability and has a lower synthesis cost.</p>
            </div>
        </div>

        <div :class="moreAdvantagesClass">
            <div>
                <h1 :class="moreAdvantagesTitleTitleClass">MetaCono can reduce facial wrinkles within 30 minutes</h1>
                <div v-if="isMobile" class="divider"></div>
                <p :class="moreAdvantagesTitleBodyClass">The human test of MetaCono showed that the facial lines of the subjects were significantly reduced in a short period of time, indicating the significant effect of MetaCono's immediate wrinkle removal.</p>
            </div>
        </div>

        <v-row v-if="!isMobile" no-gutters style="width: 1220px; align-self: center; margin-top: 42px; margin-bottom: 54px;">
            <v-col cols="12" :md="6" style="display: flex; flex-direction: column; align-items: center;">
                <div class="compare-text compare-text-title">
                    <h3>Before</h3>
                    <h3>After</h3>
                </div>
                <draggable-container width="354" height="269" image1="/images/product/compare/5.png" image2="/images/product/compare/6.png" positionX="center" positionY="-20px" index="1"></draggable-container>
                <div class="compare-text">
                    Comparison before and after using MetaCono for 30 minutes
                </div>
            </v-col>

            <v-col cols="12" :md="6" style="display: flex; flex-direction: column; align-items: center;">
                <div class="compare-text compare-text-title">
                    <h3>Before</h3>
                    <h3>After</h3>
                </div>
                <draggable-container width="354" height="269" image1="/images/product/compare/7.png" image2="/images/product/compare/8.png" positionX="center" positionY="-20px" index="2"></draggable-container>
                <div class="compare-text">
                    Comparison before and after using MetaCono for 1 day (3 times)
                </div>
            </v-col>
        </v-row>

        <v-row v-else no-gutters style="align-self: center;" :class="imageMargin">
            <v-col cols="12" :md="6" style="display: flex; flex-direction: column; align-items: center; margin-bottom: 30px;">
                <div class="compare-text compare-text-title" :style="`margin-bottom: 5px; font-size: 12px; width: ${compareImage.width}px`">
                    <h3>Before</h3>
                    <h3>After</h3>
                </div>
                <draggable-container :width="compareImage.width" :height="compareImage.height" image1="/images/product/compare/5.png" image2="/images/product/compare/6.png" positionX="center" positionY="-20px" index="3"></draggable-container>
                <div class="compare-text" :style="`margin-bottom: 5px; font-size: ${isMobile ? '10px' : '22px'}; width: ${compareImage.width}px`">
                    Comparison before and after using MetaCono for 30 minutes
                </div>
            </v-col>

            <v-col cols="12" :md="6" style="display: flex; flex-direction: column; align-items: center;">
                <div class="compare-text compare-text-title" :style="`margin-bottom: 5px; font-size: 12px; width: ${compareImage.width}px`">
                    <h3>Before</h3>
                    <h3>After</h3>
                </div>
                <draggable-container :width="compareImage.width" :height="compareImage.height" image1="/images/product/compare/7.png" image2="/images/product/compare/8.png" positionX="center" positionY="-20px" index="4"></draggable-container>
                <div class="compare-text" :style="`margin-bottom: 5px; font-size: ${isMobile ? '10px' : '22px'}; width: ${compareImage.width}px`">
                    Comparison before and after using MetaCono for 1 day (3 times)
                </div>
            </v-col>
        </v-row>

        <div :class="moreAdvantagesClass">
            <div>
                <h1 :class="moreAdvantagesTitleTitleClass">MetaCono can promote skin elasticity and reduce wrinkles with a prolonged effect</h1>
                <div v-if="isMobile" class="divider"></div>
                <p :class="moreAdvantagesTitleBodyClass">Compared with the competing product, MetaCono has an excellent performance in improving skin firmness and smoothness with an immediate and prolonged effect.</p>
            </div>
        </div>
    
        <img v-if="!isMobile" src="../../../public/images/product/MetaCono1.png" :width="image4.width" :height="image4.height" style="align-self: center;" :class="imageMargin"></img>
        <img v-if="isMobile" src="../../../public/images/product/MetaConoMobile.png" width="310" height="auto" style="align-self: center;" :class="imageMargin"></img>

        <ProductButton style="align-self: center; margin-top: 3%; margin-bottom: 3%;" color="#8476BE" :isMobile="isMobile"></ProductButton>
    </div>
</template>

<script>
import ProductTopPage from "@/components/Products/ProductTopPage.vue"
import ProductBar from "@/components/Products/ProductBar.vue"
import DraggableContainer from '@/components/Products/DraggableContainer.vue'
import ProductButton from '@/components/Products/ProductButton.vue'
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";
import { ref } from 'vue';
export default {
    components: {
        ProductTopPage,
        ProductBar,
        DraggableContainer,
        ProductButton,
    },
    setup() {
        var screenSize = useScreenSize()
        var isMobile = screenSize.isMobile.value;
        var backgroundClass = isMobile ? 'background-extra-mobile' : 'background-extra';
        var image1 = {
            width: isMobile ? window.innerWidth * 0.9 : window.innerWidth * 0.9 < 1203 ? window.innerWidth * 0.9 : 1203,
            height: isMobile ? 'auto' : 'auto',
        }
        var windowWidth = window.innerWidth;
        var windowHeight = window.innerHeight;
        var zoomInImage1 = ref(false)
        var dividerHeight = isMobile ? "15px" : "40px";
        var moreAdvantagesTitleClass = isMobile ? "more-advantages-title-text=mobile" : "more-advantages-title-text";
        var moreAdvantagesClass = isMobile ? "more-advantages-title-mobile more-advantages-text" : "more-advantages-title more-advantages-text";
        var moreAdvantagesTitleTitleClass = isMobile ? "more-advantages-title-title-text-mobile" : "more-advantages-title-title-text";
        var moreAdvantagesTitleBodyClass = isMobile ? "more-advantages-title-body-text-mobile" : "more-advantages-title-body-text";
        var image2 = {
            width: isMobile ? window.innerWidth * 0.9 : window.innerWidth * 0.9 < 782 ? window.innerWidth * 0.9 : 782,
            height: isMobile ? 'auto' : 'auto',
        }
        var imageMargin = isMobile ? "image-margin-mobile" : "image-margin";
        var image3 = {
            width: isMobile ? window.innerWidth * 0.9 : window.innerWidth * 0.9 < 782 ? window.innerWidth * 0.9 : 782,
            height: isMobile ? 'auto' : 'auto',
        }
        var productButtonMarginBottom = isMobile ? "10%" : "5%";

        var title = "Anti-wrinkle peptide MetaCono";
        var subTitle = "Instant wrinkle removal";
        var cards = [
            {
                title: "Efficacy",
                text: "Relax facial muscles and instantly erase facial wrinkles",
            },
            {
                title: "Advantage",
                text: "Possess remarkable anti-wrinkle effect with low toxicity and high stability ",
            },
        ]
        var text = "MetaCono peptide is designed for Nav1.4 (a biological target for muscle relaxation), which can precisely block the target channel and achieve a significant wrinkle-reducing effect within thirty minutes. Compared to the wild-type peptides, MetaCono provides better in vivo stability and lower toxicity while retaining its high bioactivity."
        var diagramBar = {
            left: "Mechanism diagram",
            right: "MetaCono can reduce facial wrinkles within 30 minutes",
            color: "#8476BE",
        }
        var diagramBarText = "Nav1.4 channel is a channel for sodium ions to rapidly enter muscle cells on the membranes. When Nav1.4 is stimulated, it leads to depolarization of the muscle cell membranes and ultimately causes muscle contraction. MetaCono can selectively block the Nav1.4 channel in facial muscles and lead to the relaxation of facial muscles and reduction of wrinkles caused by muscle contraction and facial expressions."

        var compareImage = {
            width: window.innerWidth,
            height: Math.floor(269 / 354 * (window.innerWidth)),
        }
        var image4 = {
            width: window.innerWidth * 0.9 < 1072 ? window.innerWidth * 0.9 : 1072,
            height: 'auto'
        }

        return {
            screenSize,
            isMobile,
            backgroundClass,
            image1,
            windowHeight,
            windowWidth,
            zoomInImage1,
            dividerHeight,
            moreAdvantagesTitleClass,
            moreAdvantagesClass,
            moreAdvantagesTitleTitleClass,
            moreAdvantagesTitleBodyClass,
            image2,
            imageMargin,
            image3,
            productButtonMarginBottom,
            image4,

            title,
            subTitle,
            cards,
            text,
            diagramBar,
            diagramBarText,
            compareImage,
        }
    },
    methods: {
        zoomImage(event, image) {
            if (image == 'image1') {
                this.zoomInImage1 = true;
            }
        },
        closeDrawer(event, image) {
            if (image == 'image1') {
                this.zoomInImage1 = false;
            }
        }
    }
}
</script>

<style scoped>
.page-mask {
    background-color: black;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.background {
    /* background-image: url("../../../public/images/product/8609504.png"); */
    background-repeat: no-repeat;
    width: 100%;
    /* background-size: 400%; */
    /* background-position-x: left; */
    /* background-position-y: top; */
}
.background-extra {
    padding-bottom: 75px; 
    background-size: cover; 
    background-position-y: center;
    background-position-x: left;
}
.background-extra-mobile {
    padding-bottom: 5%; 
    background-size: cover;
    background-position-y: top;
    background-position-x: -400px;
}

.more-advantages-title-text {
    font-size: 52px; 
    line-height: 50px; 
    letter-spacing: -0.8px; 
    color: black;
}

.more-advantages-title-text-mobile {
    font-size: 20px; 
    line-height: 50px; 
    letter-spacing: 0; 
    color: black;
}

.more-advantages-title-mobile {
    padding-left: 6%;
    padding-right: 6%;
    width: 90%; 
    height: 305px; 
    align-self: center; 
    text-align: left; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    margin-top: 4%;
    margin-bottom: 4%;
}

.more-advantages-title-title-text {
    font-size: 30px; 
    line-height: 50px; 
    letter-spacing: -0.8px; 
    color: black;
    margin-bottom: 40px;
}

.more-advantages-title-title-text-mobile {
    font-size: 14px; 
    line-height: 20px; 
    font-weight: bold;
    letter-spacing: 0px; 
    color: black;
}

.more-advantages-title-body-text {
    font-size: 20px; 
    line-height: 32px; 
    letter-spacing: -0.8px; 
    color: #2E2E27;
}

.more-advantages-title-body-text-mobile {
    font-size: 12px; 
    line-height: 20px; 
    letter-spacing: 0px; 
    font-weight: lighter;
    color: #2E2E27;
}

.more-advantages-title {
    padding: 0 50px;
    width: 90%; 
    height: 344px; 
    align-self: center; 
    text-align: left; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.more-advantages-body {
    width: 1140px; 
    height: 290px;
}
.more-advantages-text {
    background-color: #F5F5F5;
    border-radius: 38px;
    align-self: center; 
    text-align: left; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
}

.compare-text {
    font-size: 22px;
    font-weight: bold;
    color: black;
}
.compare-text-title {
    margin-bottom: 30px; 
    width: 354px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: black;
    margin-top: 5%;
    margin-bottom: 6%;
}
.image-margin {
    margin-top: 96px; 
    margin-bottom: 111px;
}
.image-margin-mobile {
    margin-top: 10%; 
    margin-bottom: 10%;
}
</style>