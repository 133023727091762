<template>
    <svg width="47" height="57" viewBox="0 0 47 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M37 11.5V11C37 5.47715 32.5228 1 27 1H11C5.47715 1 1 5.47715 1 11V37C1 41.9706 5.02944 46 10 46V46"
            stroke="#284070" stroke-width="2" />
        <rect x="10" y="11" width="36" height="45" rx="10" stroke="#284070" stroke-width="2" />
        <rect x="18" y="22" width="5" height="5" rx="2.5" stroke="#284070" stroke-width="2" />
        <rect x="18" y="30" width="5" height="5" rx="2" stroke="#284070" stroke-width="2" />
        <rect x="18" y="38" width="5" height="5" rx="2.5" stroke="#284070" stroke-width="2" />
        <rect x="26" y="22" width="5" height="5" rx="2.5" stroke="#284070" stroke-width="2" />
        <rect x="26" y="30" width="5" height="5" rx="2" stroke="#284070" stroke-width="2" />
        <rect x="26" y="38" width="5" height="5" rx="2.5" stroke="#284070" stroke-width="2" />
        <rect x="34" y="22" width="5" height="5" rx="2.5" stroke="#284070" stroke-width="2" />
        <rect x="34" y="30" width="5" height="5" rx="2" stroke="#284070" stroke-width="2" />
        <rect x="34" y="38" width="5" height="5" rx="2.5" stroke="#284070" stroke-width="2" />
    </svg>
</template>