<template>
  <div class="news-container" :class="{ 'mobile-view': sizes.isMobile.value }" :style="{
    '--browserWidth': browserWidth + 'px',
    '--browserHeight': browserHeight + 'px',
  }">
    <div class="news-cards">
      <NewsCard v-for="newsItem in newsData" :key="newsItem.id" :data="newsItem" class="news-card"
        :isMobile="sizes.isMobile.value" />
    </div>
  </div>
</template>

<script>
import NewsCard from "@/components/Media/NewsCard";
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";

export default {
  name: "CorporateNews",
  components: { NewsCard },

  setup() {
    const newsData = [
      {
        id: 1,
        url: "/images/media/news04.jpg",
        text1: "MetaNovas Biotech presented at the Future Food Asia 2024 awards ceremony held in Singapore, and successfully made it into the Top 10 Finalists",
        time: "2024-05-16 23:04",
        link: "",
      },
      {
        id: 2,
        url: "/images/media/news03.png",
        text1: "MetaNovas was selected by the British authoritative medical and health magazine as the Leading AI Skincare Company 2024",
        text2: 'On April 10, 2024, the authoritative British medical and health magazine "Global Health & Pharma" (hereinafter referred to as "GHP"), a global information platform, released the list of winners of the 9th Biotechnology Awards, and MetaNovas Biotech was awarded the Leading AI Skincare Company 2024.',
        time: "2024-04-15 14:25",
        link: "https://www.ghp-news.com/winners/metanovas-biotech/",
      },
      {
        id: 3,
        url: "/images/media/news01.png",
        text1: "MetaNovas won the L'Oréal Big Bang 'Foreseeing New Industry Research x Artificial Intelligence' cross-field track championship",
        text2: "November 6, 2023, Shanghai - L'Oréal announced the 17 winning companies from different regional markets in the 2023 North Asia Big Bang Beauty Technology Creation Camp (hereinafter referred to as \"Big Bang\"), and held a grand awards ceremony.",
        time: "2023-11-09 18:28",
        link: "https://finance.yahoo.com/news/metanovas-wins-loreal-big-bang-171800060.html",
      },
      {
        id: 4,
        url: "/images/media/news02.png",
        text1: "Metanovas Biotech has been selected as one of the top 250 most promising health technology companies in the world for two consecutive years, and has won the award again after being shortlisted in 2022",
        text2: "MetaNovas, as the world's leading artificial intelligence-driven biological innovation platform, is honored to be shortlisted as one of HolonIQ 2023 East Asia's 200 Leading Innovators in Health Technology.",
        time: "2022-12-15 23:16",
        link: "https://www.galengrowth.com/healthtech-250/?utm_source=linkedin&utm_medium=organic&utm_campaign=ht_250",
      },
    ];
    const sizes = useScreenSize();
    const scale = 4;
    const { browserWidth, browserHeight } = calBrowserSize(sizes, scale);

    return { newsData, sizes, browserWidth, browserHeight };
  },
};
</script>

<style scoped>
.news-container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.news-cards {
  width: 80%;
  height: 100%;
  padding: 5% 0;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: space-between;
}

.news-card {
  width: 100%;
  height: 30%;
  margin-bottom: 5%;
}

@media (max-width: 768px) {
  .news-container {
    width: var(--browserWidth);
    /* height: var(--browserHeight); */
  }

  .news-cards {
    width: 85%;
    padding: 20% 0;
  }
}
</style>