<template>
  <div class="about-us" :class="{ 'mobile-view': sizes.isMobile.value }" :style="{
    '--browserWidth': browserWidth + 'px',
    '--browserHeight': browserHeight + 'px',
  }">
    <div class="about-us-title">
      <p class="title-text">About Us</p>
      <div class="title-underline"></div>
    </div>
    <div class="about-us-carousel">
      <button @click="prevCard" class="carousel-btn">
        <el-icon>
          <ArrowLeftBold />
        </el-icon>
      </button>
      <AboutUsCard :data="aboutUsData[currentIndex]" class="carousel-card" :isMobile="sizes.isMobile.value" />
      <button @click="nextCard" class="carousel-btn">
        <el-icon>
          <ArrowRightBold />
        </el-icon>
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import AboutUsCard from "@/components/About/AboutUsCard.vue";
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";

export default {
  name: "AboutUs",
  components: { AboutUsCard },
  setup() {
    const aboutUsData = [
      {
        text: "With the principles of science, professionalism and innovation, MetaNovas has gained recognition from international brands such as L'Oréal and Beiersdorf. We have established long-term and stable partnerships with many top brands in cosmetics, foods and biopharmaceuticals, becoming their core technology collaborator and raw material supplier.",
        url: "/images/about/about-us-card01.jpg",
      },
      {
        text: "Since its inception, MetaNovas has achieved significant progress and earned international recognition and awards. We not only joined the Massachusetts Institute of Technology Industrial Liaison Program, but also had the honor of delivering a top-tier industry presentation at the 33rd IFSCC International Congress of Cosmetic Sciences. As a notable biotech startup, we were crowned as the champion of the 'L'Oréal Big Bang Beauty Tech Challenge' and have been recognized as one of the world's most promising health technology companies for two consecutive years.",
        url: "/images/about/about-us-card02.jpg",
      },
      {
        text: "MetaNovas Biotech is an innovative biotechnology company established in early 2021 in Los Angeles, USA and Shanghai, China. Our mission is to integrate artificial intelligence with life sciences and delve into complex biological networks through deep learning, knowledge graphs, natural language processing and multi-omics analysis. MetaNovas is dedicated to becoming a global leader in AI-driven efficient ingredient and product design and development platforms.",
        url: "/images/about/about-us-card03.jpg",
      },
    ];
    const currentIndex = ref(0);
    const nextCard = () => {
      if (currentIndex.value < aboutUsData.length - 1) {
        currentIndex.value++;
      } else {
        currentIndex.value = 0;
      }
    };
    const prevCard = () => {
      if (currentIndex.value > 0) {
        currentIndex.value--;
      } else {
        currentIndex.value = aboutUsData.length - 1;
      }
    };
    const sizes = useScreenSize();
    const scale = 1.45;
    const { browserWidth, browserHeight } = calBrowserSize(sizes, scale);

    return {
      aboutUsData,
      currentIndex,
      nextCard,
      prevCard,
      sizes,
      browserWidth,
      browserHeight,
    };
  },
};
</script>

<style scoped>
.about-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 750px;
  position: relative;
  z-index: 2;
}

.about-us::after {
  content: "";
  background-image: url("../../../public/images/about/about-us-bg.jpg");
  background-size: cover;
  background-position: center;
  opacity: 0.5;

  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.about-us-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 15%;
  z-index: 3;
  margin-bottom: 120px;
}

.title-text {
  font-size: 52px;
  font-weight: normal;
}

.title-underline {
  width: 40px;
  height: 2px;
  background-color: black;
}

.about-us-carousel {
  display: flex;
  flex-direction: row;
  width: 95%;
  align-items: center;
  justify-content: space-between;
  z-index: 3;
}

.carousel-btn {
  font-size: 50px;
  color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.carousel-card {
  width: 85%;
  height: 35%;
}

@media (max-width: 768px) {
  .about-us {
    width: var(--browserWidth);
    /* height: var(--browserHeight); */
  }

  .about-us-title {
    margin-top: 10%;
    margin-bottom: 15%;
  }

  .title-text {
    font-size: 20px;
  }

  .title-underline {
    width: 20px;
  }

  .about-us-carousel {
    width: 95%;
    height: 60%;
  }

  .carousel-btn {
    font-size: 30px;
  }

  .carousel-card {
    width: 90%;
    height: 100%;
  }
}
</style>