<template>
    <div :class="diagramBarWrapperClass">
        <div class="diagram-bar">
            <div :class="diagramBarLeftClass" :style="'background-color: ' + diagramBar.color">
                <p>{{ diagramBar.left.split(" ")[0] }}</p>
                <p>{{ diagramBar.left.split(" ")[1] }}</p>
            </div>
            <div :class="diagramBarRightClass">
                {{ diagramBar.right }}
            </div>
        </div>
        <p :class="diagramBarTextClass">
            {{ diagramBarText }}
        </p>
    </div>
</template>

<script>
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";
import { ref } from 'vue';
export default {
    props: ['diagramBar', 'diagramBarText'],
    setup(props) {
        var diagramBar = props.diagramBar;
        var diagramBarText = props.diagramBarText;
        var screenSize = useScreenSize()
        var isMobile = screenSize.isMobile.value;
        var diagramBarWrapperClass = isMobile ? "diagram-bar-wrapper-mobile" : "diagram-bar-wrapper";
        var diagramBarLeftClass = isMobile ? "diagram-bar-left-mobile" : "diagram-bar-left";
        var diagramBarRightClass = isMobile ? "diagram-bar-right-mobile" : "diagram-bar-right";
        var diagramBarTextClass = isMobile ? "diagram-bar-text-mobile" : "diagram-bar-text";

        return {
            diagramBar,
            diagramBarText,

            screenSize,
            isMobile,
            diagramBarWrapperClass,
            diagramBarLeftClass,
            diagramBarRightClass,
            diagramBarTextClass,
        }
    },
}
</script>

<style>
.diagram-bar-wrapper {
    padding-top: 105px; 
    display: flex; 
    flex-direction: column;
}

.diagram-bar-wrapper-mobile {
    padding-top: 5%; 
    display: flex; 
    flex-direction: column;
    width: 100%;
}

.diagram-bar {
    display: grid; 
    grid-template-columns: 25% 75%;
    align-self: center; 
    box-shadow: 3px 3px 3px lightgrey; 
    border-radius: 25px;
    margin-bottom: 50px;
    letter-spacing: -0.8px;
    width: 90%;
}

.diagram-bar-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center; 
    height: 125px;
    padding-top: 22.5px;
    padding-bottom: 22.5px;
    border-top-left-radius: 25px; 
    border-bottom-left-radius: 25px;
    /* width: 25%;  */
    color: white; 
    font-size: 32px; 
    font-weight: bold; 
    line-height: 40px;
}

.diagram-bar-left-mobile {
    align-self: center; 
    padding-top: 2%;
    padding-bottom: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: white;  */
    border-top-left-radius: 15px; 
    border-bottom-left-radius: 15px;
    color: white; 
    font-size: 12px; 
    font-weight: bold; 
    line-height: 19px;
    text-align: center;
    padding-left: 2%;
    padding-right: 2%;
    /* width: 25%; */
    height: 100%;
}

.diagram-bar-right {
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-self: center; 
    height: 125px;
    padding-top: 22.5px;
    padding-bottom: 22.5px;
    background-color: white; 
    border-top-right-radius: 25px; 
    border-bottom-right-radius: 25px;
    /* width: 75%; */
    color: black; 
    font-size: 32px; 
    font-weight: bold; 
    line-height: 40px;
    text-align: left;
    padding-left: 61.5px;
    padding-right: 61.5px;
}

.diagram-bar-right-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center; 
    padding-top: 2%;
    padding-bottom: 2%;
    background-color: white; 
    border-top-right-radius: 25px; 
    border-bottom-right-radius: 25px;
    color: black; 
    font-size: 12px; 
    font-weight: bold; 
    line-height: 19px;
    text-align: left;
    padding-left: 2%;
    padding-right: 2%;
    /* width: 70%; */
}

.diagram-bar-text {
    text-align: center; 
    width: 90%; 
    align-self: center; 
    color: black; 
    font-size: 20px; 
    line-height: 32px; 
    letter-spacing: -0.8px;
}
.diagram-bar-text-mobile {
    text-align: center; 
    width: 90%; 
    align-self: center; 
    color: black; 
    font-size: 12px;
    font-weight: 600; 
    line-height: 19px; 
    letter-spacing: 0;
}
</style>