<template>
  <div class="search-page"
    :style="`padding: ${searchPagePadding}; width: 100%; display: flex; flex-direction: column;`">
    <div :style="`width: ${isMobile ? '100%' : '85%'};`" class="header-container">
      <header class="header" :style="`padding: ${headerPadding}; width: 100%; flex-direction: ${browserWidth < 1335 ? 'column' : 'row'}; height: ${browserWidth < 1335 ? 'auto' : 'auto'};`">
        <div v-if="browserWidth < 1335"
          style="display: flex; flex-direction: row; width: 100%; justify-content: flex-end; margin-top: 3%;">
          <div style="display: flex; flex-direction: row;">
            <div class="follow-us-text-mobile">Follow Us</div>
            <linkedin-icon @click="openLinkedInUrl"></linkedin-icon>
            <youtube-icon @click="openYoutubeUrl"></youtube-icon>
          </div>
        </div>

        <div style="font-size: 24px; color: black;" v-if="browserWidth > 1335">
          MetaNovas Biotech
        </div>

        <div v-if="browserWidth > 1335" class="navbar-link-container" style="width: 75%;">
          <div class="navbar-link-row">
            <router-link :to="{ name: 'About', hash: '#aboutus' }" style="text-decoration: none">
              <div class="navbar-link" style="font-weight: bold" @click="navOnClick($event, 'navbar-about')"
                id="navbar-bottom-about">
                <span>About us</span>
              </div>
            </router-link>
            
            <router-link :to="{ name: 'ProductsIntroduction' }" style="text-decoration: none">
              <div class="navbar-link" style="font-weight: bold" @click="navOnClick($event, 'navbar-products')"
                id="navbar-bottom-products">
                <span>Products</span>
              </div>
            </router-link>

            <router-link :to="{ name: 'Technology' }" style="text-decoration: none">
              <div class="navbar-link" style="font-weight: bold" @click="navOnClick($event, 'navbar-technology')"
                id="navbar-bottom-technology">
                <span>Technology</span>
              </div>
            </router-link>

            <router-link :to="{ name: 'Media' }" style="text-decoration: none">
              <div class="navbar-link" style="font-weight: bold" @click="navOnClick($event, 'navbar-media')"
                id="navbar-bottom-media">
                <span>Media</span>
              </div>
            </router-link>

            <router-link :to="{ name: 'About', hash: '#support' }" style="text-decoration: none">
              <div class="navbar-link" style="font-weight: bold" @click="navOnClick($event, 'navbar-support')"
                id="navbar-support">
                <span>Suppport</span>
              </div>
            </router-link>


          </div>

          <div class="navbar-link-row">
            <router-link :to="{ name: 'About', hash: '#partnerships' }" style="text-decoration: none;">
              <div class="navbar-link" @click="navOnClick($event, 'navbar-about')"
                id="navbar-about-partnerships">
                <span>Cooperative Partnerships</span>
              </div>
            </router-link>

            <router-link :to="{ name: 'ProductsMetaAMP5' }" style="text-decoration: none">
              <div class="navbar-link" @click="navOnClick($event, 'navbar-products')" id="navbar-amp5">
                <span>MetaAMP5</span>
              </div>
            </router-link>

            <router-link :to="{ name: 'Technology', hash: '#metanlp' }" style="text-decoration: none">
              <div class="navbar-link" @click="navOnClick($event, 'navbar-technology')" id="navbar-metanlp">
                <span>MetaNLP®</span>
              </div>
            </router-link>
            
            <div></div>
            
            <router-link :to="{ name: 'About', hash: '#support' }" style="text-decoration: none">
              <div class="navbar-link" @click="navOnClick($event, 'navbar-support')" id="navbar-support-contact-us">
                <span>Contact us</span>
              </div>
            </router-link>
          </div>

          <div class="navbar-link-row">
            <router-link :to="{ name: 'About', hash: '#services' }" style="text-decoration: none">
              <div class="navbar-link" @click="navOnClick($event, 'navbar-about')" id="navbar-about-services">
                <span>R&D services</span>
              </div>
            </router-link>

            <router-link :to="{ name: 'ProductsMetaAMP33' }" style="text-decoration: none">
              <div class="navbar-link" @click="navOnClick($event, 'navbar-products')" id="navbar-amp33">
                <span>MetaAMP33</span>
              </div>
            </router-link>

            <router-link :to="{ name: 'Technology', hash: '#metakg' }" style="text-decoration: none">
              <div class="navbar-link" @click="navOnClick($event, 'navbar-technology')" id="navbar-metakg">
                <span>MetaKG®</span>
              </div>
            </router-link>
          </div>

          <div class="navbar-link-row">
            <router-link :to="{ name: 'About', hash: '#awards' }" style="text-decoration: none">
              <div class="navbar-link" @click="navOnClick($event, 'navbar-about')" id="navbar-about-awards">
                <span>Awards and Honours</span>
              </div>
            </router-link>

            <router-link :to="{ name: 'ProductsMetaCono' }" style="text-decoration: none">
              <div class="navbar-link" @click="navOnClick($event, 'navbar-products')" id="navbar-metacono">
                <span>MetaCono</span>
              </div>
            </router-link>

            <router-link :to="{ name: 'Technology', hash: '#metapep' }" style="text-decoration: none">
              <div class="navbar-link" @click="navOnClick($event, 'navbar-technology')" id="navbar-metapep">
                <span>MetaPep®</span>
              </div>
            </router-link>
          </div>

          <div class="navbar-link-row">
            <router-link :to="{ name: 'About', hash: '#product' }" style="text-decoration: none">
              <div class="navbar-link" @click="navOnClick($event, 'navbar-about')" id="navbar-about-product">
                <span>Products</span>
              </div>
            </router-link>

            <router-link :to="{ name: 'ProductsMetaPepOcean1' }" style="text-decoration: none">
              <div class="navbar-link" @click="navOnClick($event, 'navbar-products')" id="navbar-ocean1">
                <span>MetaPep-Ocean-1</span>
              </div>
            </router-link>

            <router-link :to="{ name: 'Technology', hash: '#metaomics' }" style="text-decoration: none">
              <div class="navbar-link" @click="navOnClick($event, 'navbar-technology')" id="navbar-metaomics">
                <span>MetaOmics®</span>
              </div>
            </router-link>
          </div>

          <div class="navbar-link-row">
            <div></div>
            <router-link :to="{ name: 'ProductsMetaAMP12' }" style="text-decoration: none">
              <div class="navbar-link" @click="navOnClick($event, 'navbar-products')" id="navbar-amp12">
                <span>MetaAMP12</span>
              </div>
            </router-link>
          </div>
        </div>
        <div v-else style="width: 100%;">
          <SearchBarMobile style="width: 100%;" />
        </div>
      </header>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import LinkedinIcon from '@/components/LinkedinIcon.vue';
import YoutubeIcon from '@/components/YoutubeIcon.vue';
import SearchBarMobile from '@/components/SearchBarMobile.vue';

export default {
  name: "FooterPage",
  components: {
    LinkedinIcon,
    YoutubeIcon,
    SearchBarMobile,
  },
  setup() {
    const input = ref("");
    const router = useRouter();
    var browserWidth = ref(window.innerWidth);

    const handleSearch = () => {
      if (input.value) {
        input.value = input.value.replace(/\s/, "");
        router.push({ name: "SearchPage", query: { q: input.value } });
      }
      input.value = "";
    };

    const navOnClick = (event, index) => {
      var elements = document.getElementsByClassName("navbar-link-active");
      for (let item of elements) {
        item.classList.remove(["navbar-link-active"]);
      }
      var element = document.getElementById(index);
      if (element) element.classList.add(["navbar-link-active"]);
    };

    var isMobile = ref(window.innerWidth <= 768);
    var navbarLinkFontSize = ref(isMobile.value ? '11px' : '15.4px');
    var searchPageFontSize = ref(isMobile.value ? '10px' : '15.4px');
    var searchPagePadding = ref(isMobile.value ? "0 10px" : "20px 10px");
    var headerPadding = ref(window.innerWidth < 1335 ? "0 10px" : "0");
    var navbarWidth = ref(isMobile.value ? "100%" : "441px");
    var followUsClass = ref(isMobile.value ? 'follow-us-text-mobile' : 'follow-us-text');

    return { input, handleSearch, navOnClick, isMobile, navbarLinkFontSize, searchPageFontSize, searchPagePadding, navbarWidth, followUsClass, headerPadding, browserWidth };
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  },
  methods: {
    openLinkedInUrl() {
      open('https://www.linkedin.com/company/metanovas-biotech', '_blank');
    },
    openYoutubeUrl() {
      open('https://www.youtube.com/channel/UClcKj7x_SaH1MaghU5XoKuA', '_blank');
    },
    handleWindowSizeChange() {
      // console.log(window.innerWidth)
      this.browserWidth = window.innerWidth;
      if (window.innerWidth < 1335) {

      }
      if (window.innerWidth <= 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      this.navbarLinkFontSize = this.isMobile ? '11px' : '15.4px';
      this.searchPageFontSize = this.isMobile ? '10px' : '15.4px';
      this.searchPagePadding = this.isMobile ? "0 10px" : "0 40px";
      this.headerPadding = this.isMobile ? "0 10px" : "0";
      this.navbarWidth = this.isMobile ? "100%" : "441px";
      this.followUsClass = this.isMobile ? 'follow-us-text-mobile' : 'follow-us-text';
    },
  }
};
</script>

<style scoped>
.header-container {
  display: flex; 
  flex-direction: column; 
  align-self: center
}

.navbar-link-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: 10%;
  /* width: 70%; */
}

.navbar-link-row {
  display: grid;
  grid-template-columns: 24% 22% 20% 18% 16%;
  width: 100%;
}

.navbar-link-col {
  display: flex;
  flex-direction: column;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: black;
  margin-top: 8%;
  margin-bottom: 3%;
}

.search-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 400px; */
  /* padding: 0 40px; */
  background-color: #F5F5F5;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* padding: 0 10px; */
  /* height: 60px; */
  width: 80%;
  /* flex-direction: column; */
}

.header-navbar {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.navbar-link {
  display: flex;
  text-decoration: none;
  white-space: nowrap;
  /* margin-right: 20px; */
  color: black;
  /* font-size: 15.4px; */
  font-weight: normal;
  letter-spacing: -0.2px;
}

.gg-chevron-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
  opacity: 0;
}

.gg-chevron-down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
}

.navbar-link:hover .gg-chevron-down {
  opacity: 1;
}

.navbar-link:hover {
  font-weight: bold;
}

.navbar-link-active {
  color: var(--accent-color) !important;
}

.dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: #F5F5F5;
  padding: 2%;
  width: 110%;
}

.dropdown-item-container {
  margin-top: 5px;
}

.search-page-underline {
  width: 80%;
  height: 1px;
  background-color: black;
  margin: 30px 0;
}

.search-page-text {
  /* font-size: 15.4px; */
  font-weight: normal;
  margin-bottom: 10px;
}

.follow-us-text-mobile {
  font-size: 12px;
  font-weight: bold;
  line-height: 23px;
  letter-spacing: 0.1px;
  margin-right: 4px;
}

.follow-us-text {
  font-size: 12px;
  font-weight: bold;
  line-height: 23px;
  letter-spacing: 0.1px;
}

@media (max-width: 768px) {
  .search-page {
    width: var(--browserWidth);
    height: var(--browserHeight);
  }

  .search-page-meida {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    margin-top: 3%;
    margin-bottom: 3%;
    margin-right: 5%;
    gap: 10px;
    font-size: 12px;
    font-weight: bold;
  }

  .header {
    width: 90%;
    justify-content: space-between;
  }

  .navbar-link {
    margin-right: 0;
    font-size: 11px;
  }

  .search-page-underline {
    width: 90%;
    height: 1px;
    background-color: black;
    margin-top: 5px;
    margin-bottom: 5%;
  }

  .search-page-text {
    width: 75%;
    font-size: 10px;
    margin-bottom: 0;
  }
}
</style>