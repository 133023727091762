<template>
  <div class="news-card" :class="{ 'mobile-view': isMobile }">
    <div v-if="!isMobile" class="card-logo">MetaNovas</div>
    <div class="card-content">
      <img :src="data.url" alt="" class="card-image" />
      <div class="card-visual">
        <p class="visual-text1">
          {{ data.text1 }}
        </p>
        <p class="visual-text2">
          {{ data.text2 }}
        </p>

        <div class="horizontal">
          <button class="visual-btn" v-if="data.link != ''" @click="openLink($event, data.link)">
            <img src="/images/media/media-btn-icon.png" class="btn-icon" />
            <span class="btn-text"> Read it !</span>
          </button>

          <div class="visual-time">
            {{ data.time }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsCard",
  props: ["data", "isMobile"],
  setup() {
    return {};
  },
  methods: {
    openLink(event, link) {
      window.open(link, '_blank');
    },
  },
}
</script>

<style scoped>
.horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.news-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 6% 7%;
  border-radius: 50px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  position: relative;
}

.visual-btn-area {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  border-top: 1px solid black;
  margin-top: auto;
}
.visual-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  width: 183px;
  height: 50px;
  border-radius: 25px;
  background-color: #284070;
  color: white;
}

.btn-icon {
  width: 40px;
  height: 40px;
}

.card-logo {
  position: absolute;
  top: 5%;
  right: 4%;
  color: #6ac89b;
  font-size: 24px;
  font-weight: bold;
  margin-left: auto;
}

.card-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  gap: 5%;
  font-size: 20px;
  font-weight: bold;
}

.card-image {
  width: 48%;
  aspect-ratio: 455/287;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-visual {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 45%;
  height: 100%;
  /* padding: 20px; */
}

.visual-text1 {
  text-align: left;
  border-bottom: 1px solid black;
  padding-bottom: 4%;
}

.visual-text2 {
  text-align: left;
  font-size: 14px;
  font-weight: lighter;
  color: #b5b6b6;
  margin: 4% 0;
}

.visual-time {
  font-size: 14px;
  color: #b5b6b6;
  font-weight: lighter;
  margin-left: auto;
}

/* mobile view */
@media (max-width: 768px) {
  .visual-btn {
    padding: 0 15px;
    width: 107px;
    height: 29px;
    border-radius: 15px;
  }
  .btn-icon {
    width: 20px;
    height: 20px;
  }

  .news-card {
    padding: 8%;
    border-radius: 20px;
    padding: 7%;
    margin: 0;
  }

  .card-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8%;
    font-size: 12px;
  }

  .card-image {
    width: 100%;
    aspect-ratio: 275/174;
    height: auto;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 5px;
  }

  .card-visual {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
  }

  .visual-text1 {
    font-size: 12px;
    font-weight: bold;
    border-bottom: none;
    padding-bottom: 0;
  }

  .visual-text2 {
    font-size: 8px;
    margin: 6% 0 4% 0;
  }

  .visual-time {
    font-size: 8px;
  }
}
</style>