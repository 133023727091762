<template>
  <div
    class="about-platform"
    :class="{ 'mobile-view': sizes.isMobile.value }"
    :style="{
      '--browserWidth': browserWidth + 'px',
      '--browserHeight': browserHeight + 'px',
    }"
  >
    <div class="platform-title">
      <p class="title-text">Technical Platform</p>
      <div class="title-underline"></div>
    </div>
    <div class="platform-cards">
      <PlatformCard
        v-for="data in cardData"
        :key="data.url"
        :data="data"
        class="platform-card"
        :isMobile="sizes.isMobile.value"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import PlatformCard from "@/components/About/PlatformCard.vue";
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";

export default {
  name: "Platform",
  components: { PlatformCard },

  setup() {
    const cardData = [
      {
        url: "/images/about/platform-icon-01.png",
        text: "Digestible data",
        route: "PlatformMetaNLP",
      },
      {
        url: "/images/about/platform-icon-02.png",
        text: "Computation-driven",
        route: "PlatformMetaKG",
      },
      {
        url: "/images/about/platform-icon-03.png",
        text: "System biology",
        route: "PlatformMetaPep",
      },
      {
        url: "/images/about/platform-icon-04.png",
        text: "Precision targeting",
        route: "PlatformMetaOmics",
      },
    ];
    const sizes = useScreenSize();
    const scale = 1.42;
    const { browserWidth, browserHeight } = calBrowserSize(sizes, scale);

    return { cardData, sizes, browserWidth, browserHeight };
  },
};
</script>

<style scoped>
.about-platform {
  display: flex;
  flex-direction: column;
  height: 680px;
  padding: 80px 0;
  background-color: #284070;
  align-items: center;
  justify-content: flex-start;
}
.platform-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 5%; */
  margin-bottom: 5%;
  /* margin-bottom: 150px; */
}
.title-text {
  font-size: 52px;
  font-weight: 500;
  color: white;
}
.title-underline {
  width: 40px;
  height: 2px;
  background-color: white;
}
.platform-cards {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3%;
}

/* mobile view */
@media (max-width: 768px) {
  .about-platform {
    width: var(--browserWidth);
    /* height: var(--browserHeight); */
    height: auto !important;
    padding-bottom: 15%;
  }
  .platform-title {
    margin-top: 15%;
    margin-bottom: 20%;
  }
  .title-text {
    font-size: 20px;
  }
  .title-underline {
    width: 20px;
  }
  .platform-cards {
    width: 90%;
    flex-wrap: wrap;
    gap: 10px;
  }
}
</style>