<template>
  <div
    class="home-page"
    :class="{ 'mobile-view': sizes.isMobile.value }"
    :style="{
      '--browserWidth': browserWidth + 'px',
      '--browserHeight': browserHeight + 'px',
    }"
  >
    <p class="content-title">MetaNovas Biotech</p>
    <p class="content-text">
      We look forward to your next breakthrough, starting with MetaNovas.
    </p>
    <!-- <button class="content-btn" @click="downloadFile">
      <span>Download product brosure</span>
      <el-icon><Right /></el-icon>
    </button> -->
    <IntroductionButton :isMobile="sizes.isMobile.value"/>
  </div>
</template>

<script>
import IntroductionButton from "@/components/Products/IntroductionButton.vue";
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";

export default {
  name: "HomePage",
  components: {IntroductionButton},
  setup() {
    const sizes = useScreenSize();
    const scale = 1.44;
    const { browserWidth, browserHeight } = calBrowserSize(sizes, scale)

    return { sizes, browserWidth, browserHeight };
  },
};
</script>

<style scoped>
.home-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  background-image: url("../../../public/images/about/home-page-bg.png");
  background-size: cover;
  background-position: center;
}
.content-title {
  font-size: 96px;
  color: white;
  font-weight: normal;
}
.content-text {
  display: inline-block;
  width: 55%;
  font-size: 32px;
  color: var(--accent-color);
  margin: 20px 0 40px 0;
  font-weight: normal;
}
.content-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 256px;
  height: 50px;
  border-radius: 25px;
  background-color: #199866;
  color: white;
  font-size: 15.4px;
  gap: 5px;
}

@media (max-width: 768px) {
  .home-page {
    width: var(--browserWidth);
    height: var(--browserHeight);
  }
  .content-title {
    font-size: 36px;
  }
  .content-text {
    font-size: 16px;
    width: 90%;
  }
}
</style>