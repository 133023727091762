<template>
  <div
    class="media-news"
    :class="{ 'mobile-view': sizes.isMobile.value }"
    :style="{
      '--browserWidth': browserWidth + 'px',
      '--browserHeight': browserHeight + 'px',
    }"
  >
    <div class="news-title">
      <p class="title-main">Corporate News</p>
      <p v-if="isMobile" class="title-subtitle" >Welcome to MetaNovas!</p>
    </div>
    <div class="news-content">
      <img v-if="isMobile" :src="supportPageContent.url" class="content-image" />
      <div class="content-visual">
        <p class="content-text1">{{ supportPageContent.text1 }}</p>
        <p class="content-text2">{{ supportPageContent.text2 }}</p>
        <p class="content-time">
          {{ supportPageContent.time }}
        </p>
      </div>
      <img v-if="!isMobile" :src="supportPageContent.url" class="content-image" />
    </div>
    <span v-if="!sizes.isMobile.value" class="news-CTA">
      Scroll down to learn more
      <el-icon class="arrow-icon"><DArrowRight /></el-icon>
    </span>
  </div>
</template>

<script>
import NavBar from "@/components/Navbar.vue";
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";

export default {
  name: "CorporateNews",
  components: { NavBar },
  setup() {
    const supportPageContent = {
      url: "images/media/media-image01.jpg",
      text1: "MetaNovas CTO Dr. Yu Lun attended the IFSCC conference, the Nobel Prize in the cosmetics industry, and delivered a speech and released the latest research articles",
      text2: "IFSCC is an international non-profit organization, whose full name is The International Federation of Societies of Cosmetic Chemists (International Federation of Cosmetic Chemists)......",
      time: "2023-11-19 09:30",
    };

    const sizes = useScreenSize();
    const isMobile = sizes.isMobile.value;
    const scale = 1.64;
    const { browserWidth, browserHeight } = calBrowserSize(sizes, scale);

    return { supportPageContent, sizes, browserWidth, browserHeight, isMobile };
  },
};
</script>

<style scoped>
.media-news {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  background-color: #2c406c;
  position: relative;
}
.media-news::after {
  content: "";
  background-image: url("/public/images/media/media-bg.jpg");
  background-size: cover;
  background-position: center;
  opacity: 0.1;

  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.news-title {
  display: flex;
  flex-direction: row;
  width: 80%;
  z-index: 2;
  margin-top: 6%;
  margin-bottom: 3%;
}
.title-main {
  margin-right: auto;
  font-size: 96px;
  color: white;
}
.title-subtitle {
  font-size: 24px;
  font-weight: medium;
  color: #9ae790;
}

.news-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  height: 44%;
  gap: 20px;
  z-index: 2;
  margin-bottom: 4%;
}
.content-image {
  width: 43%;
  aspect-ratio: 493/311;
  height: auto;
  border-radius: 5px;
}
.content-visual {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 45%;
  height: 100%;
}
.content-text1 {
  font-size: 20px;
  font-weight: bold;
  color: white;
  text-align: left;
  padding-bottom: 6%;
  border-bottom: 2px solid white;
}
.content-text2 {
  font-size: 14px;
  color: #B5B6B6;
  text-align: left;
  margin: 6% 0;
}
.content-time {
  font-size: 20px;
  font-weight: medium;
  color: white;
  margin-left: auto;
}

.news-CTA {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 20px;
  font-weight: regular;
  color: rgba(255, 255, 255, 0.5);
}
.arrow-icon {
  transform: rotate(90deg);
}

@media (max-width: 768px) {
  .media-news {
    width: var(--browserWidth);
    height: var(--browserHeight);
  }
  .news-title {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 80%;
    gap: 0px;
    margin-top: 15%;
    margin-bottom: 15%;
  }
  .title-main {
    font-size: 34px;
    margin: 0;
  }
  .title-subtitle {
    font-size: 14px;
    color: #9AE790;
    font-weight: medium;
  }
  .news-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 65%;
    gap: 5%;
    margin-bottom: 0;
  }
  .content-image {
    width: 100%;
    aspect-ratio: 275/164;
    height: auto;
    border-radius: 5px;
  }
  .content-visual {
    width: 100%;
    justify-content: flex-start;
  }
  .content-text1 {
    font-size: 13px;
    border-bottom: 1px solid #C8C9C9;
      padding-bottom: 5%;
  }
  .content-text2 {
    font-size: 10px;
    margin: 5% 0;
  }
  .content-time {
    font-size: 10px;
    color: #C8C9C9;
  }
}
</style>