<template>
    <product-top-page :title="title" :subTitle="subTitle" :cards="cards" :text="text" color="white" class="background" :class="backgroundClass" :style="`width: ${screenSize.browserWidth} px;`"
        v-lazy:background-image="'/images/product/3d-virus-cells-attacking-dna-strand(1).png'"
    ></product-top-page>
    <product-bar :diagramBar="diagramBar" :diagramBarText="diagramBarText"></product-bar>

    <img v-lazy="'/images/product/MetaAMP12.png'" :width="image1.width" :height="image1.height" style="align-self: center;" :class="imageMargin"></img>
    <div v-if="isMobile" style="margin-bottom: 10%;">
        <div style="cursor: pointer; font-size: 10px; line-height: 19px; letter-spacing: 0; text-decoration: underline; color: #717173;" @click="zoomImage($event, 'image1')">click to zoom in</div>
        <div v-if="zoomInImage1">
            <div :style="`width: ${windowWidth}px; height: ${windowHeight * 2}px;`" class="page-mask" @click="closeDrawer($event, 'image1')"></div>
            <img v-lazy="'/images/product/MetaAMP12.png'" :width="windowWidth * 1.2" style="z-index: 101; left: -10%; top: 20%; position: fixed; background-color: white;"></img>
        </div>
    </div>

    <div style="width: 100%; height: 40px; background-color: #284070;"></div>

    <div style="display: flex; flex-direction: column; margin-top: 70px;">
        <h1 :class="moreAdvantagesTitleClass">More advantages</h1>
        <div style="width: 40px; align-self: center; border-bottom: 2px solid black; margin-top: 22px;"></div>

        <div :class="moreAdvantagesClass">
            <div>
                <h1 :class="moreAdvantagesTitleTitleClass">MetaAMP12 can inhibit a variety of pathogens in vagina</h1>
                <div v-if="isMobile" class="divider"></div>
                <p :class="moreAdvantagesTitleBodyClass">The antibacterial test results demonstrate that MetaAMP12 can inhibit the growth of <span style="font-style: italic;">Escherichia coli</span>, <span style="font-style: italic;">Staphylococcus aureus</span> and <span style="font-style: italic;">Candida albicans</span>, and has a high inhibitory effect on a variety of harmful and potentially harmful bacteria in vagina without resistance.</p>
            </div>
        </div>

        <img v-if="!isMobile" v-lazy="'/images/product/MetaAMP12-1.png'" :width="image4.width" :height="image4.height" style="align-self: center; margin-top: 96px; margin-bottom: 111px;"></img>
        
        <img v-if="isMobile" v-lazy="'/images/product/MetaAMP12Mobile1.png'" width="301" height="182" style="align-self: center;" :class="imageMargin"></img>
        <img v-if="isMobile" v-lazy="'/images/product/MetaAMP12Mobile2.png'" width="301" height="182" style="align-self: center;" :class="imageMargin"></img>
        <img v-if="isMobile" v-lazy="'/images/product/MetaAMP12Mobile3.png'" width="301" height="182" style="align-self: center;" :class="imageMargin"></img>

        <div :class="moreAdvantagesClass">
            <div>
                <h1 :class="moreAdvantagesTitleTitleClass">MetaAMP12 does not affect the beneficial bacteria in vagina</h1>
                <div v-if="isMobile" class="divider"></div>
                <p :class="moreAdvantagesTitleBodyClass"><span style="font-style: italic;">Lactobacillus acidophilus</span> can produce lactic acids to protect the beneficial bacteria in vagina. In antimicrobial tests against <span style="font-style: italic;">Lactobacillus acidophilus</span> MetaAMP12 doesn't inhibit its viability at high concentrations, and thus can preserve the balance of microbiome in the vagina and safeguard vaginal health.</p>
            </div>
        </div>

        <img v-lazy="'/images/product/MetaAMP12-2.png'" :width="image2.width" :height="image2.height" style="align-self: center;" :class="imageMargin"></img>
        
        <div :class="moreAdvantagesClass">
            <div>
                <h1 :class="moreAdvantagesTitleTitleClass">MetaAMP12 also can inhibit NO production to reduce inflammation</h1>
                <div v-if="isMobile" class="divider"></div>
                <p :class="moreAdvantagesTitleBodyClass">Excessive production of nitric oxide (NO) is associated with inflammatory response. Even at low concentration, MetaAMP12 can reduce inflammation by inhibiting the production of NO, and thus rapidly repair the inflammatory damage.</p>
            </div>
        </div>

        <img v-lazy="'/images/product/MetaAMP12-3.png'" :width="image3.width" :height="image3.height" style="align-self: center;" :class="imageMargin"></img>

        <ProductButton style="align-self: center; margin-top: 3%; margin-bottom: 3%;" color="#284070" :isMobile="isMobile"></ProductButton>
    </div>
</template>

<script>
import ProductTopPage from "@/components/Products/ProductTopPage.vue"
import ProductBar from "@/components/Products/ProductBar.vue"
import ProductButton from '@/components/Products/ProductButton.vue'
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";
import { ref } from 'vue';
export default {
    components: {
        ProductTopPage,
        ProductBar,
        ProductButton,
    },
    setup() {
        var screenSize = useScreenSize()
        var isMobile = screenSize.isMobile.value;
        var backgroundClass = isMobile ? 'background-extra-mobile' : 'background-extra';
        var image1 = {
            width: isMobile ? window.innerWidth * 0.7 : window.innerWidth * 0.9 < 1203 ? window.innerWidth * 0.9 : 1203,
            height: isMobile ? 'auto' : 'auto',
        }
        var windowWidth = window.innerWidth;
        var windowHeight = window.innerHeight;
        var zoomInImage1 = ref(false)
        var dividerHeight = isMobile ? "15px" : "40px";
        var moreAdvantagesTitleClass = isMobile ? "more-advantages-title-text=mobile" : "more-advantages-title-text";
        var moreAdvantagesClass = isMobile ? "more-advantages-title-mobile more-advantages-text" : "more-advantages-title more-advantages-text";
        var moreAdvantagesTitleTitleClass = isMobile ? "more-advantages-title-title-text-mobile" : "more-advantages-title-title-text";
        var moreAdvantagesTitleBodyClass = isMobile ? "more-advantages-title-body-text-mobile" : "more-advantages-title-body-text";
        var image2 = {
            width: isMobile ? 283 : window.innerWidth * 0.9 < 644 ? window.innerWidth * 0.9 : 644,
            height: isMobile ? 206 : 'auto',
        }
        var imageMargin = isMobile ? "image-margin-mobile" : "image-margin";
        var image3 = {
            width: isMobile ? 203 : window.innerWidth * 0.9 < 730 ? window.innerWidth * 0.9 : 730,
            height: isMobile ? 239 : 'auto',
        }
        var productButtonMarginBottom = isMobile ? "10%" : "5%";


        var title = "Vaginal Care Antimicrobial Peptide MetaAMP12";
        var subTitle = "Vaginal health promoter";
        var cards = [
            {
                title: "Efficacy",
                text: "Effectively inhibits various pathogenic bacteria and reduces inflammation",
            },
            {
                title: "Advantage",
                text: "High safety, high selectivity against pathogenic bacteria ",
            },
        ]
        var text = "Vaginal Care Antimicrobial Peptide MetaAMP12 has both antibacterial and anti-inflammatory effects. Additionally, MetaAMP12 has no inhibitory effect on probiotics and can maintain the balance of microflora in vagina."
        var diagramBar = {
            left: "Mechanism diagram",
            right: "MetaAMP12 exhibits antibacterial and anti-inflammatory properties",
            color: "#284070",
        }
        var diagramBarText = "The vaginal microbiota and our body maintain a harmonious symbiotic relationship, but the proliferation of harmful bacteria can disrupt this balance, leading to vaginitis and vaginal itching. MetaAMP12 is an active peptide with antimicrobial properties that selectively targets harmful bacteria, and can reduce inflammation by inhibiting NO."
        var image4 = {
            width: window.innerWidth * 0.9 < 1049 ? window.innerWidth * 0.9 : 1049,
            height: 'auto',
        }

        return {
            screenSize,
            isMobile,
            backgroundClass,
            image1,
            windowHeight,
            windowWidth,
            zoomInImage1,
            dividerHeight,
            moreAdvantagesTitleClass,
            moreAdvantagesClass,
            moreAdvantagesTitleTitleClass,
            moreAdvantagesTitleBodyClass,
            image2,
            imageMargin,
            image3,
            productButtonMarginBottom,
            image4,

            title,
            subTitle,
            cards,
            text,
            diagramBar,
            diagramBarText,
        }
    },
    methods: {
        zoomImage(event, image) {
            if (image == 'image1') {
                this.zoomInImage1 = true;
            }
        },
        closeDrawer(event, image) {
            if (image == 'image1') {
                this.zoomInImage1 = false;
            }
        }
    }
}
</script>

<style scoped>
.page-mask {
    background-color: black;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.background {
    /* background-image: url("../../../public/images/product/3d-virus-cells-attacking-dna-strand(1).jpg"); */
    background-repeat: no-repeat;
    background-size: 100%;
    /* background-size: 400%; */
    background-position-x: right;
    width: 100%;
    /* background-position-y: top; */
}
.background-extra {
    padding-bottom: 75px; 
    background-size: cover; 
    background-position-y: center;
}
.background-extra-mobile {
    padding-bottom: 5%; 
    background-size: cover; 
    background-position-y: top;
}

.more-advantages-title-text {
    font-size: 52px; 
    line-height: 50px; 
    letter-spacing: -0.8px; 
    color: black;
}

.more-advantages-title-text-mobile {
    font-size: 20px; 
    line-height: 50px; 
    letter-spacing: 0; 
    color: black;
}

.more-advantages-title-mobile {
    padding-left: 6%;
    padding-right: 6%;
    width: 90%; 
    height: 305px; 
    align-self: center; 
    text-align: left; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    margin-top: 4%;
    margin-bottom: 4%;
}

.more-advantages-title-title-text {
    font-size: 30px; 
    line-height: 50px; 
    letter-spacing: -0.8px; 
    color: black;
    margin-bottom: 40px;
}

.more-advantages-title-title-text-mobile {
    font-size: 14px; 
    line-height: 20px; 
    font-weight: bold;
    letter-spacing: 0px; 
    color: black;
}

.more-advantages-title-body-text {
    font-size: 20px; 
    line-height: 32px; 
    letter-spacing: -0.8px; 
    color: #2E2E27;
}

.more-advantages-title-body-text-mobile {
    font-size: 12px; 
    line-height: 20px; 
    letter-spacing: 0px; 
    font-weight: lighter;
    color: #2E2E27;
}

.more-advantages-title {
    padding: 0 50px;
    width: 90%; 
    height: 344px; 
    align-self: center; 
    text-align: left; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.more-advantages-body {
    width: 1140px;
    height: 316px; 
    align-self: center; 
    text-align: left; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
}
.more-advantages-text {
    background-color: #F5F5F5;
    border-radius: 38px;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: black;
    margin-top: 5%;
    margin-bottom: 6%;
}
.image-margin {
    margin-top: 96px; 
    margin-bottom: 111px;
}
.image-margin-mobile {
    margin-top: 10%; 
    margin-bottom: 10%;
}
</style>