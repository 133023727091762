<template>
  <router-link
    :to="{ name: 'Technology' }"
    :class="cardClass"
    style="
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      cursor: pointer;
    "
  >
    <img :src="data.url" :alt="'technical platform' + data.text" :class="imageClass"/>
    <p :class="textClass" style="color: white; text-decoration: none">
      {{ data.text }}
    </p>
  </router-link>
</template>

<script>
export default {
  name: "PlatformCard",
  props: ["data", "isMobile"],
  setup(props) {
    const isMobile = props.isMobile;
    const cardClass = isMobile ? "mobile-card" : "card";
    const imageClass = isMobile ? "mobile-image" : "card-image";
    const textClass = isMobile ? "mobile-text" : "card-text";
    return { cardClass, imageClass, textClass };
  },
};
</script>

<style scoped>
.card {
  width: 275px;
  height: 298px;
  border-radius: 20px;
  background-color: rgba(217, 217, 217, 0.2);
}
.card-image {
  width: 153px;
  height: 153px;
  object-fit: cover;
  margin-bottom: -10px;
}
.card-text {
  font-size: 20px;
  font-weight: medium;
}

/* mobile view */
.mobile-card {
  width: 138px;
  height: 155px;
  border-radius: 10px;
  background-color: rgba(217, 217, 217, 0.2);
}
.mobile-image {
  width: 100px;
  height: 100px;
  margin-bottom: -10px;
}
.mobile-text {
  font-size: 12px;
  font-weight: regular;
}
</style>