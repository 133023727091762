<template>
  <div class="gg-arrow-up-o" :style="computedStyle">
    <div class="arrow-stem"></div>
    <div class="arrow-head"></div>
  </div>
</template>

<script>
export default {
  name: 'ArrowIcon',
  props: {
    scale: {
      type: Number,
      default: 1
    }
  },
  computed: {
    computedStyle() {
      return {
        '--ggs': this.scale
      }
    }
  }
}
</script>

<style scoped>
.gg-arrow-up-o {
  box-sizing: border-box;
  display: block;
  width: 52px;
  height: 52px;
  border: 3px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 26px;
  color: #6cd2a0;
  position: relative;
  flex-shrink: 0;
}
.arrow-stem {
  box-sizing: border-box;
  position: absolute;
  height: 30px;
  top: 10px;
  left: 21px;
  background: linear-gradient(
    currentColor 60%,
    transparent 60%,
    transparent 80%,
    currentColor 80%
  );
  width: 3px;
}
.arrow-head {
  box-sizing: border-box;
  position: absolute;
  width: 15px;
  height: 15px;
  border-top: 3px solid;
  border-left: 3px solid;
  transform: rotate(45deg);
  left: 15px;
  top: 10px;
}
</style>
