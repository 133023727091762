<template>
    <div style="width: 100%;">
        <div class="navbar-link-row">
            <div class="navbar-link-col">
                <router-link :to="{ name: 'About', hash: '#aboutus' }" style="text-decoration: none">
                    <div class="navbar-link" style="font-weight: bold" @click="navOnClick($event, 'navbar-about')"
                        id="navbar-about">
                        <span>About us</span>
                    </div>
                </router-link>

                <router-link :to="{ name: 'About', hash: '#partnerships' }"
                    style="text-decoration: none; grid-column: 2 / 4">
                    <div class="navbar-link" @click="navOnClick($event, 'navbar-about-partnerships')"
                        id="navbar-about-partnerships">
                        <span>Cooperative Partnerships</span>
                    </div>
                </router-link>

                <router-link :to="{ name: 'About', hash: '#services' }" style="text-decoration: none">
                    <div class="navbar-link" @click="navOnClick($event, 'navbar-about-services')"
                        id="navbar-about-services">
                        <span>R&D services</span>
                    </div>
                </router-link>

                <router-link :to="{ name: 'About', hash: '#awards' }" style="text-decoration: none">
                    <div class="navbar-link" @click="navOnClick($event, 'navbar-about-awards')"
                        id="navbar-about-awards">
                        <span>Awards and Honours</span>
                    </div>
                </router-link>

                <router-link :to="{ name: 'About', hash: '#product' }" style="text-decoration: none">
                    <div class="navbar-link" @click="navOnClick($event, 'navbar-about-product')"
                        id="navbar-about-product">
                        <span>Products</span>
                    </div>
                </router-link>
            </div>
            <div class="navbar-link-col">
                <router-link :to="{ name: 'ProductsIntroduction' }" style="text-decoration: none">
                    <div class="navbar-link" style="font-weight: bold" @click="navOnClick($event, 'navbar-products')"
                        id="navbar-products">
                        <span>Products</span>
                    </div>
                </router-link>

                <router-link :to="{ name: 'ProductsMetaAMP5' }" style="text-decoration: none">
                    <div class="navbar-link" @click="navOnClick($event, 'navbar-amp5')" id="navbar-amp5">
                        <span>MetaAMP5</span>
                    </div>
                </router-link>

                <router-link :to="{ name: 'ProductsMetaAMP33' }" style="text-decoration: none">
                    <div class="navbar-link" @click="navOnClick($event, 'navbar-amp33')" id="navbar-amp33">
                        <span>MetaAMP33</span>
                    </div>
                </router-link>

                <router-link :to="{ name: 'ProductsMetaCono' }" style="text-decoration: none">
                    <div class="navbar-link" @click="navOnClick($event, 'navbar-metacono')" id="navbar-metacono">
                        <span>MetaCono</span>
                    </div>
                </router-link>

                <router-link :to="{ name: 'ProductsMetaPepOcean1' }" style="text-decoration: none">
                    <div class="navbar-link" @click="navOnClick($event, 'navbar-ocean1')" id="navbar-ocean1">
                        <span>MetaPep-Ocean-1</span>
                    </div>
                </router-link>
                <router-link :to="{ name: 'ProductsMetaAMP12' }" style="text-decoration: none">
                    <div class="navbar-link" @click="navOnClick($event, 'navbar-amp12')" id="navbar-amp12">
                        <span>MetaAMP12</span>
                    </div>
                </router-link>
            </div>
            <div class="navbar-link-col">
                <router-link :to="{ name: 'Technology' }" style="text-decoration: none">
                    <div class="navbar-link" style="font-weight: bold" @click="navOnClick($event, 'navbar-technology')"
                        id="navbar-technology">
                        <span>Technology</span>
                    </div>
                </router-link>

                <router-link :to="{ name: 'Technology', hash: '#metanlp' }" style="text-decoration: none">
                    <div class="navbar-link" @click="navOnClick($event, 'navbar-metanlp')" id="navbar-metanlp">
                        <span>MetaNLP®</span>
                    </div>
                </router-link>
                <router-link :to="{ name: 'Technology', hash: '#metakg' }" style="text-decoration: none">
                    <div class="navbar-link" @click="navOnClick($event, 'navbar-metakg')" id="navbar-metakg">
                        <span>MetaKG®</span>
                    </div>
                </router-link>
                <router-link :to="{ name: 'Technology', hash: '#metapep' }" style="text-decoration: none">
                    <div class="navbar-link" @click="navOnClick($event, 'navbar-metapep')" id="navbar-metapep">
                        <span>MetaPep®</span>
                    </div>
                </router-link>
                <router-link :to="{ name: 'Technology', hash: '#metaomics' }" style="text-decoration: none">
                    <div class="navbar-link" @click="navOnClick($event, 'navbar-metaomics')" id="navbar-metaomics">
                        <span>MetaOmics®</span>
                    </div>
                </router-link>
            </div>

            <div class="navbar-link-col">
                <router-link :to="{ name: 'Media' }" style="text-decoration: none">
                    <div class="navbar-link" style="font-weight: bold" @click="navOnClick($event, 'navbar-media')"
                        id="navbar-media">
                        <span>Media</span>
                    </div>
                </router-link>
            </div>
            <div class="navbar-link-col">
                <router-link :to="{ name: 'About', hash: '#support' }" style="text-decoration: none">
                    <div class="navbar-link" style="font-weight: bold" @click="navOnClick($event, 'navbar-support')"
                        id="navbar-support">
                        <span>Suppport</span>
                    </div>
                </router-link>

                <router-link :to="{ name: 'About', hash: '#support' }" style="text-decoration: none">
                    <div class="navbar-link" @click="navOnClick($event, 'navbar-support')" id="navbar-support">
                        <span>Contact us</span>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        navOnClick(event, index) {
            var elements = document.getElementsByClassName("navbar-link-active");
            for (let item of elements) {
                item.classList.remove(["navbar-link-active"]);
            }
            console.log(index);
            var element = document.getElementById(index);
            if (element) element.classList.add(["navbar-link-active"]);
        }
    }
}
</script>

<style scoped>
.divider {
    width: 100%;
    height: 2px;
    background-color: black;
    margin-top: 5%;
    margin-bottom: 3%;
}

.navbar-link-row {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 1%;
    row-gap: 20px;
    padding: 10px 0;
}

.navbar-link-col {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.navbar-link {
    display: flex;
    text-decoration: none;
    white-space: nowrap;
    /* margin-right: 20px; */
    color: black;
    /* font-size: 15.4px; */
    font-weight: normal;
    font-size: 11px;
    letter-spacing: -0.8px;
}
</style>