<template>
    <product-top-page :title="title" :subTitle="subTitle" :cards="cards" :text="text" class="background" :class="backgroundClass" :style="`width: ${screenSize.browserWidth} px;`"
        v-lazy:background-image="'/images/product/3d-representation-dna.jpg'"
        ></product-top-page>
    <product-bar :diagramBar="diagramBar" :diagramBarText="diagramBarText"></product-bar>

    <img v-lazy="'/images/product/MetaAMP5-02.png'" :width="image1.width" :height="image1.height" style="align-self: center;"></img>
    <div v-if="isMobile" style="margin-bottom: 10%;">
        <div style="cursor: pointer; font-size: 10px; line-height: 19px; letter-spacing: 0; text-decoration: underline; color: #717173;" @click="zoomImage($event, 'image1')">click to zoom in</div>
        <div v-if="zoomInImage1">
            <div :style="`width: ${windowWidth}px; height: ${windowHeight * 2}px;`" class="page-mask" @click="closeDrawer($event, 'image1')"></div>
            <img v-lazy="'/images/product/MetaAMP5-02.png'" :width="windowWidth * 1.2" style="z-index: 101; left: -10%; top: 20%; position: fixed; background-color: white;"></img>
        </div>
    </div>

    <div :style="`width: 100%; height: ${dividerHeight}; background-color: #56B7B1;`"></div>

    <div style="display: flex; flex-direction: column; margin-top: 70px;">
        <h1 :class="moreAdvantagesTitleClass">More advantages</h1>
        <div style="width: 40px; align-self: center; border-bottom: 2px solid black; margin-top: 22px;"></div>

        <div :class="moreAdvantagesClass">
            <div>
                <h1 :class="moreAdvantagesTitleTitleClass">MetaAMP5 has a dual regulatory mechanism:Inhibiting pro-inflammatory factors while promoting anti-inflammatory factors</h1>
                <div v-if="isMobile" class="divider"></div>
                <p :class="moreAdvantagesTitleBodyClass">Pro-inflammatory factors (IL-6 and IL-1β) can trigger the inflammatory response. The results showed that MetaAMP5 with low concentrations could significantly inhibit the levels of IL-6 and IL-1β, indicating that MetaAMP5 can inhibit the production of inflammatory cytokines to achieve anti-inflammatory effects.</p>
            </div>
        </div>

        <img v-if="!isMobile" v-lazy="'/images/product/MetaAMP5-03.png'" :width="image2.width" :height="image2.height" style="align-self: center;" :class="imageMargin"></img>

        <img v-if="isMobile" v-lazy="'/images/product/MetaAMP5Mobile1.png'" :width="image2.width" :height="image2.height" style="align-self: center;" :class="imageMargin"></img>
        <img v-if="isMobile" v-lazy="'/images/product/MetaAMP5Mobile2.png'" :width="image2.width" :height="image2.height" style="align-self: center;" :class="imageMargin"></img>
        
        <div :class="moreAdvantagesClass">
            <div>
                <h1 :class="moreAdvantagesTitleTitleClass">MetaAMP5 can inhibit the level of inflammatory mediator, NO</h1>
                <div v-if="isMobile" class="divider"></div>
                <p :class="moreAdvantagesTitleBodyClass">Lipopolysaccharide (LPS) is the main component of the outer membrane of gram-negative bacteria, which can stimulate and induce the inflammatory response. As a highly effective anti-inflammatory peptide, MetaAMP5 can effectively inhibit the production of NO at low concentrations, and has a high anti-inflammatory effect.</p>
            </div>
        </div>

        <img v-lazy="'/images/product/MetaAMP5-04.png'" :width="image3.width" :height="image3.height" style="align-self: center;" :class="imageMargin"></img>
        
        <ProductButton style="align-self: center; margin-top: 3%; margin-bottom: 3%;" color="#56B7B1" :isMobile="isMobile"></ProductButton>
    </div>
</template>

<script>
import ProductTopPage from "@/components/Products/ProductTopPage.vue"
import ProductBar from "@/components/Products/ProductBar.vue"
import ProductButton from '@/components/Products/ProductButton.vue'
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";
import { ref } from 'vue';
export default {
    components: {
        ProductTopPage,
        ProductBar,
        ProductButton,
    },
    setup() {
        var screenSize = useScreenSize();
        var isMobile = ref(window.innerWidth <= 768);
        var backgroundClass = isMobile.value ? 'background-extra-mobile' : 'background-extra';
        var image1 = {
            width: isMobile.value ? window.innerWidth * 0.9 : window.innerWidth * 0.9 < 1203 ? window.innerWidth * 0.9 : 1203,
            height: isMobile.value ? 'auto' : 'auto',
        }
        var windowWidth = window.innerWidth;
        var windowHeight = window.innerHeight;
        var zoomInImage1 = ref(false)
        var dividerHeight = isMobile.value ? "15px" : "40px";
        var moreAdvantagesTitleClass = isMobile.value ? "more-advantages-title-text=mobile" : "more-advantages-title-text";
        var moreAdvantagesClass = isMobile.value ? "more-advantages-title-mobile more-advantages-text" : "more-advantages-title more-advantages-text";
        var moreAdvantagesTitleTitleClass = isMobile.value ? "more-advantages-title-title-text-mobile" : "more-advantages-title-title-text";
        var moreAdvantagesTitleBodyClass = isMobile.value ? "more-advantages-title-body-text-mobile" : "more-advantages-title-body-text";
        var image2 = {
            width: isMobile.value ? window.innerWidth * 0.9 : window.innerWidth * 0.9 < 1010 ? window.innerWidth * 0.9 : 1010,
            height: isMobile.value ? 'auto' : 'auto',
        }
        var imageMargin = isMobile.value ? "image-margin-mobile" : "image-margin";
        var image3 = {
            width: isMobile.value ? window.innerWidth * 0.9 : window.innerWidth * 0.9 < 782 ? window.innerWidth * 0.9 : 782,
            height: isMobile.value ? 'auto' : 'auto',
        }
        var productButtonMarginBottom = isMobile.value ? "10%" : "5%";

        var title = "Anti-inflammatory peptide MetaAMP5";
        var subTitle = "Skin protection from infection and inflammation";
        var cards = [
            {
                title: "Efficacy",
                text: "Repair fragile skin, relieve skin inflammation and prevent inflammatory aging",
            },
            {
                title: "Advantage",
                text: "Safe and effective anti-inflammatory peptide for oily-acne and acne-sensitive skin",
            },
            {
                title: "Application",
                text: "High temperature resistance and high stability after exposure; High solubility",
            },
        ]
        var text = "MetaAMP5 is a highly effective bioactive peptide designed by the MetaPep platform. It precisely targets acne and skin bacterial infection, inhibiting and reducing skin inflammation. It is especially effective for relieving the inflammation associated with acne vulgaris."
        var diagramBar = {
            left: "Mechanism diagram",
            right: "MetaAMP5 can inhibit inflammation in multiple ways and promote tissue repair",
            color: "#56B7B1",
        }
        var diagramBarText = "MetaAMP5 can effectively inhibit the inflammatory response in multiple ways. It has a significant inhibitory effect on neutrophils, NO and pro-inflammatory factors IL-6 and IL-1β. At the same time, MetaAMP5 can promote the expression of anti-inflammatory factors IL-10, IL-4 and IL-13 to reduce inflammatory response."
        
        return {
            screenSize,
            isMobile,
            backgroundClass,
            image1,
            windowHeight,
            windowWidth,
            zoomInImage1,
            dividerHeight,
            moreAdvantagesTitleClass,
            moreAdvantagesClass,
            moreAdvantagesTitleTitleClass,
            moreAdvantagesTitleBodyClass,
            image2,
            imageMargin,
            image3,
            productButtonMarginBottom,
            
            title,
            subTitle,
            cards,
            text,
            diagramBar,
            diagramBarText,
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    },
    methods: {
        handleWindowSizeChange() {
            // console.log(window.innerWidth)
            this.browserWidth = window.innerWidth;
            if (window.innerWidth <= 768) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        },
        zoomImage(event, image) {
            if (image == 'image1') {
                this.zoomInImage1 = true;
            }
        },
        closeDrawer(event, image) {
            if (image == 'image1') {
                this.zoomInImage1 = false;
            }
        }
    }
}
</script>

<style scoped>
.page-mask {
    background-color: black;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.background {
    /* background-image: url("../../../public/images/product/3d-representation-dna.jpg"); */
    background-repeat: no-repeat;
    /* background-size: 400%; */
    background-position-x: right;
    width: 100%;
    /* background-position-y: top; */
}
.background-extra {
    padding-bottom: 75px; 
    background-size: cover; 
    background-position-y: center;
}
.background-extra-mobile {
    padding-bottom: 5%; 
    background-size: cover; 
    background-position-y: top;
}

.more-advantages-title-text {
    font-size: 52px; 
    line-height: 50px; 
    letter-spacing: -0.8px; 
    color: black;
}

.more-advantages-title-text-mobile {
    font-size: 20px; 
    line-height: 50px; 
    letter-spacing: 0; 
    color: black;
}

.more-advantages-title {
    padding: 0 50px;
    width: 90%; 
    height: 344px; 
    align-self: center; 
    text-align: left; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.more-advantages-title-mobile {
    padding-left: 6%;
    padding-right: 6%;
    width: 90%; 
    height: 305px; 
    align-self: center; 
    text-align: left; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    margin-top: 6%;
}

.more-advantages-title-title-text {
    font-size: 30px; 
    line-height: 50px; 
    letter-spacing: -0.8px; 
    color: black;
    margin-bottom: 40px;
}

.more-advantages-title-title-text-mobile {
    font-size: 14px; 
    line-height: 20px; 
    font-weight: bold;
    letter-spacing: 0px; 
    color: black;
}

.more-advantages-title-body-text {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.8px;
    color: #2E2E27;
}

.more-advantages-title-body-text-mobile {
    font-size: 12px; 
    line-height: 20px; 
    letter-spacing: 0px; 
    font-weight: lighter;
    color: #2E2E27;
}

.more-advantages-body {
    width: 1140px;
    height: 316px; 
    align-self: center; 
    text-align: left; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
}
.more-advantages-text {
    background-color: #F5F5F5;
    border-radius: 38px;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: black;
    margin-top: 5%;
    margin-bottom: 6%;
}
.image-margin {
    margin-top: 96px; 
    margin-bottom: 111px;
}
.image-margin-mobile {
    margin-top: 10%; 
    margin-bottom: 10%;
}
</style>