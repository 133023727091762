<template>
  <CorporateNews />
  <NewsPage />
</template>

<script>
import CorporateNews from "@/views/Media/CorporateNews";
import NewsPage from "@/views/Media/NewsPage";

export default {
  name: "Media",
  components: { CorporateNews, NewsPage, },
};
</script>

<style>
</style>