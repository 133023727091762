<template>
  <div
    class="about-services"
    :class="{ 'mobile-view': sizes.isMobile.value }"
    :style="{
      '--browserWidth': browserWidth + 'px',
      '--browserHeight': browserHeight + 'px',
    }"
  >
    <div class="services-title">
      <p class="title-text">R&D Services</p>
      <div class="title-underline"></div>
    </div>
    <div class="services-content">
      <img
        v-lazy="images[currentImage]"
        :alt="'services-image-' + selectedService"
        class="services-images"
      />
      <ul class="services-list">
        <li
          class="service-item-container"
          v-for="(service, index) in services"
          :key="service"
          :class="{ 'selected-service': index === selectedService }"
          @click="selectService(index)"
        >
          <span class="service-item">
            {{ service }} <el-icon><ArrowRightBold /></el-icon>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";

export default {
  name: "Services",
  setup() {
    const selectedService = ref(0);
    const currentImage = ref(0);

    const images = ref([
      "/images/about/services-image-01.png",
      "/images/about/services-image-02.png",
      "/images/about/services-image-03.png",
      "/images/about/services-image-04.png",
      "/images/about/services-image-05.png",
    ]);
    const services = ref([
      "Biological Mechanism Discovery",
      "Demographic Differences",
      "Formulation Design",
      "Ingredient Discovery and Design",
      "Supplier Recommendation",
    ]);

    const selectService = (index) => {
      selectedService.value = index;
      currentImage.value = index;
    };

    const sizes = useScreenSize();
    const scale = 2.12;
    const { browserWidth, browserHeight } = calBrowserSize(sizes, scale);

    return {
      images,
      services,
      selectedService,
      currentImage,
      selectService,
      sizes,
      browserWidth,
      browserHeight,
    };
  },
};
</script>

<style scoped>
.about-services {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* height: 100vh; */
  height: 810px;
  padding: 80px 0;
  background-color: white;
}
.services-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 5%; */
  margin-bottom: 5%;
}
.title-text {
  font-size: 52px;
  font-weight: 500;
}
.title-underline {
  width: 40px;
  height: 2px;
  background-color: black;
}
.services-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 50%;
  width: 80%;
}
.services-images {
  /* width: 611px;
  height: 441px; */
  height: 100%;
  aspect-ratio: 611 / 441;
  border-radius: 30px;
  object-fit: cover;
  margin-right: 20px;
}
.services-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  height: 100%;
  justify-content: space-between;
}
.service-item-container {
  width: 420px;
  color: #b5b6b6;
  cursor: pointer;
  padding: 0;
  position: relative;
}
.service-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  font-size: 24px;
  font-weight: normal;
}
.service-item-container::after {
  content: "";
  display: block;
  height: 1px;
  background-color: #b5b6b6;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.service-item-container.selected-service {
  color: black;
  border-bottom: 2px solid black;
}
.service-item-container.selected-service::after {
  background-color: black;
  height: 2px;
}

/* mobile view */
@media (max-width: 768px) {
  .about-services {
    width: var(--browserWidth);
    /* height: var(--browserHeight); */
    height: auto !important;
  }
  .services-title {
    margin-top: 15%;
    margin-bottom: 15%;
  }
  .title-text {
    font-size: 20px;
  }
  .title-underline {
    width: 20px;
  }
  .services-content {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 78%;
  }
  .services-images {
    width: 292px;
    height: 262px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 0;
    margin-bottom: 15%;
  }
  .service-item-container {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 25px;
  }
  .service-item {
    font-size: 16px;
  }
}
</style>