<template>
  <div
    class="about-partnerships"
    :class="{ 'mobile-view': sizes.isMobile.value }"
    :style="{
      '--browserWidth': browserWidth + 'px',
      '--browserHeight': browserHeight + 'px',
    }"
  >
    <div class="partnerships-content">
      <div class="content-title">
        <p class="title-text">Cooperative Partnerships</p>
        <div class="title-underline"></div>
      </div>
      <p class="content-text">
        MetaNovas closely collaborates with global academic and industrial
        leaders. We have established strong partnerships with numerous
        international brands and actively supported their R&D.
      </p>
    </div>
    <div class="partnerships-scroller">
      <Vue3Marquee class="scroller-container">
        <PartnershipsCard
          v-for="cardItem in cardData"
          :key="cardItem.id"
          :data="cardItem"
          class="card-item"
          :isMobile="sizes.isMobile.value"
        />
      </Vue3Marquee>
    </div>
  </div>
</template>

<script>
import PartnershipsCard from "@/components/About/PartnershipsCard.vue";
import { Vue3Marquee } from "vue3-marquee";
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";

export default {
  name: "Partnerships",
  components: { PartnershipsCard, Vue3Marquee },

  setup() {
    const cardData = [
      { id: 1, url: "/images/about/partnership-01.png" },
      { id: 2, url: "/images/about/partnership-02.png" },
      { id: 3, url: "/images/about/partnership-03.png" },
      { id: 4, url: "/images/about/partnership-04.png" },
      { id: 5, url: "/images/about/partnership-05.png" },
      { id: 6, url: "/images/about/partnership-06.png" },
      { id: 7, url: "/images/about/partnership-07.png" },
      { id: 8, url: "/images/about/partnership-08.png" },
      { id: 9, url: "/images/about/partnership-09.png" },
      { id: 10, url: "/images/about/partnership-10.png" },
      { id: 11, url: "/images/about/partnership-11.png" },
      { id: 12, url: "/images/about/partnership-12.png" },
    ];
    const sizes = useScreenSize();
    const scale = 1.12;
    const { browserWidth, browserHeight } = calBrowserSize(sizes, scale);

    return { cardData, sizes, browserWidth, browserHeight };
  },
};
</script>

<style scoped>
.about-partnerships {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  background-color: white;
}

.partnerships-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}
.content-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 6%
}
.title-text {
  font-size: 52px;
  font-weight: 500;
}
.title-underline {
  width: 40px;
  height: 2px;
  background-color: black;
}
.content-text {
  display: flex;
  width: 80%;
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 6%;
}

.partnerships-scroller {
  display: flex;
  flex-direction: row;
}
.scroller-container {
  height: 180px !important;
  overflow: visible;
}
.card-item {
  margin: 0 10px;
}

/* mobile view */
@media (max-width: 768px) {
  .about-partnerships {
    justify-content: none;
    width: var(--browserWidth);
    height: auto !important;
    /* height: var(--browserHeight); */
  }
  .partnerships-content {
    margin-top: 10%;
  }
  .content-title {
    margin-bottom: 15%;
  }
  .title-text {
    font-size: 20px;
  }
  .title-underline {
    width: 20px;
  }
  .content-text {
    font-size: 12px;
    width: 80%;
    margin-bottom: 10%;
  }
}
</style>

