<template>
    <div :style="`height: ${isMobile? 'auto' : 'auto'};`" class="feature-container">
        <div class="banner-container" style="padding: 3% 0;">
            <div style="display: flex; flex-direction: column; margin-bottom: 3%; align-items: center;">
                <div :class="`${isMobile ? 'title-text-mobile' : 'title-text'}`" style="margin-bottom: 3%;">Featured on</div>
                <div class="divider" :style="`width: ${isMobile ? '20px' : '40px'}`"></div>
            </div>
            <div v-if="!isMobile" class="banner-bg">
                <a target="_blank"
                    href="https://www.digitaljournal.com/pr/news/newsfile/metanovas-wins-l-oreal-big-bang-future-new-product-research-x-artificial-intelligence-cross-domain-championship"><img
                        class="icons" v-lazy="'/images/about/digital_journal.png'"></img></a>
                <a target="_blank"
                    href="https://markets.businessinsider.com/news/stocks/metanovas-wins-loreal-big-bang-future-new-product-research-x-artificial-intelligence-crossdomain-championship-1032852227"><img
                        class="icons" v-lazy="'/images/about/business_insider_transparent.png'"></img></a>
                <a target="_blank"
                    href="https://www.bloomberg.com/press-releases/2023-11-27/metanovas-wins-l-oreal-big-bang-future-new-product-research-x-artificial-intelligence-cross-domain-championship"><img
                        class="icons" v-lazy="'/images/about/bloomberg_transparent.png'"></img></a>
                <a target="_blank"
                    href="https://www.benzinga.com/pressreleases/23/11/35964182/metanovas-wins-loreal-big-bang-future-new-product-research-x-artificial-intelligence-cross-domain-c"><img
                        class="icons" v-lazy="'/images/about/benzinga_transparent.png'"></img></a>
                <a target="_blank" href="https://finance.yahoo.com/news/metanovas-wins-loreal-big-bang-171800060.html"><img
                        class="icons" v-lazy="'/images/about/yahoo_banner_transparent.png'"></img></a>
                <a target="_blank"
                    href="https://www.marketwatch.com/press-release/metanovas-wins-l-oreal-big-bang-future-new-product-research-x-artificial-intelligence-cross-domain-championship-ff281402?mod=search_headline"><img
                        class="icons" v-lazy="'/images/about/market_watch_transparent.png'"></img></a>
            </div>
            <div v-else class="banner-bg-mobile">
                <div class="banner-bg-mobile-row">
                    <a target="_blank"
                        href="https://www.digitaljournal.com/pr/news/newsfile/metanovas-wins-l-oreal-big-bang-future-new-product-research-x-artificial-intelligence-cross-domain-championship">
                        <img class="icons" v-lazy="'/images/about/digital_journal.png'" height="89" width="89"></img></a>
                    <a target="_blank"
                        href="https://markets.businessinsider.com/news/stocks/metanovas-wins-loreal-big-bang-future-new-product-research-x-artificial-intelligence-crossdomain-championship-1032852227"><img
                            class="icons" v-lazy="'/images/about/business_insider_transparent.png'" height="89" width="89"></img></a>
                    <a target="_blank"
                        href="https://www.bloomberg.com/press-releases/2023-11-27/metanovas-wins-l-oreal-big-bang-future-new-product-research-x-artificial-intelligence-cross-domain-championship"><img
                            class="icons" v-lazy="'/images/about/bloomberg_transparent.png'" height="89" width="113"></img></a>
                </div>
                <div class="banner-bg-mobile-row">
                    <a target="_blank"
                        href="https://www.benzinga.com/pressreleases/23/11/35964182/metanovas-wins-loreal-big-bang-future-new-product-research-x-artificial-intelligence-cross-domain-c"><img
                            class="icons" v-lazy="'/images/about/benzinga_transparent.png'" height="89" width="106"></img></a>
                    <a target="_blank" href="https://finance.yahoo.com/news/metanovas-wins-loreal-big-bang-171800060.html"><img
                            class="icons" v-lazy="'/images/about/yahoo_banner_transparent.png'" height="89" width="100"></img></a>
                    <a target="_blank"
                        href="https://www.marketwatch.com/press-release/metanovas-wins-l-oreal-big-bang-future-new-product-research-x-artificial-intelligence-cross-domain-championship-ff281402?mod=search_headline"><img
                            class="icons" v-lazy="'/images/about/market_watch_transparent.png'" height="89" width="116"></img></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";
import { ref } from 'vue';

export default {
    setup() {
        var screenSize = useScreenSize();
        var isMobile = ref(window.innerWidth <= 768);

        return {
            screenSize,
            isMobile,
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    },
    methods: {
        handleWindowSizeChange() {
            // console.log(window.innerWidth)
            this.browserWidth = window.innerWidth;
            if (window.innerWidth <= 768) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        },
    }
}
</script>

<style scoped>
.divider {
    height: 2px;
    background-color: black;
    margin-top: -2%;
    /* margin-bottom: 3%; */
}

.title-text {
    font-size: 52px;
    line-height: 46px;
    letter-spacing: 0;
    font-weight: 500;
}
.title-text-mobile {
    font-size: 24px;
    line-height: 46px;
    letter-spacing: 0;
    font-weight: 500;
}

.feature-container {
    width: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.banner-container {
    background-color: #f5f5f5;
    width: 85%;
    padding-top: 3%;
    height: 100%;
}

.banner-bg-mobile {
    display: flex;
    flex-direction: column;
}

.banner-bg {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin-bottom: 5%;
}

.banner-bg .icons {
    background-color: #f1f6f5;
    max-width: 100%;
    max-height: 100%;
    min-height: 180px;
    transform: scale(0.7);
    margin-left: -1.5%;
    margin-right: -1.5%;
    margin-top: -2.5%;
    margin-bottom: -2.5%;
}

.banner-bg .icons:hover {
    background-color: #f1ffff;
}

.banner-bg-mobile-row {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 5%;
}

.banner-bg-mobile-row .icons {
    background-color: #f1f6f5;
    max-width: 100%;
    max-height: 100%;
    margin-top: -2.5%;
    margin-bottom: -2.5%;
}

.banner-bg-mobile-row .icons:hover {
    background-color: #f1ffff;
}
</style>