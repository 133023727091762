<template>
    <div style="display: flex; flex-direction: column; margin-top: 5%;">
        <div style="display: flex; flex-direction: column; margin-bottom: 5%; align-items: center;">
            <div :class="`${isMobile ? 'title-text-mobile' : 'title-text'}`" style="margin-bottom: 3%;">Services</div>
            <div class="divider" :style="`width: ${isMobile ? '20px' : '40px'}`"></div>
        </div>

        <div :class="`${isMobile ? 'introduction-text-mobile' : 'introduction-text'}`">
            MetaNovas is an innovative engine for functional food and cosmetics development. We transform health product
            development by AI & science.
        </div>

        <div :class="`${isMobile ? 'service-container-mobile' : 'service-container'}`">
            <div class="service-card">
                <img v-lazy="'/images/about/core-product-1.jpg'" width="280" height="260" />
                <div class="service-card-title">AI-driven new formulations</div>
                <div class="service-card-text">Discover cutting-edge formulations powered by artificial intelligence and
                    grounded in scientific research. Experience the optimal blend of ingredients meticulously designed
                    to achieve the finest health results.</div>
            </div>

            <div class="service-card">
                <img v-lazy="'/images/about/core-product-2.jpg'" width="280" height="260" />
                <div class="service-card-title">AI designed novel molecules</div>
                <div class="service-card-text">Leverage our advanced technology, which fuses deep learning and molecular
                    dynamics simulations, to generate groundbreaking functional peptides efficiently. These
                    state-of-the-art molecules demonstrate impressive success rates, as confirmed by both in-vitro and
                    in-vivo models.</div>
            </div>

            <div class="service-card">
                <img v-lazy="'/images/about/core-product-3.jpg'" width="280" height="260" />
                <div class="service-card-title">AI expanding new applications of old ingredients</div>
                <div class="service-card-text">Unveil the hidden health potentials of existing ingredients as our
                    AI-driven, expansive knowledge graph brings to light previously undiscovered applications and
                    benefits in the realm of health and wellness.</div>
            </div>

            <div class="service-card">
                <img v-lazy="'/images/about/core-product-4.jpg'" width="280" height="260" />
                <div class="service-card-title">AI repurposing drugs</div>
                <div class="service-card-text">Harness the power of AI to transform existing drugs for novel
                    applications through insightful biological network inference and comprehensive multi-omics analysis,
                    creating innovative solutions for diverse medical needs.</div>
            </div>
        </div>

        <div :class="summaryDividerClass"></div>

        <div :class="productsSummaryClass">
            Our company currently offers five major products that have achieved widespread application. Furthermore,
            our company possesses robust independent research and development capabilities, with a number of
            high-quality products currently in the process of being developed.
        </div>
    </div>
</template>

<script>
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";
import { ref } from 'vue';

export default {
    setup() {
        var screenSize = useScreenSize();
        var isMobile = ref(window.innerWidth <= 768);
        var productsSummaryClass = isMobile.value ? 'products-summary-mobile' : 'products-summary';
        var summaryDividerClass = isMobile.value ? 'summary-divider-mobile' : 'summary-divider';

        return {
            screenSize,
            isMobile,
            productsSummaryClass,
            summaryDividerClass,
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    },
    methods: {
        handleWindowSizeChange() {
            // console.log(window.innerWidth)
            this.browserWidth = window.innerWidth;
            if (window.innerWidth <= 768) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        },
    }
}
</script>

<style scoped>
.divider {
    height: 2px;
    background-color: black;
    margin-top: -2%;
    /* margin-bottom: 3%; */
}

.summary-divider {
    height: 2px;
    background-color: black;
    margin-top: 120px;
    margin-bottom: 2%;
    width: 80%;
    align-self: center;
}
.summary-divider-mobile {
    height: 1.5px;
    background-color: black;
    margin-top: 50px;
    margin-bottom: 12%;
    width: 80%;
    align-self: center;
}

.title-text {
    font-size: 52px;
    line-height: 50px;
    letter-spacing: -0.8px;
    font-weight: 500;
}

.title-text-mobile {
    font-size: 24px;
    line-height: 50px;
    letter-spacing: -0.8px;
}

.introduction-text {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0;
    width: 67%;
    text-align: center;
    align-self: center;
    margin-bottom: 5%;
}

.introduction-text-mobile {
    font-size: 12px;
    line-height: 26px;
    letter-spacing: 0;
    width: 67%;
    text-align: center;
    align-self: center;
    margin-bottom: 5%;
}

.service-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    overflow-x: auto;
    padding-top: 10px;
    padding-bottom: 10px;
}

.service-container-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    overflow-x: auto;
    /* height: 510px; */
    column-gap: 5%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.service-card {
    display: flex;
    flex-direction: column;
    padding: 2%;
    width: 310px;
    height: 500px;
    align-items: center;
    gap: 2%;
    background-color: #F5F5F5;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

.service-card-title {
    font-weight: bold;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0;
    width: 280px;
}

.service-card-text {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0;
    text-align: start;
    width: 260px;
}

.products-summary {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 140px; */
    font-size: 24px;
    font-weight: normal;
    margin: 0 120px 120px 120px;
}

.products-summary-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 140px; */
    font-size: 12px;
    font-weight: normal;
    margin: 0 50px 50px 50px;
}
</style>