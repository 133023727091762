<template>
    <div class="background" :class="backgroundClass"
        v-lazy:background-image="'/images/product/half-face-portrait-young-beautiful-girl-with-clean-fresh-skin-closed-eyes-beauty-health-lifestyle.jpg'">
        <div :class="introductionTextWrapperClass">
            <div :style="`width: ${headerTextsWrapperWidth}; display: flex; flex-direction: column;`">
                <div :class="headerClass">Products introduction</div>
                <p :class="headerIntroductionClass">Our company currently offers five major products that have
                    achieved widespread application. </p>
                <p :class="headerIntroductionClass">Furthermore, our company possesses robust
                    independent research and development capabilities, with a number of high-quality products currently in
                    the process of being developed.</p>
                <IntroductionButton v-if="!isMobile" style="margin-top: 5%;" :isMobile="isMobile"></IntroductionButton>
            </div>
        </div>
        <v-divider v-if="!isMobile" style="width: 80%; margin-top: 5%; margin-bottom: 5%;" inset color="black"></v-divider>
        <div style="width: 100%; display: flex; flex-direction: column; align-items: center;">
            <IntroductionCards :isMobile="isMobile"></IntroductionCards>
            <IntroductionButton v-if="isMobile" style="margin-top: 3%;" :isMobile="isMobile"></IntroductionButton>
            <div v-if="isMobile" style="height: 10px;"></div>
        </div>
    </div>
</template>

<script>
import IntroductionCards from '@/components/Products/IntroductionCards.vue';
import IntroductionButton from '@/components/Products/IntroductionButton.vue';
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";
import { ref } from 'vue';
export default {
    components: {
        IntroductionCards,
        IntroductionButton,
    },
    setup() {
        var screenSize = useScreenSize();
        var isMobile = ref(window.innerWidth <= 768);
        var browserWidth = ref(window.innerWidth);
        
        var headerClass = ref(isMobile.value ? 'header-text-mobile' : 'header-text');
        var headerTextsWrapperWidth = ref(isMobile.value ? '90%' : '60%');
        var headerIntroductionClass = ref(isMobile.value ? 'header-introduction-text-mobile' : 'header-introduction-text');
        var introductionTextWrapperClass = ref(isMobile.value ? 'introduction-text-wrapper-mobile' : 'introduction-text-wrapper');
        var backgroundClass = ref(isMobile.value ? 'background-extra-mobile' : 'background-extra');

        return {
            screenSize,
            isMobile,
            headerClass,
            headerTextsWrapperWidth,
            headerIntroductionClass,
            introductionTextWrapperClass,
            backgroundClass,
            browserWidth,
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    },
    methods: {
        handleWindowSizeChange() {
            // console.log(window.innerWidth)
            this.browserWidth = window.innerWidth;
            if (window.innerWidth <= 768) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
            this.headerClass = this.isMobile ? 'header-text-mobile' : 'header-text';
            this.headerTextsWrapperWidth = this.isMobile ? '90%' : '60%';
            this.headerIntroductionClass = this.isMobile ? 'header-introduction-text-mobile' : 'header-introduction-text';
            this.introductionTextWrapperClass = this.isMobile ? 'introduction-text-wrapper-mobile' : 'introduction-text-wrapper';
            this.backgroundClass = this.isMobile ? 'background-extra-mobile' : 'background-extra';
        },
    }
}
</script>

<style scoped>
.background {
    /* background-image: url("../../../public/images/product/half-face-portrait-young-beautiful-girl-with-clean-fresh-skin-closed-eyes-beauty-health-lifestyle.jpg"); */
    background-repeat: no-repeat;
    /* background-size: 100%; */
    background-position-x: right;
    margin-bottom: 2%;
    width: 100%;
    /* background-position-y: top; */
}
.background-extra {
    padding-top: 205px; 
    padding-bottom: 75px; 
    background-size: cover; 
    background-position-y: center;
}
.background-extra-mobile {
    padding-top: 94px; 
    padding-bottom: 5%; 
    background-position-y: top;
    background-size: cover;
}
.introduction-text-wrapper {
    width: 100%; 
    color: black; 
    display: flex; 
    flex-direction: column;
    padding-left: 7%;
}
.introduction-text-wrapper-mobile {
    width: 100%; 
    color: black; 
    display: flex; 
    flex-direction: column;
    align-items: center;
}
.header-text {
    font-size: 88px;
    line-height: 88px;
    letter-spacing: -1.28px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 60px;
}
.header-text-mobile {
    font-size: 32px;
    font-weight: normal;
    line-height: 72px;
    width: 100%;
}
.header-introduction-text {
    font-size: 24px;
    line-height: 38px;
    text-align: left;
}
.header-introduction-text-mobile {
    font-size: 10px;
    text-align: left;
    line-height: 19px;
    letter-spacing: 0.18px;
    width: 100%;
}
</style>