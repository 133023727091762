<template>
  <div class="about-products">
    <div class="products-title">
      <p :class="titleTextClass">Products</p>
      <div class="title-underline"></div>
    </div>
    <div class="products-cards">
        <IntroductionCards :isMobile="isMobile" />
    </div>
    <IntroductionButton :isMobile="isMobile" class="products-btn" />
  </div>
</template>

<script>
import IntroductionCards from '@/components/Products/IntroductionCards.vue';
import IntroductionButton from '@/components/Products/IntroductionButton.vue';
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";
import { ref } from 'vue';

export default {
    name: 'Products',
    components: { IntroductionCards, IntroductionButton },
    setup() {
      var screenSize = useScreenSize()
      var isMobile = screenSize.isMobile.value;
      var titleTextClass = isMobile ? 'title-text-mobile' : 'title-text';
      var productsSummaryClass = isMobile ? 'products-summary-mobile' : 'products-summary';
      return {
        isMobile,
        titleTextClass,
        productsSummaryClass,
      }
    }
};
</script>

<style scoped>
.about-products {
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  padding: 10px 0;
  align-items: center;
  justify-content: center;
  background-image: url("../../../public/images/about/products-bg.jpg");
  background-size: cover;
  background-position: center;
}
.products-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 60px;
}
.awards-text{
    font-size: 24px;
    font-weight: normal;
    width: 1110px;
    margin-bottom: 120px;
}
.title-text {
  font-size: 52px;
  font-weight: 500;
}
.title-text-mobile {
  font-size: 20px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0px;
}
.title-underline {
  width: 40px;
  height: 2px;
  background-color: black;
}
.products-cards {
  margin-bottom: 60px;
}
.products-summary {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 280px;
  font-size: 24px;
  font-weight: normal;
  margin: 0 120px;
}
.products-summary-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 280px;
  font-size: 12px;
  font-weight: normal;
  margin: 0 50px;
}
</style>