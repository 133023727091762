<template>
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2812_217)">
            <path
                d="M17.8086 2H5.19141C3.43628 2 2 3.43628 2 5.19141V17.8086C2 19.5637 3.43628 21 5.19141 21H17.8086C19.5637 21 21 19.5637 21 17.8086V5.19141C21 3.43628 19.5637 2 17.8086 2ZM9.125 17.4375H6.75V9.125H9.125V17.4375ZM7.9375 7.9375C7.28141 7.9375 6.75 7.40609 6.75 6.75C6.75 6.09391 7.28141 5.5625 7.9375 5.5625C8.59359 5.5625 9.125 6.09391 9.125 6.75C9.125 7.40609 8.59359 7.9375 7.9375 7.9375ZM17.4375 17.4375H15.0625V12.6875C15.0625 12.0314 14.5311 11.5 13.875 11.5C13.2189 11.5 12.6875 12.0314 12.6875 12.6875V17.4375H10.3125V9.125H12.6875V10.5993C13.1773 9.92656 13.9261 9.125 14.7656 9.125C16.2411 9.125 17.4375 10.4544 17.4375 12.0938V17.4375Z"
                fill="black" />
        </g>
        <defs>
            <clipPath id="clip0_2812_217">
                <rect width="23" height="23" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>