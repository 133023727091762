<template>
  <div
    class="about-rd"
    :class="{ 'mobile-view': sizes.isMobile.value }"
    :style="{
      '--browserWidth': browserWidth + 'px',
      '--browserHeight': browserHeight + 'px',
    }"
  >
    <p class="rd-text">
      Through the fusion of artificial intelligence and science, MetaNovas
      provides end-to-end R&D services in the health sectors of cosmetics,
      nutritions, pharmaceuticals and others.
    </p>
    <div class="rd-cards" :class="browserWidth < 1160 ? 'rd-cards-small-screen' : ''">
      <div class="rd-card">
        <ArrowIcon :scale="sizes.isMobile.value ? 0.6 : 1" class="card-arrow-up"/>
        <p class="card-text">
          R&D success Increase to <span class="text-highlight"> over 45%</span>
        </p>
      </div>
      <div class="rd-card">
        <ArrowIcon :scale="sizes.isMobile.value ? 0.6 : 1" class="card-arrow-down"/>
        <p class="card-text">
          Reduce R&D cycle
          <span class="text-highlight">from months to weeks</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";
import ArrowIcon from "@/components/About/ArrowIcon.vue";

export default {
  name: "RD",
  components: { ArrowIcon },
  setup() {
    const sizes = useScreenSize();
    const scale = 0.95;
    const { browserWidth, browserHeight } = calBrowserSize(sizes, scale);

    return { sizes, browserWidth, browserHeight };
  },
};
</script>

<style scoped>
.about-rd {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 394px; */
  background-color: #f5f5f5;
  padding: 20px 0;
}
.rd-text {
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 40px;
  /* width: 1064px; */
}
.rd-cards {
  display: flex;
  flex-direction: row;
  gap: 40px;
}
.rd-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 560px;
  height: 118px;
  border-radius: 30px;
  background-color: #284070;
  padding: 0 50px;
}
.card-text {
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  color: white;
  text-align: left;
  margin-left: 50px;
}
.text-highlight {
  color: #6cd2a0;
}
.card-arrow-down {
  rotate: 180deg;
}

.rd-cards-small-screen {
  flex-direction: column !important;
}

/* mobile view */
@media (max-width: 768px) {
  .about-rd {
    width: var(--browserWidth);
    /* height: var(--browserHeight); */
    height: auto !important;
    padding: 10% 0;
  }
  .rd-text {
    font-size: 12px;
    margin-bottom: 50px;
    width: 90%;
  }
  .rd-cards {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  .rd-card {
    width: 70%;
    /* height: 67px; */
    border-radius: 15px;
    padding: 0 20px;
  }
  .card-text {
    font-size: 15px;
    margin-left: 30px;
  }
}
</style>