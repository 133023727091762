<template>
  <div class="awards-card" :class="{ 'mobile-view': isMobile}" @click="openLink">
    <img
      v-lazy="data.url"
      :alt="'award & honors' + data.title"
      class="card-image"
    />
    <p class="card-title">
      {{ data.title }}
    </p>
  </div>
</template>

<script>
export default {
  name: "AwardsCard",
  props: ["data", "isMobile"],
  methods: {
    openLink() {
      if (this.data.link) {
        window.open(this.data.link, '_blank');
      }
    },
  }
};
</script>

<style scoped>
.awards-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 360px;
  height: 379px;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  cursor: pointer;
}
.card-image {
  height: 70%;
  width: 100%;
  object-fit: cover;
  border-bottom: 2px solid rgba(181, 182, 182, 0.52);
}
.card-title {
  font-size: 20px;
  font-weight: bold;
  color: black;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 0 20px;
}

/* mobile view */
.mobile-view .awards-card {
  width: 155px;
  height: 163px;
  border-radius: 10px;
}
.mobile-view .card-title {
  font-size: 8px;
  padding: 0 10px;
}
</style>