<template>
  <div class="about-contact-us" :class="{ 'mobile-view': isMobile }" :style="{
    '--browserWidth': browserWidth + 'px',
    '--browserHeight': browserHeight * 0.8 + 'px',
  }">
    <div class="contact-us-header">
      <Logo :isMobile="isMobile" @click="logoOnClick" style="cursor: pointer;" />
      <div v-if="!isMobile" class="header-media">
        <p class="header-text">Follow Us</p>
        <a href="https://www.linkedin.com/company/metanovas-biotech" target="_blank">
          <img src="/images/linkedin-icon.png" alt="linkedin" class="header-icon" />
        </a>
        <a href="https://www.youtube.com/channel/UClcKj7x_SaH1MaghU5XoKuA" target="_blank">
          <img src="/images/youtube-icon.png" alt="youtube" class="header-icon" />
        </a>
      </div>
    </div>
    <div class="contact-us-title">
      <p class="title-text">Contact Us</p>
      <div class="title-underline"></div>
    </div>

    <div class="contact-us-container">
      <div class="contact-us-content" :style="`height: ${contactUsContentHeight}; justify-content: ${contactUsContentJustifyContent};`">
        <div class="content-item" @click="openLinkedIn" style="cursor: pointer">
          <div class="content-icon"><img v-lazy="'/images/about/about-us-icon01.png'" /></div>
          <p class="content-title" :style="`font-size: ${isMobile ? '10px' : ''}`">LinkedIn Official Account</p>
          <p class="content-email" :style="`font-size: ${isMobile ? '10px' : ''}; margin-top: ${isMobile ? '5%' : 'auto'}`">MetaNovas Biotech</p>
        </div>
        <div class="content-item">
          <div class="content-icon"><img v-lazy="'/images/about/about-us-icon02.png'" /></div>
          <p class="content-title" :style="`font-size: ${isMobile ? '10px' : ''}`">Job Recruitment</p>
          <p class="content-email" :style="`font-size: ${isMobile ? '10px' : ''}; margin-top: ${isMobile ? '5%' : 'auto'}`">hr@metanovas.com</p>
        </div>
        <div class="content-item">
          <div class="content-icon"><img v-lazy="'/images/about/about-us-icon03.png'" /></div>
          <p class="content-title" :style="`font-size: ${isMobile ? '10px' : ''}`">Business Cooperation</p>
          <p class="content-email" :style="`font-size: ${isMobile ? '10px' : ''}; margin-top: ${isMobile ? '5%' : 'auto'}`">bd@metanovas.com</p>
        </div>
        <div class="content-item">
          <div class="content-icon"><img v-lazy="'/images/about/about-us-icon04.png'" /></div>
          <p class="content-title" :style="`font-size: ${isMobile ? '10px' : ''}`">News report</p>
          <p class="content-email" :style="`font-size: ${isMobile ? '10px' : ''}; margin-top: ${isMobile ? '5%' : 'auto'}`">pr@metanovas.com</p>
        </div>
      </div>
      <div :class="{ 'contact-us-bgblock': !isMobile }" :style="`bottom: ${contactUsBgBlockBottom}`"></div>
    </div>

    <div class="about-us-notice">
      <p class="notice-text">© 2024 MetaNovas Biotech. All Rights Reserved.</p>
      <p class="notice-text">
        These statements have not been evaluated by the Food and Drug
        Administration.
      </p>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";
import { ref } from "vue";

export default {
  name: "ContactUs",
  components: { Logo },
  setup() {
    const sizes = useScreenSize();
    const scale = 1.58;
    var isMobile = ref(window.innerWidth <= 1100);
    const { browserWidth, browserHeight } = calBrowserSize(sizes, scale);

    var is4k = window.innerWidth >= 2500
    var contactUsContentHeight = is4k ? '220px' : '220px';
    var contactUsBgBlockBottom = is4k ? '0' : '0';
    var contactUsContentJustifyContent = is4k ? 'space-evenly' : 'space-between';

    return { sizes, browserWidth, browserHeight, isMobile, contactUsContentHeight, contactUsBgBlockBottom, contactUsContentJustifyContent };
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  },
  methods: {
    logoOnClick() {
      var elements = document.getElementsByClassName("navbar-link-active");
      for (let item of elements) {
          item.classList.remove(["navbar-link-active"]);
      }
      var element = document.getElementById('navbar-about');
      if (element) element.classList.add(["navbar-link-active"]);

      this.$router.push({ name: "About" })
    },

    handleWindowSizeChange() {
      // console.log(window.innerWidth)
      this.browserWidth = window.innerWidth;
      this.isMobile = window.innerWidth <= 1100;
    },
    openLinkedIn() {
      window.open('https://www.linkedin.com/company/metanovas-biotech', '_blank');
    }
  }
};
</script>

<style scoped>
.about-contact-us {
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  height: var(--browserHeight);
  padding: 10px 0;
  background-color: #284070;
  align-items: center;
  justify-content: flex-start;
}

.contact-us-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 30px;
  width: 100%;
  /* margin-top: 1%; */
  /* margin-bottom: 5%; */
}

.header-media {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.header-text {
  font-size: 24px;
  font-weight: Bold;
  color: white;
}

.header-icon {
  width: 37px;
  height: 37px;
}

.contact-us-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}

.title-text {
  font-size: 52px;
  font-weight: 500;
  color: white;
}

.title-underline {
  width: 40px;
  height: 2px;
  background-color: white;
}

.contact-us-container {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: center;
  width: 100%;
  /* height: 30%; */
  margin-bottom: auto;
  position: relative;
}

.contact-us-content {
  width: 80%;
  height: 84%;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  z-index: 2;
}

.content-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 20px;
}

.content-icon {
  display: flex;
  justify-content: center;
  width: 30%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  margin-bottom: 3%;
}

.content-title {
  font-size: 20px;
  font-weight: normal;
  color: white;
}

.content-email {
  font-size: 20px;
  font-weight: bold;
  color: white;
  /* margin-top: 5%; */
}

.contact-us-bgblock {
  width: 100%;
  background-color: #6cd2a0;
  height: 80px;
  position: absolute;
  bottom: 50px;
  z-index: 1;
}

.notice-text {
  font-size: 15.4px;
  font-weight: normal;
  margin-bottom: 20px;
  color: white;
}

@media (max-width: 1100px) {
  .about-contact-us {
    width: var(--browserWidth);
    /* height: var(--browserHeight); */
    height: auto !important;
  }

  .contact-us-header {
    padding: 0 15px;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .contact-us-title {
    margin-bottom: 12%;
  }

  .title-text {
    font-size: 20px;
  }

  .title-underline {
    width: 20px;
  }

  .contact-us-container {
    height: 50%;
    margin-bottom: 10%;
  }

  .contact-us-content {
    width: 80% !important;
    height: 100% !important;
    flex-wrap: wrap !important;
  }

  .content-item {
    height: 45%;
    padding-bottom: 0;
    margin-bottom: 5%;
  }

  .content-icon {
    width: 60px;
    /* height: 60px; */
    margin-bottom: 5%;
  }

  .content-title {
    font-size: 12px;
    /* margin-bottom: 2%; */
  }

  .content-email {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    background-color: #6cd2a0;
    border-radius: 6px;
    font-size: 12px;
    padding: 0 10px;
  }

  .about-us-notice {
    width: 75%;
  }

  .notice-text {
    font-size: 10px;
    margin-bottom: 10px;
  }
}
</style>