<template>
    <svg width="43" height="56" viewBox="0 0 43 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 27H10.5V51C10.5 53.2091 8.70914 55 6.5 55V55C4.29086 55 2.5 53.2091 2.5 51V27Z" stroke="#284070"
            stroke-width="2" />
        <path d="M17.5 27H25.5V51C25.5 53.2091 23.7091 55 21.5 55V55C19.2909 55 17.5 53.2091 17.5 51V27Z"
            stroke="#284070" stroke-width="2" />
        <path d="M32.5 27H40.5V51C40.5 53.2091 38.7091 55 36.5 55V55C34.2909 55 32.5 53.2091 32.5 51V27Z"
            stroke="#284070" stroke-width="2" />
        <path d="M16 27L27 27" stroke="#284070" stroke-width="2" stroke-linecap="round" />
        <path d="M1 27L12 27" stroke="#284070" stroke-width="2" stroke-linecap="round" />
        <path d="M31 27L42 27" stroke="#284070" stroke-width="2" stroke-linecap="round" />
        <path d="M2.5 39.5C3.5 40 5.9 40.7 7.5 39.5C9.5 38 10.5 37 10.5 37.5" stroke="#284070" stroke-width="2"
            stroke-linecap="round" />
        <path d="M17.5 44.1301C18.5 44.6301 20.9 45.3301 22.5 44.1301C24.5 42.6301 25.5 41.6301 25.5 42.1301"
            stroke="#284070" stroke-width="2" stroke-linecap="round" />
        <path d="M32.5 44.1301C33.5 44.6301 35.9 45.3301 37.5 44.1301C39.5 42.6301 40.5 41.6301 40.5 42.1301"
            stroke="#284070" stroke-width="2" stroke-linecap="round" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M5.56043 31.0606C4.97465 31.6464 4.97465 32.5962 5.56043 33.182C6.14622 33.7677 7.09597 33.7677 7.68175 33.182C8.26754 32.5962 8.26754 31.6464 7.68175 31.0606L6.62109 30L5.56043 31.0606Z"
            fill="#284070" />
        <path
            d="M6.62109 30L6.97465 29.6464L6.62109 29.2929L6.26754 29.6464L6.62109 30ZM5.91399 32.8284C5.52346 32.4379 5.52346 31.8047 5.91399 31.4142L5.20688 30.7071C4.42583 31.4881 4.42583 32.7545 5.20688 33.5355L5.91399 32.8284ZM7.3282 32.8284C6.93768 33.2189 6.30451 33.2189 5.91399 32.8284L5.20688 33.5355C5.98793 34.3166 7.25426 34.3166 8.03531 33.5355L7.3282 32.8284ZM7.3282 31.4142C7.71872 31.8047 7.71872 32.4379 7.3282 32.8284L8.03531 33.5355C8.81636 32.7545 8.81636 31.4881 8.03531 30.7071L7.3282 31.4142ZM6.26754 30.3535L7.3282 31.4142L8.03531 30.7071L6.97465 29.6464L6.26754 30.3535ZM5.91399 31.4142L6.97465 30.3535L6.26754 29.6464L5.20688 30.7071L5.91399 31.4142Z"
            fill="#284070" />
        <path
            d="M16.5 6.5L11.4793 11.5207C10.8385 12.1615 10.3839 12.9644 10.1641 13.8436L9.83589 15.1564C9.6161 16.0356 9.16151 16.8385 8.52071 17.4793L6.5 19.5"
            stroke="#284070" stroke-width="2" stroke-linecap="round" />
        <path
            d="M22 12L17.0296 16.9704C16.3571 17.6429 15.5069 18.1096 14.5787 18.3159L12.9213 18.6841C11.9931 18.8904 11.1429 19.3571 10.4705 20.0295L8.5 22"
            stroke="#284070" stroke-width="2" stroke-linecap="round" />
        <path d="M17.71 1.9906L26.5 11" stroke="#284070" stroke-width="2" stroke-linecap="round" />
        <path
            d="M23.9055 9.23181L26.5293 6.608C27.7458 5.3915 27.7458 3.41917 26.5293 2.20267V2.20267C25.3128 0.986167 23.3405 0.986168 22.124 2.20267L19.5002 4.82648"
            stroke="#284070" stroke-width="2" />
    </svg>

</template>