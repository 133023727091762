<template>
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18.5" cy="18.5" r="18.5" fill="#2C406C" />
        <path
            d="M24.935 24.8074C24.7284 24.8074 24.5494 24.7346 24.3977 24.589C24.2461 24.4434 24.1708 24.2702 24.1719 24.0695L24.1719 13.7384C24.1719 13.5387 24.2471 13.3655 24.3977 13.2189C24.5483 13.0723 24.7274 12.9995 24.935 13.0005C25.1426 13.0015 25.3217 13.0743 25.4723 13.2189L30.8146 18.3845C30.9652 18.5301 31.0405 18.7032 31.0405 18.904C31.0405 19.1047 30.9652 19.2778 30.8146 19.4235L25.4723 24.589C25.3217 24.7346 25.1426 24.8074 24.935 24.8074Z"
            fill="white" />
        <path
            d="M13.1055 13.0002C13.3121 13.0002 13.4912 13.073 13.6428 13.2186C13.7944 13.3642 13.8697 13.5374 13.8687 13.7381L13.8687 24.0692C13.8687 24.2689 13.7934 24.4421 13.6428 24.5887C13.4922 24.7353 13.3131 24.8081 13.1055 24.8071C12.8979 24.8061 12.7188 24.7333 12.5682 24.5887L7.22589 19.4232C7.07528 19.2775 7 19.1044 7 18.9036C7 18.7029 7.0753 18.5298 7.22589 18.3842L12.5682 13.2186C12.7188 13.073 12.8979 13.0002 13.1055 13.0002Z"
            fill="white" />
    </svg>
</template>