<template>
    <nav :style="`width: ${pageWidth}px; height: ${pageHeight * 2}px;`" class="header-navbar" id="header-navbar">
        <v-list v-model:opened="open" style="width: 100%;">
            <v-list-item title="About" @click="navOnClick($event, 'navbar-about', 'About')">
            </v-list-item>

            <v-list-item title="Technology" @click="navOnClick($event, 'navbar-technology', 'Technology')">
            </v-list-item>

            <v-list-group value="Products">
                <template v-slot:activator="{ props }">
                    <v-list-item
                    v-bind="props"
                    title="Products"
                    @click="navOnClick($event, 'navbar-products', 'ProductsIntroduction')"
                    ></v-list-item>
                </template>
                <v-list-item
                    v-for="([title, link], i) in products"
                    :key="i"
                    :title="title"
                    :value="title"
                    style="font-size: 8px;"
                    @click="navOnClick($event, 'navbar-products', link)"
                ></v-list-item>
            </v-list-group>

            <v-list-item title="Media" @click="navOnClick($event, 'navbar-media', 'Media')">
            </v-list-item>

            <!-- <v-list-item title="Support" @click="navOnClick($event, 'navbar-support', 'About')">
            </v-list-item> -->
        </v-list>
    </nav>
</template>

<script>
import { ref } from 'vue';

export default {
    setup() {
        var pageWidth = window.innerWidth * 0.6;
        var pageHeight = window.innerHeight;
        var open = ref(["Products"]);
        var products = [["MetaAMP5", "ProductsMetaAMP5"], ["MetaAMP33", "ProductsMetaAMP33"], ["MetaAMP12", "ProductsMetaAMP12"], ["MetaCono", "ProductsMetaCono"], ["MetaPep-Ocean-1", "ProductsMetaPepOcean1"]]
        return {
            pageWidth,
            pageHeight,
            open,
            products,
        }
    },
    methods: {
        navOnClick(event, index, name) {
            // var elements = document.getElementsByClassName("navbar-link-active");
            // for (let item of elements) {
            //     item.classList.remove(["navbar-link-active"]);
            // }
            // console.log(index);
            // var element = document.getElementById(index);
            // if (element) element.classList.add(["navbar-link-active"]);
            this.$router.push({'name': name})
        },
    }
}
</script>

<style scoped>
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    /* height: 76px; */
}

.header-navbar {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    background-color: white;
    text-align: left;
}

.navbar-link {
    display: flex;
    text-decoration: none;
    white-space: nowrap;
    margin-right: 20px;
    color: black;
    font-size: 15.4px;
    font-weight: normal;
}

.gg-chevron-down {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 100px;
    color: var(--accent-color);
    opacity: 0;
}

.gg-chevron-down::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    left: 4px;
    top: 2px
}

.navbar-link:hover .gg-chevron-down {
    opacity: 1;
}

.navbar-link:hover {
    color: var(--accent-color);
    font-weight: bold;
}

.navbar-link-active {
    color: var(--accent-color) !important;
}

.dropdown-container {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.dropdown-item-container {
    margin-top: 5px;
}
</style>