<template>
    <div>
        <div class="page-container" :style="`color: ${color || 'black'}`">
            <div style="width: 100%; text-align: center;">
                <div style="width: 100%; display: flex; flex-direction: column; align-items: center;">
                    <p :class="titleClass">{{ title }}</p>
                    <div v-if="isMobile" class="divider"></div>
                    <p :class="subTitleClass">{{ subTitle }}</p>
                </div>
            </div>
            <v-row v-if="!isMobile" no-gutters style="width: 90%; align-self: center; margin-top: 75px;">
                <v-col v-for="(card, index) in cards" :key="'cards_' + index" cols="12" :md="cardMd" style="display: flex; justify-content: center;">
                    <div style="background-color: rgba(255, 255, 255, 0.5); padding: 5% 3% 3% 5%; width: 85%; border-radius: 12px;">
                        <p style="font-size: 24px; font-weight: bold; text-align: left;">{{ card.title }}</p>
                        <p style="font-size: 16px; text-align: left;">{{ card.text }}</p>
                    </div>
                </v-col>
            </v-row>

            <div v-else style="display: flex; flex-direction: column; align-items: center;">
                <div v-for="(card, index) in cards" :key="'cards_' + index" style="display: flex; justify-content: center; width: 100%;">
                    <div style="background-color: rgba(255, 255, 255, 0.5); padding: 3% 3% 3% 3%; width: 75%; border-radius: 12px; margin-bottom: 3%;">
                        <p style="font-size: 16px; font-weight: bold; text-align: left; line-height: 46px; letter-spacing: -0.8px;">{{ card.title }}</p>
                        <p style="font-size: 12px; text-align: left; line-height: 19px; letter-spacing: 0;">{{ card.text }}</p>
                    </div>
                </div>
            </div>
            
            <p :style="`color: ${color || '#2E2E27'}`" :class="pageTextClass">
                {{ text }}
            </p>
        </div>
    </div>
</template>

<script>
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";
import { ref } from 'vue';
export default {
    props: ['title', 'subTitle', 'cards', 'text', 'diagramBar', 'diagramBarText', "color"],
    setup(props) {
        var screenSize = useScreenSize()
        var isMobile = screenSize.isMobile.value;
        var backgroundMarginTop = isMobile ? ref('94px') : ref('205px');
        var titleClass = isMobile ? 'header-text-mobile' : 'header-text';
        var subTitleClass = isMobile ? 'header-sub-title-text-mobile' : 'header-sub-title-text'
        var pageTextClass = isMobile ? 'page-text-mobile' : 'page-text'

        var title = props.title;
        var subTitle = props.subTitle;
        var cards = props.cards;
        var text = props.text;
        var cardMd = 12 / props.cards.length;
        var diagramBar = props.diagramBar;
        var diagramBarText = props.diagramBarText;

        return {
            title,
            subTitle,
            cards,
            text,
            cardMd,
            diagramBar,
            diagramBarText,
            backgroundMarginTop,
            titleClass,
            subTitleClass,
            pageTextClass,
            isMobile,
        }
    },
}
</script>

<style>
.page-container {
    color: black;
    padding-top: 164px; 
    display: flex; 
    flex-direction: column; 
    text-align: center; 
    padding-bottom: 118px;
}
.page-text {
    width: 1022px; 
    align-self: center; 
    font-size: 20px; 
    margin-top: 65px; 
    letter-spacing: -0.8px; 
    line-height: 32px; 
    text-align: center; 
}
.page-text-mobile {
    width: 80%;
    align-self: center;
    font-size: 12px;
    margin-top: 65px;
    letter-spacing: 0px;
    line-height: 19px;
    text-align: center;
}
.divider {
    width: 80%;
    height: 1px;
    background-color: black;
    margin-top: 2%;
}
.header-text {
    font-size: 48px;
    font-weight: bold;
    line-height: 46px;
    letter-spacing: -0.8px;
}
.header-text-mobile {
    font-size: 20px;
    font-weight: bold;
    line-height: 32px;
    letter-spacing: -0.8px;
    width: 70%;
}
.header-sub-title-text {
    font-size: 32px;
    line-height: 46px;
    letter-spacing: -0.8px;
}
.header-sub-title-text-mobile {
    font-size: 14px;
    line-height: 46px;
    letter-spacing: 0px;
    margin-bottom: 20%;
}
</style>