<template>
    <product-top-page :title="title" :subTitle="subTitle" :cards="cards" :text="text" class="background" :class="backgroundClass" :style="`width: ${screenSize.browserWidth} px;`"
        v-lazy:background-image="'/images/product/high-angle-dental-tools-arrangement.jpg'"
    ></product-top-page>
    <product-bar :diagramBar="diagramBar" :diagramBarText="diagramBarText"></product-bar>

    <img v-lazy="'/images/product/MetaPep-Ocean-1.png'" :width="image1.width" :height="image1.height" style="align-self: center;" :class="imageMargin"></img>
    <div v-if="isMobile" style="margin-bottom: 10%;">
        <div style="cursor: pointer; font-size: 10px; line-height: 19px; letter-spacing: 0; text-decoration: underline; color: #717173;" @click="zoomImage($event, 'image1')">click to zoom in</div>
        <div v-if="zoomInImage1">
            <div :style="`width: ${windowWidth}px; height: ${windowHeight * 2}px;`" class="page-mask" @click="closeDrawer($event, 'image1')"></div>
            <img v-lazy="'/images/product/MetaPep-Ocean-1.png'" :width="windowWidth * 1.2" style="z-index: 101; left: -10%; top: 20%; position: fixed; background-color: white;"></img>
        </div>
    </div>

    <div style="width: 100%; height: 40px; background-color: #717173;"></div>

    <div style="display: flex; flex-direction: column; margin-top: 70px;">
        <h1 :class="moreAdvantagesTitleClass">More advantages</h1>
        <div style="width: 40px; align-self: center; border-bottom: 2px solid black; margin-top: 22px;"></div>

        <div :class="moreAdvantagesClass">
            <div>
                <h1 :class="moreAdvantagesTitleTitleClass">MetaPep-Ocean-1 can inhibit many oral pathogenic bacteria</h1>
                <div v-if="isMobile" class="divider"></div>
                <p :class="moreAdvantagesTitleBodyClass">MetaPep-Ocean-1 effectively inhibits the growth of the four major harmful oral pathogens at low concentrations without affecting beneficial bacteria, and It can be used to prevent oral microbial diseases.</p>
            </div>
        </div>

        <img src="../../../public/images/product/MetaPep-Ocean-3.png" :width="image2.width" :height="image2.height" style="align-self: center;" :class="imageMargin"></img>
    
        <div :class="moreAdvantagesClass">
            <div>
                <h1 :class="moreAdvantagesTitleTitleClass">MetaPep-Ocean-1 can inhibit the growth of <span style="font-style: italic;">Streptococcus mutans</span></h1>
                <div v-if="isMobile" class="divider"></div>
                <p :class="moreAdvantagesTitleBodyClass"><span style="font-style: italic;">Streptococcus mutans</span> is a major component of dental plaque. MetaPep-Ocean-1 can inhibit the growth of <span style="font-style: italic;">Streptococcus mutans</span> at lower concentrations and maintain oral and gum health.</p>
            </div>
        </div>

        <img src="../../../public/images/product/MetaPep-Ocean-2.png" :width="image3.width" :height="image3.height" style="align-self: center;" :class="imageMargin"></img>
        
        <div :class="moreAdvantagesClass">
            <div>
                <h1 :class="moreAdvantagesTitleTitleClass">MetaPep-Ocean-1 is harmless to beneficial bacteria <span style="font-style: italic;">Lactobacillus acidophilus</span></h1>
                <div v-if="isMobile" class="divider"></div>
                <p :class="moreAdvantagesTitleBodyClass"><span style="font-style: italic;">Lactobacillus acidophilus</span> belongs to oral probiotics and can inhibit harmful bacteria in oral cavity. Experimental results indicated that MetaPep-Ocean-1 only has around 20% inhibition rate on  <span style="font-style: italic;">Lactobacillus acidophilus</span> within 20 minutes, indicating no significant adverse effect on oral probiotics.</p>
            </div>
        </div>

        <img src="../../../public/images/product/MetaPep-Ocean-4.png" :width="image4.width" :height="image4.height" style="align-self: center;" :class="imageMargin"></img>
        
        <div :class="moreAdvantagesClass">
            <div>
                <h1 :class="moreAdvantagesTitleTitleClass">MetaPep-Ocean-1 has anti-inflammatory effects via inhibiting IL-1β</h1>
                <div v-if="isMobile" class="divider"></div>
                <p :class="moreAdvantagesTitleBodyClass">The activation of interleukin-1β (IL-1β) can trigger inflammatory responses. Our experimental findings showed that MetaPep-Ocean-1 can effectively reduce the IL-1β level and this suggests that MetaPep-Ocean-1 has the potential to alleviate gum inflammation.</p>
            </div>
        </div>

        <img src="../../../public/images/product/MetaPep-Ocean-5.png" :width="image5.width" :height="image5.height" style="align-self: center;" :class="imageMargin"></img>

        <ProductButton style="align-self: center; margin-top: 3%; margin-bottom: 3%;" color="#56B7B1" :isMobile="isMobile"></ProductButton>
    </div>
</template>

<script>
import ProductTopPage from "@/components/Products/ProductTopPage.vue"
import ProductBar from "@/components/Products/ProductBar.vue"
import ProductButton from '@/components/Products/ProductButton.vue'
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";
import { ref } from 'vue';
export default {
    components: {
        ProductTopPage,
        ProductBar,
        ProductButton,
    },
    setup() {
        var screenSize = useScreenSize()
        var isMobile = screenSize.isMobile.value;
        var backgroundClass = isMobile ? 'background-extra-mobile' : 'background-extra';
        var image1 = {
            width: isMobile ? window.innerWidth * 0.9 : window.innerWidth * 0.9 < 1203 ? window.innerWidth * 0.9 : 1203,
            height: isMobile ? 'auto' : 'auto',
        }
        var windowWidth = window.innerWidth;
        var windowHeight = window.innerHeight;
        var zoomInImage1 = ref(false)
        var dividerHeight = isMobile ? "15px" : "40px";
        var moreAdvantagesTitleClass = isMobile ? "more-advantages-title-text=mobile" : "more-advantages-title-text";
        var moreAdvantagesClass = isMobile ? "more-advantages-title-mobile more-advantages-text" : "more-advantages-title more-advantages-text";
        var moreAdvantagesTitleTitleClass = isMobile ? "more-advantages-title-title-text-mobile" : "more-advantages-title-title-text";
        var moreAdvantagesTitleBodyClass = isMobile ? "more-advantages-title-body-text-mobile" : "more-advantages-title-body-text";
        var image2 = {
            width: isMobile ? window.innerWidth * 0.9 : window.innerWidth * 0.9 < 1168 ? window.innerWidth * 0.9 : 1168,
            height: isMobile ? 'auto' : 'auto',
        }
        var imageMargin = isMobile ? "image-margin-mobile" : "image-margin";
        var image3 = {
            width: isMobile ? window.innerWidth * 0.9 : window.innerWidth * 0.9 < 943 ? window.innerWidth * 0.9 : 943,
            height: isMobile ? 'auto' : 'auto',
        }
        var productButtonMarginBottom = isMobile ? "10%" : "5%";
        var image4 = {
            width: isMobile ? window.innerWidth * 0.9 : window.innerWidth * 0.9 < 943 ? window.innerWidth * 0.9 : 943,
            height: isMobile ? 'auto' : 'auto',
        }
        var image5 = {
            width: isMobile ? window.innerWidth * 0.9 : window.innerWidth * 0.9 < 907 ? window.innerWidth * 0.9 : 907,
            height: isMobile ? 'auto' : 'auto',
        }

        var title = "Gingival Protection Peptide MetaPep-Ocean-1";
        var subTitle = "Oral microbiome balancer ";
        var cards = [
            {
                title: "Efficacy",
                text: "Antibacterial, anti-inflammatory and enhancing oral protection",
            },
            {
                title: "Advantage",
                text: "Antimicrobial and anti-inflammatory with high selectivity and safety",
            },
        ]
        var text = "Gingival protection peptide MetaPep-Ocean-1 inhibit a broad spectrum of oral pathogenic bacteria without affecting the survival of beneficial bacteria. MetaPep-Ocean-1 can effectively maintain the balance of oral microbiota and promote oral health by inhibiting bacteria and reducing inflammation."
        var diagramBar = {
            left: "Mechanism diagram",
            right: "MetaPep-Ocean-1 is selectively inhibitory to gingival pathogens",
            color: "#717173",
        }
        var diagramBarText = "Poor oral health can lead to tooth decay and periodontal diseases. Excess pathogenic bacteria growth on tooth surfaces leads to oral microbiota imbalance and gum diseases. Streptococcus mutans is a main component of dental plaque, while Porphyromonas gingivalis, Staphylococcus aureus, and Pseudomonas aeruginosa serve as triggers for various oral inflammations. MetaPep-Ocean-1 can inhibit a variety of pathogenic bacteria by disrupting its bacterial membranes and enhance the protection of the oral cavity."
        
        return {
            screenSize,
            isMobile,
            backgroundClass,
            image1,
            windowHeight,
            windowWidth,
            zoomInImage1,
            dividerHeight,
            moreAdvantagesTitleClass,
            moreAdvantagesClass,
            moreAdvantagesTitleTitleClass,
            moreAdvantagesTitleBodyClass,
            image2,
            imageMargin,
            image3,
            productButtonMarginBottom,
            image4,
            image5,
            
            title,
            subTitle,
            cards,
            text,
            diagramBar,
            diagramBarText,
        }
    },
    methods: {
        zoomImage(event, image) {
            if (image == 'image1') {
                this.zoomInImage1 = true;
            }
        },
        closeDrawer(event, image) {
            if (image == 'image1') {
                this.zoomInImage1 = false;
            }
        }
    }
}
</script>

<style scoped>
.page-mask {
    background-color: black;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.background {
    /* background-image: url("../../../public/images/product/high-angle-dental-tools-arrangement.jpg"); */
    background-repeat: no-repeat;
    width: 100%;
    /* background-size: 400%; */
    /* background-position-x: left; */
    /* background-position-y: top; */
}
.background-extra {
    padding-bottom: 75px; 
    background-size: cover; 
    background-position-y: center;
    background-position-x: left;
}
.background-extra-mobile {
    padding-bottom: 5%; 
    background-size: cover; 
    background-position-y: top;
    background-position-x: -400px;
}

.more-advantages-title-text {
    font-size: 52px; 
    line-height: 50px; 
    letter-spacing: -0.8px; 
    color: black;
}

.more-advantages-title-text-mobile {
    font-size: 20px; 
    line-height: 50px; 
    letter-spacing: 0; 
    color: black;
}

.more-advantages-title-mobile {
    padding-left: 6%;
    padding-right: 6%;
    width: 90%; 
    height: 305px; 
    align-self: center; 
    text-align: left; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    margin-top: 4%;
    margin-bottom: 4%;
}

.more-advantages-title-title-text {
    font-size: 30px; 
    line-height: 50px; 
    letter-spacing: -0.8px; 
    color: black;
    margin-bottom: 40px;
}

.more-advantages-title-title-text-mobile {
    font-size: 14px; 
    line-height: 20px; 
    font-weight: bold;
    letter-spacing: 0px; 
    color: black;
}

.more-advantages-title-body-text {
    font-size: 20px; 
    line-height: 32px; 
    letter-spacing: -0.8px; 
    color: #2E2E27;
}

.more-advantages-title-body-text-mobile {
    font-size: 12px; 
    line-height: 20px; 
    letter-spacing: 0px; 
    font-weight: lighter;
    color: #2E2E27;
}

.more-advantages-title {
    padding: 0 50px;
    width: 90%; 
    height: 344px; 
    align-self: center; 
    text-align: left; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.more-advantages-body {
    width: 1140px;
    height: 248px; 
    align-self: center; 
    text-align: left; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
}
.more-advantages-text {
    background-color: #F5F5F5;
    border-radius: 38px;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: black;
    margin-top: 5%;
    margin-bottom: 6%;
}
.image-margin {
    margin-top: 96px; 
    margin-bottom: 111px;
}
.image-margin-mobile {
    margin-top: 10%; 
    margin-bottom: 10%;
}
</style>