<template>
  <div class="about">
    <HomePage />
    <FeaturedOn />
    <AboutUs id="aboutus" />
    <Partnerships id="partnerships" />
    <Platform />
    <Services id="services" />
    <RD />
    <Awards id="awards" />
    <Products id="products" />
    <ServicesOld />
  </div>
</template>

<script>
import HomePage from '@/views/About/HomePage';
import FeaturedOn from '@/views/About/FeaturedOn';
import AboutUs from '@/views/About/AboutUs';
import Partnerships from '@/views/About/Partnerships';
import Platform from '@/views/About/Platform';
import Services from '@/views/About/Services';
import Awards from '@/views/About/Awards';
import Products from '@/views/About/Products';
import ServicesOld from '@/views/About/ServicesOld';
import RD from '@/views/About/RD';

export default {
  name: 'About',
  components: { HomePage, FeaturedOn, AboutUs, Partnerships, Platform, Services, Awards, Products, ServicesOld, RD },
}
</script>

<style></style>