<template>
  <div class="logo" :class="mobileViewClass">
    <div class="logo-container">
      <img src="/images/logo-no-word.png" alt="Logo Image" class="logo-image" />
      <span class="logo-name">MetaNovas</span>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Logo',
    props: ["isMobile"],
    setup(props) {
      var mobileViewClass = props.isMobile ? "mobile-view" : "";
      return {
        mobileViewClass,
      }
    },
};
</script>

<style scoped>
.logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
}
.logo-image {
    width: 48px;
    height: auto;
    margin-right: 15px;
}
.logo-name{
    font-size: 24px;
    font-weight: bold;
    color: var(--accent-color);
}

/* mobile view */
.mobile-view .logo-name {
    font-size: 20px;
}
</style>