<template>
    <div style="cursor: pointer">
        <div :class="textClass" style="display: flex; justify-content: center;" @click="openBrosure">
            <p>Download product brochure</p>
            <v-icon icon="mdi-arrow-right" color="white"></v-icon>
        </div>
    </div>
</template>

<script>
export default {
    props: ["isMobile"],
    setup(props) {
        var isMobile = props.isMobile;
        var textClass = isMobile ? "button-mobile" : "button";
        return {
            textClass,
        }
    },
    methods: {
        openBrosure() {
            open('https://docsend.com/view/s/jayfs7ysj3iui97c', '_blank');
        },
    }
}
</script>

<style scoped>
.button {
    font-size: 15.4px;
    letter-spacing: 0.1px;
    line-height: 23.2px;
    color: white;
    background-color: #199866;
    border-radius: 31px;
    display: flex;
    flex-direction: row;
    padding-left: 2%;
    padding-right: 2%;
    align-items: center;
    width: 285px; 
    height: 50px; 
}
.button-mobile {
    font-size: 10px;
    letter-spacing: 0.1px;
    line-height: 23.2px;
    color: white;
    background-color: #199866;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    padding-left: 2%;
    padding-right: 2%;
    align-items: center;
    width: 180px; 
    height: 31px; 
}
</style>