<template>
  <div
    :class="cardClass"
    style="
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    "
  >
    <img :src="data.url" :alt="'partnership' + data.id" class="card-image" />
  </div>
</template>

<script>
export default {
  name: "PartnershipsCard",
  props: ["data", "isMobile"],
  setup(props) {
    const isMobile = props.isMobile;
    const cardClass = isMobile ? "mobile-card" : "card";
    return { cardClass };
  },
};
</script>

<style scoped>
.card {
  width: 348px;
  height: 125px;
  border-radius: 25px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* mobile view */
.mobile-card {
  width: 216px;
  height: 77px;
  border-radius: 15px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}
</style>