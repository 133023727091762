import { computed } from 'vue';

export function calBrowserSize(sizes, scale) {
    const browserWidth = sizes.browserWidth.value;
    const browserHeight = computed(() => window.innerHeight);

    return {
        browserWidth,
        browserHeight
    };
}