<template>
    <div class="header" :style="`height: ${headerHeight};`">
        <Logo class="header-logo" :isMobile="isMobile" @click="logoOnClick" />

        <div v-if="isMobile">
            <v-icon icon="mdi-menu" style="color: white" @click="openDrawer"></v-icon>
            <div v-if="menuOpen">
                <div :style="`width: ${pageWidth}px; height: ${pageHeight * 2}px;`" class="page-mask"
                    @click="closeDrawer"></div>
                <NavbarMobileLink style="z-index: 101; position: fixed; right: 0; top: 0;"></NavbarMobileLink>
            </div>
        </div>
        <nav v-else class="header-navbar" id="header-navbar">
            <router-link :to="{ name: 'About' }" style="text-decoration: none;">
                <div class="navbar-link" @click="navOnClick($event, 'navbar-about')" id="navbar-about">
                    <span>About</span>
                    <!-- <span class="gg-chevron-down"></span> -->
                </div>
            </router-link>
            <router-link :to="{ name: 'Technology' }" style="text-decoration: none;">
                <div class="navbar-link" @click="navOnClick($event, 'navbar-technology')" id="navbar-technology">
                    <span>Technology</span>
                    <!-- <span class="gg-chevron-down"></span> -->
                </div>
            </router-link>

            <v-menu open-on-hover>
                <template v-slot:activator="{ props }">
                    <router-link :to="{ name: 'ProductsIntroduction' }" style="text-decoration: none; width: 120%;"
                        v-bind="props">
                        <div class="navbar-link" style="margin-right: 3px;"
                            @click="navOnClick($event, 'navbar-products')" id="navbar-products">
                            <span>Products</span>
                            <span class="gg-chevron-down"></span>
                        </div>
                    </router-link>
                </template>

                <div class="dropdown-container">
                    <div class="dropdown-item-container">
                        <router-link :to="{ name: 'ProductsMetaAMP5' }" @click="navOnClick($event, 'navbar-products')"
                            class="navbar-link">
                            MetaAMP5
                        </router-link>
                    </div>
                    <div class="dropdown-item-container">
                        <router-link :to="{ name: 'ProductsMetaAMP33' }" @click="navOnClick($event, 'navbar-products')"
                            class="navbar-link">
                            MetaAMP33
                        </router-link>
                    </div>
                    <div class="dropdown-item-container">
                        <router-link :to="{ name: 'ProductsMetaCono' }" @click="navOnClick($event, 'navbar-products')"
                            class="navbar-link">
                            MetaCono
                        </router-link>
                    </div>
                    <div class="dropdown-item-container">
                        <router-link :to="{ name: 'ProductsMetaPepOcean1' }"
                            @click="navOnClick($event, 'navbar-products')" class="navbar-link">
                            MetaPep-Ocean-1
                        </router-link>
                    </div>
                    <div class="dropdown-item-container">
                        <router-link :to="{ name: 'ProductsMetaAMP12' }" @click="navOnClick($event, 'navbar-products')"
                            class="navbar-link">
                            MetaAMP12
                        </router-link>
                    </div>
                </div>
            </v-menu>

            <router-link :to="{ name: 'Media' }" style="text-decoration: none;">
                <div class="navbar-link" @click="navOnClick($event, 'navbar-media')" id="navbar-media">
                    <span>Media</span>
                    <!-- <span class="gg-chevron-down"></span> -->
                </div>
            </router-link>
            <router-link :to="{ name: 'About', hash: '#support' }" style="text-decoration: none;">
                <div class="navbar-link" @click="navOnClick($event, 'navbar-support')" id="navbar-support">
                    <span>Support</span>
                    <!-- <span class="gg-chevron-down"></span> -->
                </div>
            </router-link>
        </nav>
    </div>
</template>

<script>
import Logo from '@/components/Logo.vue';
import NavbarMobileLink from '@/components/NavbarMobileLink.vue';
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";
import { ref } from 'vue';
export default {
    name: 'Navbar',
    components: { Logo, NavbarMobileLink },
    setup() {
        var screenSize = useScreenSize();
        var isMobile = ref(window.innerWidth <= 768);
        var headerHeight = ref(isMobile ? "94px" : "76px");
        var headerWidth = ref(`${screenSize.browserWidth}px`);
        var pageWidth = ref(window.innerWidth);
        var pageHeight = ref(window.innerHeight);
        var menuOpen = ref(false);
        return {
            isMobile,
            headerHeight,
            headerWidth,
            pageWidth,
            pageHeight,
            menuOpen,
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleWindowSizeChange);
        var elements = document.getElementsByClassName("navbar-link-active");
        for (let item of elements) {
            item.classList.remove(["navbar-link-active"]);
        }

        var index = 'navbar-' + this.$route.path.split('/')[1];
        var element = document.getElementById(index);
        if (element) element.classList.add(["navbar-link-active"]);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    },
    methods: {
        logoOnClick() {
            var elements = document.getElementsByClassName("navbar-link-active");
            for (let item of elements) {
                item.classList.remove(["navbar-link-active"]);
            }
            var element = document.getElementById('navbar-about');
            if (element) element.classList.add(["navbar-link-active"]);

            this.$router.push({ name: "About" })
        },
        navOnClick(event, index) {
            var elements = document.getElementsByClassName("navbar-link-active");
            for (let item of elements) {
                item.classList.remove(["navbar-link-active"]);
            }
            console.log(index);
            var element = document.getElementById(index);
            if (element) element.classList.add(["navbar-link-active"]);
        },
        openDrawer(event) {
            console.log("open drawer")
            this.menuOpen = true;
        },
        closeDrawer(event) {
            this.menuOpen = false;
        },
        handleWindowSizeChange() {
            // console.log(window.innerWidth)
            if (window.innerWidth <= 768) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
            this.headerHeight = this.isMobile ? "94px" : "76px";
            this.headerWidth = `${window.innerWidth}px`;
        },
    }
}
</script>

<style scoped>
.header-logo {
    cursor: pointer;
}

.page-mask {
    background-color: black;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    /* height: 76px; */
}

.header-navbar {
    display: flex;
    align-items: center;
}

.navbar-link {
    display: flex;
    text-decoration: none;
    white-space: nowrap;
    /* margin-right: 20px; */
    color: white;
    font-size: 15.4px;
    font-weight: normal;
    margin-right: 25px;
}

.gg-chevron-down {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 100px;
    color: var(--accent-color);
    opacity: 0;
}

.gg-chevron-down::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    left: 4px;
    top: 2px
}

.navbar-link:hover .gg-chevron-down {
    opacity: 1;
}

.navbar-link:hover {
    color: var(--accent-color);
    font-weight: bold;
}

.navbar-link-active {
    color: var(--accent-color) !important;
}

.dropdown-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 2%;
    background-color: #182945;
    width: 120%;
}

.dropdown-item-container {
    margin-top: 5px;
}
</style>