<template>
    <product-top-page :title="title" :subTitle="subTitle" :cards="cards" :text="text" class="background" :class="backgroundClass" :style="`width: ${screenSize.browserWidth} px;`"
        v-lazy:background-image="'/images/product/3d-representation-dna(1).jpg'"
    ></product-top-page>
    <product-bar :diagramBar="diagramBar" :diagramBarText="diagramBarText"></product-bar>

    <img v-lazy="'/images/product/MetaAMP33-01.png'" :width="image1.width" :height="image1.height" style="align-self: center;"></img>
    <div v-if="isMobile" style="margin-bottom: 10%;">
        <div style="cursor: pointer; font-size: 10px; line-height: 19px; letter-spacing: 0; text-decoration: underline; color: #717173;" @click="zoomImage($event, 'image1')">click to zoom in</div>
        <div v-if="zoomInImage1">
            <div :style="`width: ${windowWidth}px; height: ${windowHeight * 2}px;`" class="page-mask" @click="closeDrawer($event, 'image1')"></div>
            <img v-lazy="'/images/product/MetaAMP33-01.png'" :width="windowWidth * 1.2" style="z-index: 101; left: -10%; top: 20%; position: fixed; background-color: white;"></img>
        </div>
    </div>

    <div style="width: 100%; height: 40px; background-color: #F8A260;"></div>

    <div style="display: flex; flex-direction: column; margin-top: 70px;">
        <h1 :class="moreAdvantagesTitleClass">More advantages</h1>
        <div style="width: 40px; align-self: center; border-bottom: 2px solid black; margin-top: 22px;"></div>

        <div :class="moreAdvantagesClass">
            <div style="width: 100%;">
                <h1 :class="moreAdvantagesTitleTitleClass">MetaAMP33 exhibits significant anti-acne effects</h1>
                <div v-if="isMobile" class="divider"></div>
                <p :class="moreAdvantagesTitleBodyClass">In the human test of MetaAMP33, the numbers of comedones and redness changed significantly after using MetaAMP33 from the expert evaluation of 33 subjects, indicating that MetaAMP33 has a highly effective anti-acne effect.</p>
            </div>
        </div>

        <v-row v-if="!isMobile" no-gutters style="width: 1020px; align-self: center; margin-top: 42px; margin-bottom: 54px;">
            <v-col cols="12" :md="6" style="position: relative; display: flex; flex-direction: column; align-items: center;">
                <h3 style="font-size: 22px; font-weight: bold; margin-bottom: 30px;">Drag the blue line to show before/after</h3>
                <draggable-container height="268" width="477" image1="/images/product/compare/1.png" image2="/images/product/compare/2.png" positionX="center" positionY="-400px" index="1"></draggable-container>
            </v-col>

            <v-col cols="12" :md="6" style="position: relative; display: flex; flex-direction: column; align-items: center;">
                <h3 style="font-size: 22px; font-weight: bold; margin-bottom: 30px;">Drag the blue line to show before/after</h3>
                <draggable-container height="268" width="477" image1="/images/product/compare/3.png" image2="/images/product/compare/4.png" positionX="center" positionY="-250px" index="2"></draggable-container>
            </v-col>
        </v-row>

        <div v-else style="width: 100%; align-self: center;" :class="imageMargin">
            <div style="position: relative; display: flex; flex-direction: column; align-items: center; margin-bottom: 20px;">
                <h3 style="font-size: 14px; font-weight: bold; margin-bottom: 10px;">Drag the blue line to show before/after</h3>
                <draggable-container height="160" width="283" image1="/images/product/compare/1.png" image2="/images/product/compare/2.png" positionX="center" positionY="-230px" index="3"></draggable-container>
            </div>

            <div style="position: relative; display: flex; flex-direction: column; align-items: center;">
                <h3 style="font-size: 14px; font-weight: bold; margin-bottom: 10px;">Drag the blue line to show before/after</h3>
                <draggable-container height="160" width="283" image1="/images/product/compare/3.png" image2="/images/product/compare/4.png" positionX="center" positionY="-150px" index="4"></draggable-container>
            </div>
        </div>

        <div :class="moreAdvantagesClass">
            <div style="width: 100%;">
                <h1 :class="moreAdvantagesTitleTitleClass">MetaAMP33 exhibits excellent antibacterial effects</h1>
                <div v-if="isMobile" class="divider"></div>
                <p :class="moreAdvantagesTitleBodyClass">Inhibiting the growth of Propionibacterium acnes is a common treatment of acne. Through the antibacterial experiments, MetaAMP33 can reduce the survival of Propionibacterium acnes at low concentrations. This indicates that MetaAMP33 can improve acne by suppressing the growth of acne-causing pathogens.</p>
            </div>
        </div>

        <img v-lazy="'/images/product/MetaAMP33-02.png'" :width="image2.width" :height="image2.height" style="align-self: center;" :class="imageMargin"></img>
    
        <div :class="moreAdvantagesClass">
            <div style="width: 100%;">
                <h1 :class="moreAdvantagesTitleTitleClass">MetaAMP33 can effectively inhibit NO levels</h1>
                <div v-if="isMobile" class="divider"></div>
                <p :class="moreAdvantagesTitleBodyClass">MetaAMP3 can efficiently inhibit NO production at low concentrations, demonstrating potent anti-inflammatory effects.</p>
            </div>
        </div>

        <div :class="moreAdvantagesClass">
            <div style="width: 100%;">
                <h1 :class="moreAdvantagesTitleTitleClass">MetaAMP33 can inhibit the pro-inflammatory cytokines</h1>
                <div v-if="isMobile" class="divider"></div>
                <p :class="moreAdvantagesTitleBodyClass">MetaAMP33 at low concentrations significantly inhibits the levels of IL-6 and IL-1β, thus inhibiting the inflammatory cell activation and reducing inflammatory response. </p>
            </div>
        </div>

        <ProductButton style="align-self: center; margin-top: 3%; margin-bottom: 3%;" color="#EB954A" :isMobile="isMobile"></ProductButton>
    </div>
</template>

<script>
import ProductTopPage from "@/components/Products/ProductTopPage.vue"
import ProductBar from "@/components/Products/ProductBar.vue"
import DraggableContainer from '@/components/Products/DraggableContainer.vue'
import ProductButton from '@/components/Products/ProductButton.vue'
import { useScreenSize } from "@/composables/useScreenSize.js";
import { calBrowserSize } from "@/composables/calBrowserSize.js";
import { ref } from 'vue';
export default {
    components: {
        ProductTopPage,
        ProductBar,
        DraggableContainer,
        ProductButton,
    },
    setup() {
        var screenSize = useScreenSize()
        var isMobile = screenSize.isMobile.value;
        var backgroundClass = isMobile ? 'background-extra-mobile' : 'background-extra';
        var image1 = {
            width: isMobile ? window.innerWidth * 0.9 : window.innerWidth * 0.9 < 1203 ? window.innerWidth * 0.9 : 1203,
            height: isMobile ? 'auto' : 'auto',
        }
        var windowWidth = window.innerWidth;
        var windowHeight = window.innerHeight;
        var zoomInImage1 = ref(false)
        var dividerHeight = isMobile ? "15px" : "40px";
        var moreAdvantagesTitleClass = isMobile ? "more-advantages-title-text=mobile" : "more-advantages-title-text";
        var moreAdvantagesClass = isMobile ? "more-advantages-title-mobile more-advantages-text" : "more-advantages-title more-advantages-text";
        var moreAdvantagesTitleTitleClass = isMobile ? "more-advantages-title-title-text-mobile" : "more-advantages-title-title-text";
        var moreAdvantagesTitleBodyClass = isMobile ? "more-advantages-title-body-text-mobile" : "more-advantages-title-body-text";
        var image2 = {
            width: isMobile ? (window.innerWidth * 0.9 < 320 ? window.innerWidth * 0.9 : 320) : (window.innerWidth * 0.9 < 782 ? window.innerWidth * 0.9 : 782),
            height: isMobile ? 'auto' : 'auto',
        }
        var imageMargin = isMobile ? "image-margin-mobile" : "image-margin";
        var image3 = {
            width: isMobile ? window.innerWidth * 0.9 : window.innerWidth * 0.9 < 782 ? window.innerWidth * 0.9 : 782,
            height: isMobile ? 'auto' : 'auto',
        }
        var productButtonMarginBottom = isMobile ? "10%" : "5%";

        var title = "Anti-acne Peptide MetaAMP33";
        var subTitle = "Acne and inflammation fighter";
        var cards = [
            {
                title: "Efficacy",
                text: "Repair skin, reduce inflammation and bacterial infections",
            },
            {
                title: "Advantage",
                text: "Higher efficacy and lower cost",
            },
            {
                title: "Application",
                text: "Resistant to high temperatures and maintains high bioactivity",
            },
        ]
        var text = "MetaAMP33 is a new active peptide that targets skin bacterial infections by inhibiting the harmful bacteria. Additionally, it can inhibit the various pro-inflammatory cytokines, alleviating skin inflammation and bacterial infections, such as acne (pimples)."
        var diagramBar = {
            left: "Mechanism diagram",
            right: "MetaAMP33 can regulate sebaceous glands and inhibit Bacillus acnes",
            color: "#F8A260",
        }
        var diagramBarText = "Acne is caused by excessive sebum secretion and follicular blockage. Propionibacterium acnes (P. acnes) is the major cause of skin inflammation and infections, which activates macrophages and endothelial cells to produce NO and exacerbates the inflammatory response. MetaAMP33 can regulate the secretion of sebaceous glands, efficiently inhibit the growth of P. acnes and also suppress NO and pro-inflammatory factors, which relieve skin inflammation."

        return {
            screenSize,
            isMobile,
            backgroundClass,
            image1,
            windowHeight,
            windowWidth,
            zoomInImage1,
            dividerHeight,
            moreAdvantagesTitleClass,
            moreAdvantagesClass,
            moreAdvantagesTitleTitleClass,
            moreAdvantagesTitleBodyClass,
            image2,
            imageMargin,
            image3,
            productButtonMarginBottom,

            title,
            subTitle,
            cards,
            text,
            diagramBar,
            diagramBarText,
        }
    },
    methods: {
        zoomImage(event, image) {
            if (image == 'image1') {
                this.zoomInImage1 = true;
            }
        },
        closeDrawer(event, image) {
            if (image == 'image1') {
                this.zoomInImage1 = false;
            }
        }
    }
}
</script>

<style scoped>
.page-mask {
    background-color: black;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.background {
    /* background-image: url("../../../public/images/product/3d-representation-dna(1).jpg"); */
    background-repeat: no-repeat;
    /* background-size: 400%; */
    background-position-x: right;
    width: 100%;
    /* background-position-y: top; */
}
.background-extra {
    padding-bottom: 75px; 
    background-size: cover; 
    background-position-y: center;
}
.background-extra-mobile {
    padding-bottom: 5%; 
    background-size: cover; 
    background-position-y: top;
}

.more-advantages-title-text {
    font-size: 52px; 
    line-height: 50px; 
    letter-spacing: -0.8px; 
    color: black;
}

.more-advantages-title-text-mobile {
    font-size: 20px; 
    line-height: 50px; 
    letter-spacing: 0; 
    color: black;
}

.more-advantages-title {
    padding: 0 50px;
    width: 90%; 
    height: 344px; 
    align-self: center; 
    text-align: left; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.more-advantages-title-mobile {
    padding-left: 6%;
    padding-right: 6%;
    width: 90%; 
    height: 305px; 
    align-self: center; 
    text-align: left; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    margin-top: 4%;
    margin-bottom: 4%;
}

.more-advantages-title-title-text {
    font-size: 30px; 
    line-height: 50px; 
    letter-spacing: -0.8px; 
    color: black;
    margin-bottom: 40px;
}

.more-advantages-title-title-text-mobile {
    font-size: 14px; 
    line-height: 20px; 
    font-weight: bold;
    letter-spacing: 0px; 
    color: black;
}

.more-advantages-title-body-text {
    font-size: 20px; 
    line-height: 32px; 
    letter-spacing: -0.8px; 
    color: #2E2E27;
}

.more-advantages-title-body-text-mobile {
    font-size: 12px; 
    line-height: 20px; 
    letter-spacing: 0px; 
    font-weight: lighter;
    color: #2E2E27;
}

.more-advantages-body {
    width: 1140px;
    height: 316px; 
    align-self: center; 
    text-align: left; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
}

.more-advantages-text {
    background-color: #F5F5F5;
    border-radius: 38px;
    align-self: center; 
    text-align: left; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: black;
    margin-top: 5%;
    margin-bottom: 6%;
}
.image-margin {
    margin-top: 96px; 
    margin-bottom: 111px;
}
.image-margin-mobile {
    margin-top: 10%; 
    margin-bottom: 10%;
}
</style>